import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CommentCard } from './../../core/interfaces/commentCard';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bounty-program',
  templateUrl: './bounty-program.component.html',
  styleUrls: ['./bounty-program.component.scss']
})
export class BountyProgramComponent implements OnInit, OnDestroy {

  constructor(
    private sessionStorage: SessionStorageService,
  ) {}

  private _timeout$: Subject<void> = new Subject();
  darkTheme: boolean = false;
  siteName: string = environment.config.EXCHANGE_NAME_L;
  supportUrl: string = environment.config.SUPPORT_URL;

  userComments: CommentCard[] = [
    {
      userName: 'Name1',
      comment: `Excellent support and
      secure exchange.`,
      image: '../../assets/chainex/images/pages/home/mbernardt_cropped.webp'
    },
    {
      userName: 'Name2',
      comment: `Excellent support and
      secure exchange.`,
      image: '../../assets/chainex/images/pages/home/tchanisa_cropped.webp'
    },
    {
      userName: 'Name3',
      comment: `Excellent support and
      secure exchange.`,
      image: '../../assets/chainex/images/pages/home/sjpretorius_cropped.webp'
    },
    {
      userName: 'Name4',
      comment: `Excellent support and
      secure exchange.`,
      image: '../../assets/chainex/images/pages/home/sjpretorius_cropped.webp'
    }
  ];

  ngOnInit() {
    this.sessionStorage.observe('THEME').pipe(
      tap((data) => {
        this.darkTheme = data === 'dark-theme';
      }),
      takeUntil(this._timeout$)
    ).subscribe();
  }

  ngOnDestroy() {
    this._timeout$.next();
    this._timeout$.complete();
  }
}
