import { IMarketData } from '../../core/interfaces/home-interface';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { MarketsService } from 'src/app/markets/markets.service';
import { UtilHelper } from 'src/app/core/helpers/util-helper';
import { HomeService } from 'src/app/home/home.service';
import { environment } from 'src/environments/environment';
import { Team } from 'src/app/core/interfaces/team';
import { InfoService } from '../info.service';

const imagePath: string  = `/assets/${environment.config.EXCHANGE_NAME_L}/images/pages/about`;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  private subs: SubscriptionLike[] = [];

  chartFirstLoad: boolean = true;
  chartResting: boolean = false;
  coinPrices: IMarketData[] = [];
  darkTheme: boolean = false;
  supportUrl: string = environment.config.SUPPORT_URL;
  graphPoints: Array<any> = [];
  infoService: InfoService;

  teamList: Team[] = [
    {name: 'Ryno Mathee', imgSrc: `${imagePath}/rynom.jpeg`, jobDescription: 'Chief Technical Officer'},
    {name: 'Henco Vorstman', imgSrc: `${imagePath}/hencov.jpg`, jobDescription: 'Chief Executive Officer'},
    {name: 'Francois Odendaal', imgSrc: `${imagePath}/francoiso.jpg`, jobDescription: 'Product Owner & QA Analyst'},
    {name: 'Jonathan Kleinhans', imgSrc: `${imagePath}/jonathank.jpeg`, jobDescription: 'Compliance officer & Business Analyst'},
    {name: 'Marita Webb', imgSrc: `${imagePath}/maritaw.jpg`, jobDescription: 'Ops and Support Manager'},
    {name: 'Omri Jacobsz', imgSrc: `${imagePath}/omrij.jpg`, jobDescription: 'Scrum Master'},
    {name: 'Ruhann Herselman', imgSrc: `${imagePath}/ruhannh.jpg`, jobDescription: 'Financial Manager'},
    {name: 'Patrick Erasmus', imgSrc: `${imagePath}/patricke.jpg`, jobDescription: 'Coin Listing Co-ordinator'},
    {name: 'Jared Cassidy', imgSrc: `${imagePath}/jaredc.jpeg`, jobDescription: 'Systems Administrator'},
    {name: 'Marcus Lawrence', imgSrc: `${imagePath}/marcusl.jpeg`, jobDescription: 'QA Analyst'},
    {name: 'Jason Eybers', imgSrc: `${imagePath}/jasone.jpg`, jobDescription: 'Developer'},
    {name: 'Christiaan Odendaal', imgSrc: `${imagePath}/christiaano.jpeg`, jobDescription: 'Developer'},
    {name: 'Sam Mthuli', imgSrc: `${imagePath}/lihlem.jpg`, jobDescription: 'Developer'},
    {name: 'Thomas Rozema', imgSrc: `${imagePath}/thomasr.jpg`, jobDescription: 'Junior Developer'},
    {name: 'Marco Pretorius', imgSrc: `${imagePath}/marcop.jpg`, jobDescription: 'Junior Developer'},
    {name: 'Unine Allan', imgSrc: `${imagePath}/uninea.jpg`, jobDescription: 'Designer'},
    {name: 'Monique Bernardt', imgSrc: `${imagePath}/moniqueb.jpeg`, jobDescription: 'Social Media'},
    {name: 'Annemie Holtzhousen', imgSrc: `${imagePath}/annemieh.jpeg`, jobDescription: 'Support Desk Agent'},
    {name: 'Andrew Jordaan', imgSrc: `${imagePath}/andrewj.jpg`, jobDescription: 'Support Desk Agent'}
  ];
  environment: any = environment.config;
  loggedIn: boolean = false;
  siteName: String = environment.config.EXCHANGE_NAME_L;
  address: typeof environment.config.ADDRESS_DETAILS = environment.config.ADDRESS_DETAILS;
  utilHelper: UtilHelper = UtilHelper;

  constructor(
    public marketService: MarketsService,
    private homeService: HomeService,
    _infoService: InfoService) {
    this.infoService = _infoService;
  }

  ngOnInit() {
   // subscribe for updates
    this.subs.push(this.marketService.marketUpdateSubject.subscribe((response) => {
      this.generateMarketStats();
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  generateMarketStats() {
    this.marketService.getMarketPairsWithStats().subscribe(response => {
      const stats: IMarketData[] = [];
      let normStat: IMarketData = null;
      let maxChange: IMarketData = null;
      let minChange: IMarketData = null;
      let maxVol: IMarketData = null;
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].marketPair === 'BTC/ZAR' || response.data[i].marketPair === 'BTC/USDT') {
          normStat = { ...response.data[i] };
          normStat.type = '';
          normStat.text = '';
          stats.push(normStat);
        }
        if (i > 0) {
          if ((UtilHelper.strToNumber(response.data[i].volume) > UtilHelper.strToNumber(maxVol.volume)
            && response.data[i].exchangeCode === 'BTC') || maxVol.exchangeCode !== 'BTC') {
            maxVol = { ...response.data[i] };
            maxVol.type = 'maxVol';
          }
          if (UtilHelper.strToNumber(response.data[i].change) > UtilHelper.strToNumber(maxChange.change)) {
            maxChange = { ...response.data[i] };
            maxChange.type = 'maxChange';
          }
          if (UtilHelper.strToNumber(response.data[i].change) < UtilHelper.strToNumber(minChange.change)) {
            minChange = { ...response.data[i] };
            minChange.type = 'minChange';
          }
        } else {
          maxVol = { ...response.data[i] };
          maxVol.type = 'maxVol';
          maxChange = { ...response.data[i] };
          maxChange.type = 'maxChange';
          minChange = { ...response.data[i] };
          minChange.type = 'minChange';
        }
        if (response.data.length - 1 === i) {
          stats.push(maxChange);
          stats.push(minChange);
          stats.push(maxVol);
          if (this.coinPrices.length === 0) {
            this.coinPrices = stats;
          } else {
            for (let a = 0; a < stats.length; a++) {
              this.coinPrices[a].takerFee = stats[a].takerFee;
              this.coinPrices[a].change = stats[a].change;
              this.coinPrices[a].changeColor = stats[a].changeColor;
              this.coinPrices[a].coinCode = stats[a].coinCode;
              this.coinPrices[a].coinId = stats[a].coinId;
              this.coinPrices[a].coinName = stats[a].coinName;
              this.coinPrices[a].exchangeCode = stats[a].exchangeCode;
              this.coinPrices[a].exchangeId = stats[a].exchangeId;
              this.coinPrices[a].exchangeName = stats[a].exchangeName;
              this.coinPrices[a].high = stats[a].high;
              this.coinPrices[a].id = stats[a].id;
              this.coinPrices[a].lastPrice = stats[a].lastPrice;
              this.coinPrices[a].low = stats[a].low;
              this.coinPrices[a].marketPair = stats[a].marketPair;
              this.coinPrices[a].makerFee = stats[a].makerFee;
              this.coinPrices[a].topAsk = stats[a].topAsk;
              this.coinPrices[a].topBid = stats[a].topBid;
              this.coinPrices[a].volume = stats[a].volume;
              this.coinPrices[a].btcEstimate = stats[a].btcEstimate;
              this.coinPrices[a].yesterdayPrice = stats[a].yesterdayPrice;
              this.coinPrices[a].type = stats[a].type;
              this.coinPrices[a].text = stats[a].text;
            }
          }
          this.generateChartData();
        }
      }
    });
  }

  generateChartData() {
    if (this.chartResting) {
      return;
    }

    this.chartResting = true;
    const self = this;
    setTimeout(function () {
      self.chartResting = false;
    }, 30000);

    let chartPair: string;
    const oneWeek: number = 604800;
    const endDate: number = Date.now() / 1000;
    const startDate: number = (endDate - oneWeek);
    const temp: number = this.coinPrices.length;
    const points: any = { pair: '', data: [0, 0, 0, 0, 0, 0, 0] };

    for (let i = 0; i < this.coinPrices.length; i++) {
      chartPair = this.coinPrices[i].marketPair;
      if (this.graphPoints.length === 0) {
        this.coinPrices[i].chartData = [{ data: [0, 0, 0, 0, 0, 0, 0], label: '' }];
      } else {
        for (let cp = 0; cp < this.graphPoints.length; cp++) {
          if (this.graphPoints[cp].pair === chartPair) {
            this.coinPrices[cp].chartData = [{ data: this.graphPoints[cp].data, label: '' }];
          }
        }
      }
      this.homeService.getChartData({
        symbol: chartPair, resolution: '1D',
        from: startDate, to: endDate
      }).subscribe((response) => {
        if (!!response.c) {
          this.coinPrices[i].chartData = [{ data: response.c, label: '' }];
          points.pair = chartPair;
          points.data = response.c;
          this.graphPoints = [];
          this.graphPoints.push(points);
        } else {
          this.coinPrices[i].chartData = [{ data: [0, 0, 0, 0, 0, 0, 0], label: '' }];
        }
      });
      if (i === temp - 1) {
        this.coinPrices = this.coinPrices;
        this.generateChartData();
      }
    }
  }

  navlink(): string {
    return (this.loggedIn ? '/markets' : '/');
  }

  scrollToTop() {
    const element = document.getElementById('page-top');
    element.scrollIntoView({behavior: 'smooth'});
  }

}
