import { Component, OnInit } from '@angular/core';
import { InfoService } from 'src/app/info/info.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-info-footer',
  templateUrl: './info-footer.component.html',
  styleUrls: ['./info-footer.component.scss']
})

export class InfoFooterComponent implements OnInit {

  infoService: InfoService;
  environment: any = environment.config;

  constructor(
    _infoService: InfoService
  ) {
    this.infoService = _infoService;
  }

  ngOnInit(): void {

  }

}
