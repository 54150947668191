import { Component, OnInit } from '@angular/core';
import { InfoService } from '../info.service';
import { MatTableDataSource } from '@angular/material/table';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { environment } from './../../../environments/environment';

let exchangeName;
exchangeName = environment.config.EXCHANGE_NAME;

interface WithdrawFee {
  market: string;
  taker_fee: number;
  maker_fee: number;
}

interface MarketPair {
  coincode: string;
  coinname: string;
  withdrawfee: number;
}

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.scss']
})


export class FeesComponent implements OnInit {

  withdrawalfees_def: any = ['code', 'name', 'withdraw_fee'];
  withdrawalfees: MatTableDataSource<WithdrawFee>;
  marketpairs_def: any = ['market', 'taker_fee', 'maker_fee', 'quick_trading'];
  marketpairs: MatTableDataSource<MarketPair>;
  scalingfees_def: any = ['volume_text', 'maker_fee', 'taker_fee'];
  scalingfees: MatTableDataSource<MarketPair>;

  trading_description: string = '';
  trading_description_1: string = '';
  scaling_description: string = this.i18n(`Based on your last 30 day trading volume,
   your trading fees will be adjusted on a sliding scale, i.e. the more
   you trade the lower your trading fees will be. Our scaling fee levels
   are presented below, if you qualify for one of these levels it will
   also be indicated. If a level is not indicated for you, standard
   market fees (Market fees section) apply.`);

  userFee: any = null;
  quickOrderFee: number = 0.75;

  constructor(
    private service: InfoService,
    private i18n: I18n
  ) { }

  ngOnInit() {
    if (exchangeName === 'ChainEX') {
      this.trading_description = this.i18n(`ChainEX will charge a low, percentage fee on each trade completed.
      The percentage fees charged per market is indicated below.
      If you qualify for a scaling fee level (indicated on the Scaling Fees section),
      it will overwrite these fees. Please note that all quick trading fees are charged at `);
      this.trading_description_1 = this.i18n(`% on
      all markets and that your scaling fee percentage does not apply to quick trading`);
      const promises = [
        new Promise((resolve) => this.getWithdrawFees(resolve)),
        new Promise((resolve) => this.getMarketData(resolve)),
        new Promise((resolve) => this.getUserFee(resolve)),
        new Promise((resolve) => this.getScalingFees(resolve))
      ];
      Promise.all(promises).then(() => { });
    } else {
      this.trading_description = this.i18n(`BurnX will charge a low, percentage fee on
   each trade completed. The percentage fees charged per market is indicated
   below.`);
      const promises = [
        new Promise((resolve) => this.getWithdrawFees(resolve)),
        new Promise((resolve) => this.getMarketData(resolve)),
        new Promise((resolve) => this.getUserFee(resolve))
      ];
      Promise.all(promises).then(() => { });
    }
  }

  getWithdrawFees(resolve: any) {
    this.service.getWithdrawFees()
      .subscribe((response: any) => {
        if (response.response === 'success') {
          this.withdrawalfees = new MatTableDataSource(response.data);
        }
        resolve();
      });

  }

  getMarketData(resolve: any) {
    this.service.getMarketData()
      .subscribe((response: any) => {
        if (response.response === 'success') {
          this.marketpairs = new MatTableDataSource(response.data);
          this.quickOrderFee = (+response.quick_trading_fee * 100);
        }
        resolve();
      });
  }

  getUserFee(resolve: any) {
    this.service.getUserFee()
      .subscribe((response: any) => {
        if (response.response === 'success' && !!response.data) {
          this.userFee = response.data;
          this.userFee.total_volume = Number(this.userFee.total_volume);
          this.userFee.total_volume = isNaN(this.userFee.total_volume) ? 0 : this.userFee.total_volume;
        }
        resolve();
      });
  }

  getScalingFees(resolve: any) {
    this.service.getScalingFees()
      .subscribe((response: any) => {
        if (response.response === 'success') {
          const data: any[] = [];
          for (let i = 0;  i < response.data.length; i++) {
            if (i === response.data.length - 1) {
              data.push({'first': + i === 0 ? 1 : 0, 'volume_amount': +response.data[i].min, 'volume_max': 21000000,
              'taker_fee': +response.data[i].taker_fee, 'maker_fee': +response.data[i].maker_fee});
            } else {
              data.push({'first': + i === 0 ? 1 : 0, 'volume_amount': +response.data[i].min,
              'volume_max': +response.data[i + 1].min, 'taker_fee': +response.data[i].taker_fee,
              'maker_fee': +response.data[i].maker_fee});
            }
          }
          this.scalingfees = new MatTableDataSource(data);
        }
        resolve();
      });
  }

  isUserFee(row: any): boolean {
    // compare scalingfees volume with user volume
    if (!this.userFee) {
      return false;
    } else {

      // we don't worry about null/undefined => 0/NaN as both fail the range check bellow
      this.userFee.total_volume = Number(this.userFee.total_volume);
      if (row.volume_amount <= this.userFee.total_volume
        && this.userFee.total_volume < row.volume_max ) {
          return true;
        }
      return false;
    }
  }
}
