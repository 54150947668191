<div id="page-top"></div>
<app-header></app-header>

<div class="row container-fluid mx-0 px-0" [ngClass]="infoService.darkTheme ? 'dark-theme-background' : 'light-theme-background'">
  <div class="vertical-spacer d-none d-lg-block"></div>
  <div class="col-12 col-lg-10 mt-2 pt-5 mx-auto">
    <a class="row container-fluid mx-0 px-0">
      <img alt="logo" src="/assets/chainex/images/pages/maker-program/maker_{{infoService.darkTheme ? 'dark' : 'light'}}.png" class="banner mx-auto">
    </a>
  </div>
  <div class="blueprint-background">
    <div class="vertical-spacer d-none d-lg-block"></div>
    <div class="col-12 col-lg-10 mt-2 pt-2 pb-1 mx-auto">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 i18n>About the <span class="orange-text"><b>Program</b></span></h2>
        </div>
        <div class="col-12 col-lg-9 px-lg-0">
          <p>
            ChainEX is excited to introduce our new Market Maker Program which allows our traders to increase their trading profits by earning rewards!
          </p>
          <p>
            With our Market Maker Program, ChainEX gives you the ability to earn fees instead of paying them on your trades.
            That’s right! Instead of paying fees on the trades you make, you will receive a reward simply for trading.
          </p>
        </div>
      </div>
    </div>
    <div class="vertical-spacer d-none d-lg-block"></div>
    <div class="col-12 col-lg-10 mt-2 pt-2 pb-1 mx-auto">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 i18n>How <span class="orange-text"><b>Negative Maker Fees</b></span> work</h2>
        </div>
        <div class="col-12 col-lg-9 px-lg-0">
          <p>
            As a market maker you can now earn fees instead of paying them. What this means is that not only will
            you <strong>not</strong> pay a fee when placing an order as a maker, but you will also receive a percentage
            back on your traded order. When you place a maker order and a taker order is traded against it,
            you will receive up to 15% of the total fee paid by the taker.
          </p>
          <p>
            <a href="https://www.youtube.com/watch?v=nvbSdTklEvc" target="_bank">Click here</a> to learn more about maker and taker orders and <a href="/fees">click here</a> to see the negative maker fees per market.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="map-background">
    <div class="vertical-spacer d-none d-lg-block"></div>
    <div class="col-12 col-lg-10 mt-2 pt-2 pb-1 mx-auto">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 i18n>Get Rewarded for <span class="orange-text"><b>Trading More</b></span></h2>
        </div>
        <div class="col-12 col-lg-9 px-lg-0">
          <p>
            ChainEX has a scaling fee structure that rewards our traders with even lower fees than the standard market fees
            if they perform a lot of trades. Depending on the total value of your trades in the last 30 days, you will
            qualify for a reduced scaling fee as per our <a href="/fees">fees page.</a>
          </p>
          <p>
            As part of our Market Maker Program, we are further reducing our scaling fee percentages,
            meaning you can save even more on your trades by consistently trading on ChainEX!
          </p><br>
          <p>
            <strong>This also works in conjunction with our negative maker fees as you can earn higher percentages of fees back based on the scaling fee bracket you are on.</strong>
          </p>
          <p>Our new scaling fees are as follows:</p>
          <a class="row container-fluid mx-0 px-0">
            <img alt="logo" src="/assets/chainex/images/pages/maker-program/table_{{infoService.darkTheme ? 'dark' : 'light'}}.png" class="banner">
          </a>
        </div>
      </div>
    </div>

    <div class="vertical-spacer d-none d-lg-block"></div>

    <div class="col-12 col-lg-10 mt-2 pt-2 pb-1 mx-auto">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 i18n>How to get <span class="orange-text"><b>started</b></span></h2>
        </div>
        <div class="col-12 col-lg-9 px-lg-0">
          <p>
            Our Market Maker Program is up and running right now! Head over to our fees page to see our new
            Trading Fee structure and how you can benefit from our negative maker fees and scaling fee structure.
          </p>
          <p>
            To start benefiting from the Program, all you need to do is login and start trading your favorite coins right now!
          </p>
        </div>
        <div class="col-12 col-xl-4 mb-5 mb-lg-3 mx-auto">
          <a href="/markets" class="mat-raised-button mat-primary orange-gradient w-100 mt-3 py-2 main-button" i18n>START TRADING TODAY</a>
        </div>
      </div>
    </div>
    <div class="vertical-spacer d-none d-lg-block"></div>
  </div>
  <div class="col-12 mb-sm-0 bottom-space">
    <div class="w-100 my-5 d-none d-lg-block"></div>
  </div>
</div>

<!-- footer -->
<app-info-footer></app-info-footer>
