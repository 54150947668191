<div class="container-fluid">
  <div class="row back pt-1 pl-1">

    <div class="col-12 col-lg-3 col-xlg-2 mb-2 order-1 order-lg-0 fullHeight full-market mat-elevation-z2">
      <markets-sidebar #sidebar class="h-100"></markets-sidebar>
    </div>

    <div class="col-12 col-lg-9 col-xlg-10 order-0 order-lg-1">
      <div class="row overflow-hidden">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-lg-6 fix-gutter">
              <div class="row mat-elevation-z2 mb-2 details pt-3">
                <div class="col-md-12">
                  <h4 class="table-heading" i18n>
                    TRADING FEES
                    <small>
                      <mat-icon
                        class="align-bottom fee-info"
                        placement="bottom"
                        i18n-matTooltip
                        matTooltip="{{trading_description}} {{quickOrderFee | number: '1.2'}}{{trading_description_1}}"
                        triggers="hover click"
                      >
                        info
                      </mat-icon>
                    </small>
                  </h4>
                  <mat-table class="w-100 mb-1" [dataSource]="marketpairs">

                      <ng-container matColumnDef="market">
                          <mat-header-cell *matHeaderCellDef i18n> Market </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.market}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="taker_fee">
                          <mat-header-cell *matHeaderCellDef i18n> Taker </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.taker_fee==0?'FREE!':(element.taker_fee*100 | number:'1.2') + ' %'}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="maker_fee">
                          <mat-header-cell *matHeaderCellDef i18n> Maker </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.maker_fee==0?'FREE!':(element.maker_fee*100 | number:'1.2') + ' %'}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="quick_trading">
                        <mat-header-cell *matHeaderCellDef i18n> Quick Trading </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ quickOrderFee | number: '1.2' }} %</mat-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="marketpairs_def"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: marketpairs_def;"></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
            <div class="col-lg-6 fix-gutter d-flex flex-column pl-06em">
              <div class="row mat-elevation-z2 details pt-3">
                <div class="col-md-12">
                  <h4 class="table-heading" i18n>SCALING FEES <small><mat-icon class="align-bottom fee-info" placement="bottom" i18n-matTooltip matTooltip="{{ scaling_description }}" triggers="hover click" >info</mat-icon></small></h4>
                  <mat-table class="w-100 mb-1" [dataSource]="scalingfees">

                    <ng-container matColumnDef="volume_text">
                        <mat-header-cell *matHeaderCellDef i18n> 30 Day  Trade Volume </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.first === 1 ? 'Less than' : 'Greater or equal to' | i18n }} {{ element.volume_amount === 0 ? 1 : element.volume_amount | number:'1.0' }} BTC</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="maker_fee">
                        <mat-header-cell *matHeaderCellDef i18n> Maker </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.first === 1 ? ('Default market fee' | i18n) : (element.maker_fee | number:'1.3').concat('%') }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="taker_fee">
                        <mat-header-cell *matHeaderCellDef i18n> Taker </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.first === 1 ? ('Default market fee' | i18n) : (element.taker_fee | number:'1.3').concat('%') }}  </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="scalingfees_def"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: scalingfees_def;" [ngClass]="{'highlight': isUserFee(row)}"></mat-row>
                  </mat-table>
                </div>
              </div>
              <div class="row mat-elevation-z2 flex-fill mb-2 mt-04em pt-3">
                <div class="col-md-12">
                  <h4 class="table-heading" i18n>WITHDRAW FEES</h4>

                  <mat-table class="w-100 mb-1" [dataSource]="withdrawalfees">

                      <ng-container matColumnDef="code">
                          <mat-header-cell *matHeaderCellDef i18n> Coin code </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.code}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="name">
                          <mat-header-cell *matHeaderCellDef i18n> Coin Name </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="withdraw_fee">
                          <mat-header-cell *matHeaderCellDef i18n> Withdraw </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.withdraw_fee}} </mat-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="withdrawalfees_def"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: withdrawalfees_def;"></mat-row>
                  </mat-table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
