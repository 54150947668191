import { Component } from '@angular/core';
import { InfoService } from '../info.service';

@Component({
  selector: 'app-earn',
  templateUrl: './earn.component.html',
  styleUrls: ['./earn.component.scss']
})
export class EarnComponent {
  infoService: InfoService;

  constructor(_infoService: InfoService) {
    this.infoService = _infoService;
  }

}
