import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfoService } from 'src/app/info/info.service';

@Component({
  selector: 'app-stablecoin',
  templateUrl: './stablecoin.component.html',
  styleUrls: ['../about/about.component.scss'] // This is exactly the same
})
export class StablecoinComponent {

  infoService: InfoService;
  environment: any = environment.config;
  address: typeof environment.config.ADDRESS_DETAILS = environment.config.ADDRESS_DETAILS;

  constructor(_infoService: InfoService) {
    this.infoService = _infoService;
  }

}
