<div class="container-fluid">
  <div class="row back pt-1 pl-1">

    <div class="col-12 col-lg-3 col-xlg-2 order-1 order-lg-0 mb-2 full-market mat-elevation-z2">
      <markets-sidebar #sidebar class="h-100"></markets-sidebar>
    </div>

    <div class="col-12 col-lg-9 col-xlg-10 order-0 order-lg-1 overflow-x-hidden">
      <div class="row h-100 d-flex flex-column">
        <div class="col-lg-12 fix-gutter flex-fill d-flex flex-column">
          <div class="row mat-elevation-z2 pt-3 mb-2 details">
            <div class="col-12">
                <h4 class="table-heading" i18n>RISK DISCLOSURE</h4>
            </div>
            <div class="col-sm-12">
              <ol>
                <li>
                  <p i18n>
                    Digital Currency is a new technology and entails various risks that you should understand – some of which (but
                    not limited to) we have highlighted here below.
                  </p>
                </li>
                <li>
                  <p i18n>
                    Digital Currency transactions send from your ChainEX wallets are irreversible. If you send Digital Currency to
                    an incorrect address, or send the wrong amount of Digital Currency, you cannot get them back. ChainEX cannot be
                    held liable for executing a transaction if the instruction relates to an incorrect Digital Currency address.
                  </p>
                </li>
                <li>
                  <p i18n>
                    Before buying or selling Digital Currency, educate yourself about Digital Currency. Buying and selling Digital
                    Currency entails risks and could result in a complete loss of your funds. Please ensure that you fully understand
                    the risks involved and do not invest money that you cannot afford to lose.
                  </p>
                </li>
                <li>
                  <p i18n>
                    Digital Currency is not backed by any entity. Neither ChainEX nor anyone else has an obligation to buy back your
                    Digital Currencies in the future.
                  </p>
                </li>
                <li>
                  <p i18n>
                    Additionally, it is very important to note the following risks:
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1">5.1</span>
                      <span class="col-11" i18n>
                        Digital Currency is not legal tender and it is not backed by the government. Accounts and value balances are not
                        subject to any Government backed deposit insurance or any other Government protections;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">5.2</span>
                      <span class="col-11" i18n>
                        Legislative and regulatory changes or actions at the state or international level may adversely affect the use,
                        transfer, exchange, and value of Digital Currency;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">5.3</span>
                      <span class="col-11" i18n>
                        Transactions in Digital Currency are generally irreversible, and, accordingly, losses due to fraudulent or
                        accidental transactions may not be recoverable;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">5.4</span>
                      <span class="col-11" i18n>
                        Digital Currency transactions shall be deemed to be made when recorded on a "blockchain" ledger, which is not
                        necessarily the date or time that you initiate the transaction;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">5.5</span>
                      <span class="col-11" i18n>
                        Digital Currency is derived from the continued willingness of market participants to exchange fiat currency for
                        Digital Currency, which may result in the potential for permanent and total loss of value of Digital Currency,
                        should the market for Digital Currency disappear;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">5.6</span>
                      <span class="col-11" i18n>
                        There is no assurance that a person who accepts a Digital Currency as payment today will continue to do so in
                        the future;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">5.7</span>
                      <span class="col-11" i18n>
                        The volatility and unpredictability of the price of Digital Currency relative to fiat currency may result in
                        significant loss or tax liability over a short period of time;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">5.8</span>
                      <span class="col-11" i18n>
                        The nature of Digital Currency may lead to an increased risk of fraud or cyber-attack and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">5.9</span>
                      <span class="col-11" i18n>
                        The nature of Digital Currency means that any technological difficulties experienced by a service provider may
                        prevent the access or use of your Digital Currency.
                      </span>
                    </li>
                    <br>
                  </ul>
                </li>
              </ol>
              <div class="alert alert-secondary clearfix" role="alert">
                  <div class="row">
                    <div class="col-1 reduce-max-w">
                        <mat-icon svgIcon="map-marker"></mat-icon>
                    </div>
                    <div class="col-11">
                      <p i18n>
                        {{address.NAME}},
                        {{address.STREET}}
                        {{address.TOWN}}
                        <br />
                        <span>{{address.REGISTRATION}} {{address.COUNTRY}}.</span>
                      </p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
