<div class="container-fluid px-0">
  <div class="row mx-0 py-0 py-lg-3 my-3 my-lg-5">
    <!-- start blueprint background -->
    <div class="blueprint-background">
      <!-- Main Bounty Program section  -->
      <div class="container">
        <div class="row mx-0">
          <div class="col-12 col-lg-4 px-3 px-lg-0 text-size">
            <h3 i18n><span class="orange-header">Bounty</span> Program</h3>
          </div>
          <div class="col-12 col-lg-8">
            <p i18n>
              <b>ChainEX</b> is a cryptocurrency exchange and as such security and protection
              of our system and platform is of the utmost importance to us. This Bug Bounty has
              been put in place in order to encourage the responsible disclosure of any bug or
              vulnerability contained within the <b>ChainEX</b> code or protocols and if any are
              found, to reward those who make such disclosures.
            </p>
          </div>
        </div>
        <br><br>
        <div class="row mx-0">
          <div class="col-12 col-lg-4 px-3 px-lg-0 text-size">
            <h3 i18n>
              <span class="orange-header">Bounty</span> Policy
            </h3>
          </div>
          <div class="col-12 col-lg-8">

            <!-- Application of legislation governing conduct section -->
            <h4 class="table-heading" i18n>
              1. Application of legislation governing conduct:
            </h4>
            <p i18n>
              <b>ChainEX</b> undertakes not to initiate legal action for security research conducted
              pursuant to all Bug Bounty Program policies or accidental violations, if the researcher
              fully complies with this Policy.
            </p>
            <p i18n>
              We will not bring a claim against researchers for circumventing the technological measures
              we have used to protect the applications in the scope of the Bug Bounty Program.
              Please understand that if your security research involves the networks, systems, information,
              applications, products or services of another party (which is not us), that third party
              may determine whether to pursue legal action. We cannot and do not authorize security research
              in the name of other entities.
            </p>
            <p i18n>
              Please submit an appropriately composed report before engaging in conduct that
              may be inconsistent with or unaddressed by this policy.
            </p>
            <p i18n>
              This report should include a brief description of your intended conduct so that we may
              determine whether it is consistent with the Bug Bounty Program policy.
            </p>
            <p i18n>
              <b>ChainEX</b> reserves the right to change or modify the terms of this program at anytime.
              You may not participate in this program if you are a person or entity identified on the TFS list.
              The searchable TFS list is available on the Financial Intelligence
              Centre (FIC) website (<span class="orange-header"><a href="https://www.fic.gov.za/International/sanctions/Pages/search.aspx" target="_blank">Pages - Home l Financial Intelligence Centre</a></span>).
            </p>

            <!-- Confidentiality section -->
            <h4 class="table-heading" i18n>
              2. Confidentiality:
            </h4>
            <p i18n>
              Any information you receive or collect about us, our affiliates, any of our users
              or employees in connection with the Bug Bounty Program (“Confidential Information”)
              must be kept confidential and only used in connection with the Bug Bounty Program.
            </p>
            <p i18n>
              You may not use, disclose or distribute any such Confidential Information, including
              without limitation any information regarding your submission, without our prior written consent.
              To protect the Confidential Information, you shall use all reasonable precautions required
              to be used for protection of such information and shall keep the Confidential Information,
              including documents and copies thereof containing Confidential Information,
              in a way preventing third parties’ unauthorized access thereto.
            </p>
            <p i18n>
              <b>ChainEX</b> does not give permission/authorization (either implied or explicit) to an
              individual or group of individuals to (1) extract personal information or content of
              <b>ChainEX</b> Users or publicize this information on the open or public-facing internet
              without <b>ChainEX</b>’s consent or (2) modify or corrupt data belonging to <b>ChainEX</b>
              in order to extract and publicly disclose data belonging to <b>ChainEX</b>.
            </p>
            <p i18n>
              All the Confidential Information is owned solely by <b>ChainEX</b> (or its licensors) and the
              unauthorized disclosure or use of such Confidential Information may cause irreparable harm and
              significant injury, the degree of which may be difficult to ascertain. Accordingly, we will have
              the right to pursue immediate legal action in the event of any breach of these provisions,
              as well as the right to pursue any and all other rights and remedies available in law or equity
              for such breach, including indemnity.
            </p>
            <p i18n>
              <b>If you fail to protect the Confidential Information</b> specified herein, and in case it is
              found out that the Confidential Information has been disclosed and/or misused, including,
              but not limited to, posting publicly (including on social media) materials with Confidential Information,
              content containing false and/or impairing goodwill, you shall be obliged to pay a fine in the
              amount of R1 000 000 (one million rand) to be accrued in addition to any damages incurred.
            </p>
            <p i18n>
              Notwithstanding the end of the term of your submission or closing the issue in connection with fixing
              and payment of remuneration, the provisions regarding Confidential Information will survive for ten
              (10) years after the Confidential Information has been received and, with respect to Confidential
              Information that constitutes a trade secret, for so long as such Confidential
              Information remains a trade secret.
            </p>

            <!-- Report requirements section -->
            <h4 class="table-heading" i18n>
              3. Report requirements:
            </h4>
            <ul>
              <li i18n>
                Do not access customer or employee personal information. If you accidentally
                access any of these, please stop testing and submit the vulnerability.
              </li>
              <li i18n>
                Stop testing and report the issue immediately if you gain access to any
                non-public application or credentials.
              </li>
              <li i18n>
                Do not disrupt production systems, or destroy, or alter data during security
                testing.
              </li>
              <li i18n>
                Collect only the information necessary to demonstrate the vulnerability.
              </li>
              <li i18n>
                Include any necessary screenshots, screen captures, network requests, reproduction
                steps or similar in your submission (do not use third party file sharing sites).
              </li>
              <li i18n>
                When investigating a vulnerability, please only target your own account and do not
                attempt to access data from anyone else’s account as this will contradict the
                <b>ChainEX</b> Terms of Use.
              </li>
              <li i18n>
                When investigating a vulnerability, please avoid the destruction of data.
              </li>
              <li i18n>
                You are not allowed to exploit a security vulnerability in any other way
                than prescribed in this Policy.
              </li>
              <li i18n>
                Only the first verified vulnerability report can receive the reward.
              </li>
            </ul>

            <!-- Streamline the intake process section -->
            <h4 class="table-heading" i18n>
              4. To help streamline our intake process, we ask that submissions include:
            </h4>
            <ul>
              <li i18n>
                Vulnerability Types and Description of the vulnerability.
              </li>
              <li i18n>
                Steps to reproduce the vulnerability.
              </li>
              <li i18n>
                Proof of use (e.g. any necessary screenshots, screen captures or network requests).
              </li>
              <li i18n>
                Vulnerability Exploitation Probability.
              </li>
              <li i18n>
                List of URLs and affected payload parameters.
              </li>
              <li i18n>
                Other additional payloads, evidence of vulnerability and solutions.
              </li>
              <li i18n>
                Browser version, OS and/or app version used for testing.
              </li>
            </ul>
            <p i18n>
              <b>Note:</b> Failure to comply with these requirements or knowingly providing false
              information may result in ineligibility for a bounty and/or removal from the program.
            </p>

            <!-- Rights and licenses section -->
            <h4 class="table-heading" i18n>
              5. Rights and licenses:
            </h4>
            <p i18n>
              By making a submission, you give us the right to use your submission for any purpose.
              We may modify the Program Terms or cancel the Bug Bounty Program at any time.
            </p>

            <!-- Rewards section -->
            <h4 class="table-heading" i18n>
              6. Rewards:
            </h4>
            <p i18n>
              <b>ChainEX</b> may, at its sole discretion, provide rewards to eligible reporters
              of qualified vulnerabilities. The reward will be paid in cryptocurrency into your <b>ChainEX</b>
              account, as <b>ChainEX</b> deems appropriate.
            </p>
            <p i18n>
              The value of rewards paid out will vary depending on <b>Severity.</b> The severity is calculated
              according to our risk rating model based on <b>Impact</b> and <b>Probability.</b>
            </p>

            <h4 class="table-heading orange-header" i18n>
              <span>
                The following table outlines the nominal rewards for specific classes of vulnerabilities
                for in-scope properties (see the section on Scope).
              </span>
            </h4>
          </div>
          <div class="col-12 col-lg-4 px-3 px-lg-0">
          </div>
          <div class="col-12 col-lg-8">
            <img class="BPRewards" src="assets/{{siteName}}/images/pages/bounty-program/bounty-program-risk-reward-table-{{darkTheme ? 'darktheme' : 'lighttheme'}}.png">
          </div>
          <div class="col-12 col-lg-4 px-3 px-lg-0">
          </div>

          <!-- The fine print section -->
          <div class="col-12 col-lg-8">
            <h4 class="table-heading" i18n>
              7. The fine print:
            </h4>
            <p i18n>
              A bounty will only be awarded to the first person who reports a vulnerability, unless two or
              more people report the same vulnerability at approximately the same time, in which case
              the bounty may be split between them.
            </p>
            <ul>
              <li i18n>
                If the same vulnerability appears in multiple locations it will normally only receive a single bounty.
              </li>
              <li i18n>
                Reports of security-related vulnerabilities are not eligible for bounties if the vulnerabilities
                are publicly disclosed prior to being fixed.
              </li>
              <li i18n>
                Vulnerabilities need to be disclosed to the <b>ChainEX</b> team prior to receiving the bounty payment.
                The <b>ChainEX</b> team will not award a bounty payment upfront before knowing all details.
              </li>
              <li i18n>
                Known upstream vulnerabilities, such as a bug in the Bitcoin or other Alt Coin's codebases
                or in one of the libraries used by <b>ChainEX</b>, do not qualify for bounty payment.
              </li>
              <li i18n>
                The issue must be described in enough details to address it.
              </li>
              <li i18n>
                Only the discoverer of a vulnerability is eligible for the associated bounty.
              </li>
              <li i18n>
                All bounty claims need to be assessed individually on a case by case basis.
              </li>
              <li i18n>
                Bounties will be confirmed and awarded within 10 days of their assessment by the
                <b>ChainEX</b> team.
              </li>
              <li i18n>
                Inquiries on bounty status may be sent to <span class="orange-header"><a href="{{supportUrl}}" target="_blank">support</a></span>.
              </li>
              <li i18n>
                Bounties will not be awarded if it is illegal to do so.
              </li>
              <li i18n>
                The classification of vulnerabilities, values of bounties and conditions
                under which bounties are paid are subject to change without notice.
              </li>
              <li i18n>
                The <b>ChainEX</b> team has sole discretion to determine whether a vulnerability
                report qualifies for a bounty and for which bounty it qualifies.
              </li>
              <li i18n>
                If you have found a security issue that directly affects a cryptocurrency and/or
                its components (e.g. blockchain, node, wallet), please ensure that you report it
                directly to the respective project team.
              </li>
            </ul>
          </div>

          <!-- Scopes section -->
          <div class="col-12 col-lg-4 px-3 px-lg-0">
          </div>
          <div class="col-12 col-lg-8">
            <h4 class="table-heading" i18n>
              8. Scopes
            </h4>
          </div>
          <div class="col-12 col-lg-4 px-3 px-lg-0">
          </div>
          <div class="col-12 col-lg-8">
            <img class="BPRewards" src="assets/{{siteName}}/images/pages/bounty-program/bounty-program-in-scope-table-{{darkTheme ? 'darktheme' : 'lighttheme'}}.png">
          </div>
          <div class="col-12 col-lg-4 px-3 px-lg-0">
          </div>
          <div class="col-12 col-lg-8">
            <img class="BPRewards" src="assets/{{siteName}}/images/pages/bounty-program/bounty-program-out-of-scope-table-{{darkTheme ? 'darktheme' : 'lighttheme'}}.png">
          </div>
          <div class="col-12 col-lg-2 px-3 px-lg-0">
          </div>
        </div>
      </div>
    </div>
    <!-- Rewards update section - To be used at a later date when the API functionality has been implemented -->
    <!-- Mobile View -->
    <!-- <div [ngClass]="darkTheme ? 'dark-primary-bg':'primary-bg'" class="d-block d-xl-none col-12 px-0">
      <div class="col-12 col-lg-9 mx-auto">
        <h2 class="text-center my-5">
          <span class="orange-text" i18n><b>Rewards</b></span> updates
        </h2>
        <div>
          <div id="userCarousel" class="carousel carousel-fade" data-interval="false">
            <div [ngClass]="{'active': userComment.userName === userComments[0].userName}" class="carousel-item" *ngFor="let userComment of userComments">
              <shared-comment-card [userComment]="userComment"></shared-comment-card>
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#userCarousel" role="button" data-slide="prev">
        <img class="d-block my-auto" src="assets/chainex/images/pages/home/light-mode/left_triangle.svg">
      </a>
      <a class="carousel-control-next" href="#userCarousel" role="button" data-slide="next">
        <img class="d-block my-auto" src="assets/chainex/images/pages/home/light-mode/right-triangle.svg">
      </a>
    </div> -->

    <!-- Desktop View -->
    <!-- <div [ngClass]="darkTheme ? 'dark-primary-bg':'primary-bg'" class="d-none d-xl-block col-12 px-0">
      <div class="col-12 col-lg-8 mx-auto">
        <h2 class="text-center my-5" i18n><span class="orange-text"><b>Rewards</b></span> updates</h2>
        <div>
          <div id="userCarousel2" class="carousel carousel-fade" data-interval="false">
            <div class="carousel-item active">
              <div class="row d-flex flex-row justify-content-center">
                <div *ngFor="let userComment of userComments" class="col-3 px-2">
                  <shared-comment-card [userComment]="userComment"></shared-comment-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a class="carousel-control-prev carousel-button" href="#userCarousel2" role="button" data-slide="prev">
        <img class="d-block my-auto" src="assets/chainex/images/pages/home/light-mode/left_triangle.svg">
      </a>

      <a class="carousel-control-next carousel-button" href="#userCarousel2" role="button" data-slide="next">
        <img class="d-block my-auto" src="assets/chainex/images/pages/home/light-mode/right-triangle.svg">
      </a>
    </div>

    <div [ngClass]="darkTheme ? 'dark-primary-bg':'primary-bg'" class="col-12 px-0">
      <div class="vertical-spacer my-3 my-lg-5"></div>
    </div>
    <div class="col-12 px-0">
      <div class="vertical-spacer my-3 my-lg-5"></div>
    </div> -->

    <!-- Page footer -->
    <div class="col-12 px-lg-0 mt-5 text-center">
      <div class="vertical-spacer"></div>
      <h4 class="table-heading-footer" i18n>Log <span class="orange-header">Vulnerability</span></h4>
        <p class="col-12 col-lg-6 col-md-6 col-sm-6 mx-auto text-center" i18n>
          If you believe you have found a vulnerability, please click the below button to submit the vulnerability.
          Do not post the issue on GitHub or anywhere else until the issue has been resolved.
        </p>
      <div class="col-12 col-lg-3 mx-auto">
        <a href="https://support.chainex.io/open.php?topicId=73" target="_blank" class="mat-raised-button mat-primary orange-gradient w-100 mt-3 py-2 main-button footer-link" i18n>
          LOG VULNERABILITY NOW
        </a>
      </div>
    </div>

    <div class="col-12 px-0">
      <div class="vertical-spacer my-3 my-lg-5">
      </div>
    </div>

  </div>
</div>
