import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {

  address: typeof environment.config.ADDRESS_DETAILS = environment.config.ADDRESS_DETAILS;

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  getAppUrl(): string {
    return environment.config.APP_URL;
  }

}
