<div class="container-fluid">
  <div class="row back pt-1 pl-1">

    <div class="col-12 col-lg-3 col-xlg-2 mb-2 order-1 order-lg-0 full-market mat-elevation-z2">
      <markets-sidebar #sidebar class="h-100"></markets-sidebar>
    </div>

    <div class="col-12 col-lg-9 col-xlg-10 order-0 order-lg-1 full-table overflow-x-hidden">
      <div class="row">
        <div class="col-lg-12 fix-gutter">
          <div class="row mat-elevation-z2 mb-2 pt-3 details">
            <div class="col-sm-12">
              <h4 class="table-heading" i18n>ANTI MONEY LAUNDERING (AML) AND KNOW YOUR CUSTOMER (KYC) POLICY</h4>
              <ol>
                <li>
                  <h4 class="table-heading" i18n>INTRODUCTION</h4>
                  <p i18n>
                    ChainEX is committed to implementing and maintaining the highest standards of Anti Money Laundering
                    (AML) and Know Your Customer (KYC) compliance and requires management and employees to adhere to these
                    standards to prevent abuse of ChainEX’s products and services for money laundering and terrorist
                    financing purposes.
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>PRINCIPLES</h4>
                  <p class="row mt-0">
                    <span class="number col-1">2.1</span>
                    <span class="col-11" i18n>
                      In pursuing its commitment to assist in the detection, prevention, and reporting of money laundering
                      activities, ChainEX is guided by the following principles:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0">2.1.1</span>
                      <span class="col-11" i18n>
                        ChainEX implements a risk-based approach to assessing and implementing AML and KYC procedures;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0">2.1.2</span>
                      <span class="col-11" i18n>
                        ChainEX knows its Users by obtaining satisfactory evidence of their identity and having effective
                        procedures to verify the authenticity of the information furnished by new Users;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0">2.1.3</span>
                      <span class="col-11" i18n>
                        ChainEX ensures that its business is conducted in conformity with high ethical standards and in
                        compliance with suitable sanction screening processes imposed by, for example, the European Union,
                        the UK Treasury and the US Office of Foreign Assets Control, and to take measures to prevent transacting
                        with individuals appearing on these sanctions lists. Additionally, ChainEX ensures that laws and
                        regulations are adhered to, and that a service is not provided where there is good reason to believe that
                        transactions are associated with money laundering activities;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0">2.1.4</span>
                      <span class="col-11" i18n>
                        Subject to the legal constraints relating to User confidentiality, ChainEX fully cooperates with
                        law enforcement agencies by, among others, taking appropriate measures allowed by law if there are
                        reasonable grounds for suspecting money laundering;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0">2.1.5</span>
                      <span class="col-11" i18n>
                        ChainEX adopts policies consistent with the principles set out in this policy, and ensures that its
                        staff, wherever located, are informed of these policies and adequately trained in matters covered herein;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0">2.1.6</span>
                      <span class="col-11" i18n>
                        ChainEX implements specific procedures for User identification, record keeping and retention of
                        transaction documents and reporting of covered and suspicious transactions. ChainEx performs - if the circumstances so
                        require stringent procedures for Users presenting a higher risk, such as those
                        transacting large volumes and Politically Exposed Persons (PEPs); and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0">2.1.7</span>
                      <span class="col-11" i18n>
                        We may collect other data, including but not limited to, referral URLs, your location and blockchain.
                      </span>
                    </li>
                    <br/>
                  </ul>
                </li>
              </ol>
              <div class="alert alert-secondary clearfix" role="alert">
                  <div class="row">
                    <div class="col-1 reduce-max-w">
                        <mat-icon svgIcon="map-marker"></mat-icon>
                    </div>
                    <div class="col-11">
                      <p i18n>
                        {{address.NAME}},
                        {{address.STREET}}
                        {{address.TOWN}}
                        <br />
                        <span>{{address.REGISTRATION}} {{address.COUNTRY}}.</span>
                      </p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
