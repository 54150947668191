import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { AboutComponent } from './about/about.component';
import { FeesComponent } from './fees/fees.component';
import { ApiComponent } from './api/api.component';
import { SecurityComponent } from '../info/security/security.component';
import { BountyProgramComponent } from '../info/bounty-program/bounty-program.component';
import { LegalComponent } from './legal/legal.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { KycComponent } from './legal/kyc/kyc.component';
import { RiskComponent } from './legal/risk/risk.component';
import { MetaGuard } from '@ngx-meta/core';
import { environment } from '../../environments/environment';
import { InfoModule } from './info.module';
import { StablecoinComponent } from './stablecoin/stablecoin.component';
import { EarnComponent } from './earn/earn.component';
import { MarketMakerComponent } from './market-maker/market-maker.component';

const routes: Routes = [
  {
    path: 'terms', component: TermsOfServiceComponent, data: { title: 'Terms of Service',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Terms of Service',
      description: environment.config.EXCHANGE_NAME + ' ' + 'Terms of Service'
    } }, canActivate: [MetaGuard]
  },
  {
    path: 'about', component: AboutComponent, data:
    { title: 'About Us',
      meta: {
        title: 'About ' + environment.config.EXCHANGE_NAME + ' | South African Cryptocurrency Exchange',
        description: environment.config.EXCHANGE_NAME + ' ' +
          'is a South African cryptocurrency exchange that was founded in early 2018. join the revolution and start trading, and investing in crypto today!',
        'og:image': 'https://chainex.io/assets/chainex/images/meta/About-Us.png',
        'twitter:image': 'https://chainex.io/assets/chainex/images/meta/About-Us.png',
        'twitter:card': 'summary_large_image'
      }
    }, canActivate: [MetaGuard]
  },
  {
    path: 'fees', component: FeesComponent, data: { title: 'Fees',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Fees',
      description: 'All trading fees are collected in BTC.' +
      ' The buy order fee is added to the BTC order value,' +
      ' whereas the sell order fee is taken away from the BTC order value.',
      'og:image': 'https://chainex.io/assets/chainex/images/meta/Fees.png',
      'twitter:image': 'https://chainex.io/assets/chainex/images/meta/Fees.png',
      'twitter:card': 'summary_large_image'

    } }, canActivate: [MetaGuard]
  },
  {
    path: 'api', component: ApiComponent, data: { title: 'API',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'API',
description: environment.config.EXCHANGE_NAME + ' ' +
  'API offers a simple and more standardized REST API to make full use of our site functionality.'
    } }, canActivate: [MetaGuard]
  },
  {
    path: 'security', component: SecurityComponent, data: { title: 'Security',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Security',
      description: environment.config.EXCHANGE_NAME +
        'security measures allow for safe and secure online trading for all of our users.'
    } }, canActivate: [MetaGuard]
  },
  {
    path: 'bounty', component: BountyProgramComponent, data: { title: 'Bounty Program',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Bounty Program',
      description: environment.config.EXCHANGE_NAME +
        'The ChainEX Bug Bounty has been launched to encourage the responsible disclosure of any bugs or vulnerabilities found on ChainEX.',
    } }, canActivate: [MetaGuard]
  },
  {
    path: 'legal', component: LegalComponent, data: { title: 'Legal',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Legal',
      description: environment.config.EXCHANGE_NAME + ' ' + 'Limited legal policy.'
    } }, canActivate: [MetaGuard]
  },
  {
    path: 'privacy', component: PrivacyComponent, data: { title: 'Privacy Policy',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Privacy',
      description: 'In order to enable us to open and operate an account, ' +
      'to generally provide you with our products and services and comply with laws and' +
      ' regulations, we may need to and/or may be required to collect, record, hold, use, ' +
      'disclose and store personal information about you.'
    } }, canActivate: [MetaGuard]
  },
  {
    path: 'amlkyc', component: KycComponent, data: {
      title: 'Anti Money Laundering (AML) and Know Your Customer (KYC) Policy',
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - ' +
          'Anti Money Laundering (AML) and Know Your Customer (KYC) Policy',
        description: environment.config.EXCHANGE_NAME +
        ' is committed to implementing and maintaining the highest standards of ' +
        'Know Your Customer (KYC) and Anti Money Laundering (AML) compliance.'
      }
    }, canActivate: [MetaGuard]
  },
  {
    path: 'risk', component: RiskComponent, data: { title: 'Risk Disclosure ',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Risk Policy',
      description:
        'Digital Currency is a new technology and entails various risks that you should understand ' +
      '- some of which (but not limited to) we have highlighted here.'
    } }, canActivate: [MetaGuard]
  },
  {
    path: 'czar', component: StablecoinComponent, data: {
      title: 'ChainEX CZAR | ChainEX South African Rand Stable Coin',
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - ' + 'ChainEX CZAR | ChainEX South African Rand Stable Coin',
        description: 'CZAR is a stablecoin pegged to the South African Rand, backed and built by a secure and reliable cryptocurrency exchange.',
        'og:url': environment.config.APP_URL + '/czar',
        'og:type': 'website',
        'og:title': 'ChainEX CZAR | ChainEX South African Rand Stable Coin',
        'og:description': 'CZAR is a stablecoin pegged to the South African Rand, backed and built by a secure and reliable cryptocurrency exchange.',
      }
    }, canActivate: [MetaGuard],
  },
  {
    path: 'earn', component: EarnComponent, data: {
      title: 'ChainEX Earn Portal | Earn interest on your crypto!',
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - ' + 'Earn Portal | Earn interest on your crypto!',
        description: 'Earn interest on your crypto with ChainEX!',
        'og:url': environment.config.APP_URL + '/earn',
        'og:type': 'website',
        'og:title': 'ChainEX Earn Portal | Earn interest on your crypto!',
        'og:description': 'Earn interest on your crypto with ChainEX!',
      }
    }, canActivate: [MetaGuard],
  },
  {
    path: 'maker', component: MarketMakerComponent, data: {
      title: 'Negative Maker Fees ChainEX | South African Cryptocurrency Exchange',
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - ' + 'Negative Maker Fees ChainEX | South African Cryptocurrency Exchange',
        description: 'Securely trade and earn Negative Maker fees on ChainEX, a proudly South African cryptocurrency exchange.',
        'og:url': environment.config.APP_URL + '/maker',
        'og:type': 'website',
        'og:title': 'Negative Maker Fees ChainEX | South African Cryptocurrency Exchange',
        'og:description': 'Securely trade and earn Negative Maker fees on ChainEX, a proudly South African cryptocurrency exchange.',
      }
    }, canActivate: [MetaGuard],
  },

];

export const routing: ModuleWithProviders<InfoModule> = RouterModule.forChild(routes);
