import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})
export class ApiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  getApiUrl(): string {
    // check and strip out if the last char is a /
    return environment.config.API_URL[environment.config.API_URL.length - 1] === '/'
      ? environment.config.API_URL.substring(0, environment.config.API_URL.length - 1) :  environment.config.API_URL;
  }
}
