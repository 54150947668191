import { AuthGuard, NoAuthGuard } from './../core/services/auth-guard.service';
import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';

import { routing } from './info.routing';
import { CommonModule } from '@angular/common';

import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { FeesComponent } from './fees/fees.component';
import { ApiComponent } from './api/api.component';
import { SecurityComponent } from './security/security.component';
import { BountyProgramComponent } from './bounty-program/bounty-program.component';
import { LegalComponent } from './legal/legal.component';
// import { HistoryComponent } from '../history/history.component';
import { KycComponent } from './legal/kyc/kyc.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { RiskComponent } from './legal/risk/risk.component';
import { AboutComponent } from './about/about.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StablecoinComponent } from './stablecoin/stablecoin.component';
import { EarnComponent } from './earn/earn.component';
import { InfoFooterComponent } from './shared/info-footer/info-footer.component';
import { MarketMakerComponent } from './market-maker/market-maker.component';

@NgModule({
  imports: [
    CommonModule,
    routing,
    HttpClientModule,
    MatButtonModule,
    MatSelectModule,
    MatTableModule,
    MatInputModule,
    MatGridListModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    ChartsModule,
    MatMenuModule,
    MatSortModule,
    MatCardModule,
    SharedModule
  ],
  declarations: [
    AboutComponent,
    TermsOfServiceComponent,
    FeesComponent,
    ApiComponent,
    SecurityComponent,
    BountyProgramComponent,
    LegalComponent,
    // HistoryComponent,
    KycComponent,
    PrivacyComponent,
    RiskComponent,
    StablecoinComponent,
    EarnComponent,
    InfoFooterComponent,
    MarketMakerComponent
  ],
  providers: [
    AuthGuard,
    NoAuthGuard
  ],
  entryComponents: [MatIcon],
})

export class InfoModule { }
