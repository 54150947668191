<div id="page-top"></div>
<app-header></app-header>

<div class="row container-fluid mx-0 px-0" [ngClass]="infoService.darkTheme ? 'dark-theme-background' : 'light-theme-background'">
  <div class="blueprint-background">
    <div class="vertical-spacer d-none d-lg-block"></div>
    <div class="col-12 col-lg-10 pt-4 pt-lg-0 mt-3 pb-3 mx-auto">
      <div class="row">
      <div class="col-12 col-lg-3">
        <h2 i18n>About <span class="orange-text"><b>ChainEX</b></span></h2>
      </div>
      <div class="col-12 col-lg-9 px-lg-0">
          <p>
          Our team of experts has worked all over the world for and with top companies.
          They’ve been pulled together from various backgrounds and industries, on a
          mission to upgrade the world to a better financial system. ChainEX is a
          proudly South African Cryptocurrency Exchange!</p>
          <p>
          On the journey to becoming the best cryptocurrency exchange in South Africa,
          we aim to build a large community here at ChainEX. We’re not just another
          cryptocurrency exchange with bad support and slow withdrawals. No, we’re a
          cryptocurrency exchange in South Africa that prides itself on excellence and
          keeps you as a partner at the forefront of our minds! From fast support to a
          helpful Telegram community, to bi-weekly community calls and a host of
          educational content, why wouldn’t you choose us?</p>
          <p>
          At ChainEX, we don’t believe in chance, coincidence or that success is only
          reserved for some. We believe in purpose and that if you make up your mind and
          follow your dreams wholeheartedly, believing that anything is possible, the
          rest will follow. Crypto and altcoins are on the rise. When will you get involved?</p>
          <p>
          The future of crypto is bright and at ChainEX as we continuously work on
          improving the exchange for you. Taking your queries into consideration.
          Our plans include expanding our asset base by introducing more cryptocurrencies
          to our exchange, providing you with an even better experience and more exposure
          to the world of cryptocurrencies.</p>
      </div>
      </div>
    </div>
    <div class="vertical-spacer d-none d-lg-block"></div>
    <div class="col-12 col-lg-10 pt-3 pt-lg-0 pb-2 pb-lg-5 mb-2 mb-lg-3 mx-auto">
      <div class="row">
      <div class="col-12 col-lg-3">
        <h2 i18n>Your <span class="orange-text"><b>Security</b></span></h2>
      </div>
      <div class="col-12 col-lg-9 px-lg-0">
        <p>Your security and the safety of your assets is so important to us. So important that we
          provide 2FA (2-Factor Authentication) for you to secure your account. Not only do we
          offer 2FA, but we’ve also protected all our pages with state of the art SSL certificates
          ensuring the encryption of your data.</p>
        <div class="col-12 col-xl-4 mb-0 mb-lg-3 mx-auto">
          <a routerLink="/security" class="mat-raised-button mat-primary orange-gradient w-100 mt-3 py-2 main-button" i18n>CHAINEX SECURITY</a>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="map-background">
  <div class="col-12 col-lg-10 mt-2 mt-lg-5 pt-2 pt-lg-5 pb-1 mx-auto">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 i18n>Our <span class="orange-text"><b>markets</b></span></h2>
        </div>
        <div class="col-12 col-lg-9 px-lg-0">
          <p>
            ChainEX isn't just your typical cryptocurrency exchange. We offer a host of features that
            benefit you! But enough about us. Let's talk about you. New to crypto? Don’t worry, we’ve
            got your back, learn more about our selected cryptocurrencies <span class="orange-header"><a routerLink="/coins">on our coin info page</a></span>.
            Did you know you can repeatedly & automatically buy your chosen cryptocurrency as soon as
            your deposit clears? Our instant trade on deposit feature gives you automated freedom.</p>
          <p>
            We also have accumulation and distribution orders & scaling fees. That's like having your
            own little trading bot trying to distribute and accumulate certain coins. We're currently
            the only exchange in South Africa that does that. How cool is that?!</p>
          <p>
            Fast Clearing is an added function that allows non-Standard Bank users to process their
            withdrawals on the same day they have made them! Our bank of choice is Standard Bank.
            <span class="orange-header"><a href="{{supportUrl}}/kb/functionality/what-is-fast-clearing-and-how-to-use-it" target="_blank">Here’s how it works</a></span>.</p>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 mx-auto">
      <div class="col-12 px-lg-0">
        <div class="row w-100 mx-0 py-3 d-flex justify-content-center">
          <div [ngClass]="infoService.darkTheme ? 'dark-stats-shadow':'stats-shadow'"
            class="col-11 col-sm-8 col-lg-3 col-xl mx-lg-3 my-3 py-4 px-2 clickable market-card-container" *ngFor="let price of coinPrices">
            <home-market-card [market]="price" [darkTheme]="infoService.darkTheme"></home-market-card>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-10 mt-4 mx-auto">
      <div class="col-12 px-lg-0">
        <p class="col-12 col-lg-9 mx-auto text-center">We have a host of unique order types, such as scaled orders,
          market orders, stop & stop limit
          orders.<br>Sign up to view and use them all!</p>
        <div class="col-12 col-xl-4 mb-3 mx-auto">
          <a routerLink="/markets" class="mat-raised-button mat-primary orange-gradient w-100 mt-3 py-2 main-button" i18n>
            SEE ALL MARKETS
          </a>
        </div>
      </div>
      <div class="vertical-spacer d-none d-lg-block"></div>
    </div>
  </div>

  <div class="blueprint-background-flipped">
    <div class="col-12 col-lg-10 mt-2 mt-lg-5 pt-3 pb-3 pb-lg-0 mx-auto">
      <div class="row">
      <div class="col-12 col-lg-3">
        <h2 i18n>Our <span class="orange-text"><b>Team</b></span></h2>
      </div>
      <div class="col-12 col-lg-9 px-lg-0">
        <p>The ChainEX team comes from a wide range of backgrounds and industry, but all
          united in our passion to revolutionise the way people use money</p>

      <div class="row">
        <div class="col-lg-3 col-md-4 col-6 text-center my-2 scale-in-center" *ngFor="let i of teamList" >
          <div class="photo-holder">
            <img [src]="i.imgSrc" alt="{{i.name}}" class="photo" loading="lazy">
          </div>
          <h3 class="team-name text-uppercase mt-4">{{i.name}}</h3>
          <h4 class="team-description my-3">{{i.jobDescription}}</h4>
        </div>
      </div>
      </div>
      </div>
    </div>
    <div class="vertical-spacer d-none d-lg-block"></div>
  </div>

  <div [ngClass]="infoService.darkTheme ? 'dark-primary-bg':'primary-bg'" class="col-12 px-0">
    <div class="col-12 mt-5 pb-5 d-flex justify-content-center">
      <div class="row w-100">
      <div class="col-12 px-0">
        <h4 class="text-center" i18n>
          Buy and sell Bitcoin and other currencies with ease on <b>ChainEX</b>
        </h4>
        <h4 class="text-center mb-4">
          <span class="emphasis-heading"><span class="orange-text">Join</span> the revolution</span> by investing as
          little as R10!
        </h4>
      </div>
      <div class="col-12 col-xl-4 pt-3 mx-auto">
        <a routerLink="/register" class="mat-raised-button mat-primary orange-gradient mb-0 w-100 py-2 main-button"
          i18n>START TRADING TODAY</a>
      </div>
      </div>
    </div>
  </div>

  <div class="col-12 pt-5 pb-5 address">
    <div class="row mx-0 w-100 justify-content-center address-box">
      <div class="col-auto d-flex align-items-center pl-0 pr-2">
        <mat-icon class="mb-2" svgIcon="map-marker"></mat-icon>
      </div>
      <div class="col-auto px-0 mb-lg-0">
          <p class="text-center mb-0">
            <span class="d-block d-lg-inline px-0">{{address.NAME}},&nbsp;</span>
            <span class="d-block d-md-inline px-0">{{address.STREET}}&nbsp;</span>
            <span class="d-block d-md-inline px-0">{{address.TOWN}}&nbsp;</span><br class="d-none d-md-inline">
            <span class="d-block d-md-inline px-0" i18n>{{address.REGISTRATION}}&nbsp;</span>
            <span class="d-block d-md-inline px-0">{{address.COUNTRY}}.</span>
          </p>
      </div>
    </div>
  </div>
  <div class="vertical-spacer w-100 my-5 d-none d-lg-block"></div>
</div>

<!-- footer -->
<app-info-footer></app-info-footer>
