import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-risk',
  templateUrl: './risk.component.html',
  styleUrls: ['./risk.component.scss']
})
export class RiskComponent {

  address: typeof environment.config.ADDRESS_DETAILS = environment.config.ADDRESS_DETAILS;

}
