<div class="container-fluid">
  <div class="row back pt-1 pl-1">

    <div class="col-12 col-lg-3 col-xlg-2 order-1 order-lg-0 mb-2 full-market mat-elevation-z2">
      <markets-sidebar #sidebar class="h-100"></markets-sidebar>
    </div>

    <div class="col-12 col-lg-9 col-xlg-10 order-0 order-lg-1 full-table overflow-x-hidden">
      <div class="row">
        <div class="col-lg-12 fix-gutter">
          <div class="row mat-elevation-z2 mb-2 pt-3 details">
            <div class="col-sm-12">
              <h4 class="table-heading" id="terms" i18n>TERMS OF USE AND CONDITIONS</h4>
              <ol class="list-unstyled mx-3">
                <li>
                  <h4 class="table-heading" i18n>INTRODUCTION</h4>

                  <p i18n>Thank you for choosing ChainEX!</p>
                  <p i18n><strong>PLEASE READ THESE TERMS OF USE AND CONDITIONS CAREFULLY.</strong></p>
                  <p i18n>
                    <strong>BY CLICKING THE "CREATE ACCOUNT" BUTTON OR BY ACCESSING OR USING THE CHAINEX SITE, YOU AGREE
                    TO BE LEGALLY BOUND BY THESE TERMS OF USE AND CONDITIONS AND ALL LAWS, REGULATIONS AND POLICIES REFERENCED HEREIN.
                    IF YOU DO NOT AGREE TO OR ACCEPT ALL OF THE TERMS OF THIS AGREEMENT, PLEASE IMMEDIATELY DISCONTINUE ACCESS TO,
                    AND USE OF, ALL CHAINEX SERVICES AND PRODUCTS.</strong>
                  </p>

                  <p i18n>
                    This is an Agreement between you and ChainEX - a proudly South African private (limited) company duly incorporated
                    under the Companies Act 2008 with company registration number: 2017/439541/07. By signing up to use an account
                    through ChainEX, or any associated websites, APIs, or mobile applications (collectively the "ChainEX Site"), you
                    agree that you have read, understood, accept, and agree to be bound to, and are bound to, all of the terms and conditions
                    contained in this Agreement and the Annexures hereto as well as our <a target="_blank" routerLink="/privacy">Privacy Policy</a>.
                  </p>

                  <p i18n>
                    The services are owned and controlled by ChainEX. These terms affect your rights and obligations in law - including but not
                    limited to the binding arbitration provisions contained below, that may significantly affect your legal rights, including your
                    right to file a lawsuit in court. If you do not agree to be bound by these terms, please do not access or use the services.
                  </p>
                  <p i18n>
                    ChainEX reserves the right to change or modify the terms and conditions contained in these terms, including but not limited to,
                    any policy or guideline of the site, at any time and at its sole discretion. We will provide notice of these changes by posting
                    the revised terms to the site and changing the "Last Revised" date at the bottom of the terms.
                  </p>
                  <p i18n>
                    There are clauses in this Agreement and the annexures thereto, that require your careful consideration. To assist you, those terms
                    which may limit any of your rights, or which provide for specific acknowledgements or warranties required by/ from you, are in bold
                    font. You acknowledge that you have read and appreciate the importance of the terms and clauses in bold font.
                  </p>
                </li>
              </ol>
              <ol class="list-unstyled mx-3">
                <li>
                  <h4 class="table-heading" i18n>DEFINITIONS</h4>

                  <p class="row mt-0">
                    <span class="number col-1">1.1</span>
                    <span class="col-11" i18n>
                      <strong>"ChainEX"</strong> refers to a private (limited) company duly incorporated under the Companies Act, 71 of 2008, with
                      company registration number: 2017/439541/07.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.2</span>
                    <span class="col-11" i18n>
                      <strong>"ChainEX services"</strong> include but are not limited to Digital Currency Trading Platforms.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.3</span>
                    <span class="col-11" i18n><strong>"Platform"</strong> refers to ChainEX as a Digital Currency Trading Platform.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.4</span>
                    <span class="col-11" i18n>
                      <strong>"Users"</strong> refers to all individuals, institutions or organisations that access, download or use ChainEX services
                      and who meet the criteria and conditions stipulated by ChainEX.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.5</span>
                    <span class="col-11" i18n>
                      <strong>"Digital Currency"</strong> refers to encrypted or digital tokens or cryptocurrencies with a certain value that are based
                      on blockchain and cryptography technologies and are issued and managed in a decentralized form.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.6</span>
                    <span class="col-11" i18n>
                      <strong>"Digital Assets"</strong> refers to Digital Currencies, their derivatives or other types of digitalized assets with a
                      certain value.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.7</span>
                    <span class="col-11" i18n>
                      <strong>"Trading"</strong> refers to transactions in which one Digital Currency is exchanged for another Digital Currency.
                      This includes transactions in which Digital Currencies are exchanged for Fiat currency or vice versa.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.8</span>
                    <span class="col-11" i18n>
                      <strong>"Fait Currency"</strong> refers to a currency established as money, often by government regulation.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.9</span>
                    <span class="col-11" i18n>
                      <strong>"Account"</strong> refers to a user's account with ChainEX.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.10</span>
                    <span class="col-11" i18n>
                      <strong>"ZAR"</strong> refers to the South African Rand.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>SUPPLEMENTARY TERMS</h4>

                  <p class="row mt-0">
                    <span class="number col-1">2.1</span>
                    <span class="col-11" i18n>
                      Due to the rapid development of Digital Currencies and ChainEX, these terms of use do not enumerate or cover all rights
                      and obligations of each party, and do not guarantee full alignment with needs arising from future development. Therefore,
                      <strong><a href="/privacy">THE PRIVACY POLICY</a>, <a href="/amlkyc"> ANTI MONEY LAUNDERING (AML) AND KNOW YOUR CUSTOMER
                      (KYC) POLICY</a> AND <a href="/risk"> RISK DISCLOSURE</a></strong> are deemed supplementary terms that are an integral
                      part of these terms and shall have the same legal effect. Your use of ChainEX services is deemed your acceptance of the
                      above supplementary terms. ChainEX reserves the right to change or modify these terms of use in its discretion at any
                      time. ChainEX will notify such changes by updating the terms on its platform <a routerLink="/" target="_blank">
                      ({{getAppUrl()}})</a> and modifying the last revised date displayed on this page, or by emailing Users at their provided
                      email addresses, or by any other means as determined by ChainEX. The method of notification will be left to ChainEX's
                      sole discretion.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">2.2</span>
                    <span class="col-11" i18n><strong>
                      ANY AND ALL MODIFICATIONS OR CHANGES TO THESE TERMS WILL BECOME EFFECTIVE UPON PUBLICATION ON THE PLATFORM OR RELEASE
                      TO USERS. THEREFORE, YOUR CONTINUED USE OF CHAINEX SERVICES IS DEEMED YOUR ACCEPTANCE OF THE MODIFIED AGREEMENT AND
                      RULES. IF YOU DO NOT AGREE TO ANY CHANGES TO THESE TERMS, YOU MUST STOP USING CHAINEX SERVICES IMMEDIATELY. YOU ARE
                      RECOMMENDED TO FREQUENTLY REVIEW THESE TERMS TO ENSURE YOUR UNDERSTANDING OF THE TERMS AND CONDITIONS THAT APPLY TO
                      YOUR ACCESS TO AND USE OF CHAINEX SERVICES.
                    </strong></span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>PREAMBLE</h4>

                  <p class="row mt-0">
                    <span class="number col-1">3.1</span>
                    <span class="col-11" i18n>Your ChainEX account encompasses the following basic services:</span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">3.1.1</span>
                      <span class="col-11" i18n>
                        <strong>One</strong> hosted account (with possible sub-accounts) that allow Users to store certain supported
                        digital currencies and to track, transfer, and manage their supported digital currencies;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">3.1.2</span>
                      <span class="col-11" i18n>
                        Digital Currency conversion services through which Users can buy and sell supported Digital Currency in
                        transactions with ChainEX; and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">3.1.3</span>
                      <span class="col-11" i18n>
                        A Fiat Currency (e.g. ZAR) account for use in connection with other ChainEX services. Our services do not provide
                        Users with the ability to trade one form of legal tender for another form of legal tender.
                      </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">3.2</span>
                    <span class="col-11" i18n>
                      The hosted Digital Currency wallet services allow you to send supported Digital Currency to, and request, receive,
                      and store supported Digital Currency from, third parties pursuant to instructions you provide through the ChainEX
                      site (each such transaction is a "Digital Currency Transaction"). The hosted Digital Currency wallet services are
                      available only in connection with those Digital Currencies that ChainEX, in its sole discretion, decides to support.
                      The Digital Currencies that ChainEX supports may change from time to time.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.3</span>
                    <span class="col-11" i18n>
                      ChainEX processes supported Digital Currency according to the instructions received from its Users and does not
                      guarantee the identity of any user, receiver, requestee or other party. You should verify all transaction
                      information prior to submitting instructions to ChainEX. Once submitted to a Digital Currency network, a Digital
                      Currency Transaction will be unconfirmed for a period of time pending sufficient confirmation of the transaction
                      by the Digital Currency network. A transaction is not complete while it is in a pending state. Funds associated
                      with transactions that are in a pending state will be designated accordingly and will not be included in your
                      ChainEX account balance, nor available to conduct transactions.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.4</span>
                    <span class="col-11" i18n>
                      ChainEX is not a principal in any transaction. In giving an instruction, the User appoints ChainEX as its agent
                      to match the User's instructions to buy or sell the specified Digital Currencies at the price nominated by the
                      User, against instructions from other Users. Upon matching, all transactions will be subject to immediate
                      settlement and delivery, and any instruction is irrevocable authority, from the User to ChainEX, to access the
                      User's account to settle the transaction and deduct any commission, or fee, or other amount owing to ChainEX
                      under these terms, or for any other reason.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.5</span>
                    <span class="col-11" i18n>
                      You cannot cancel, reverse, or change any transaction marked as complete or pending. If your payment is
                      unsuccessful, or if your payment method has insufficient funds, you authorise ChainEX, in its sole discretion,
                      either to cancel the transaction or to debit your other payment methods, including ChainEX balances or other
                      linked accounts, in any amount necessary to complete the transaction.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.6</span>
                    <span class="col-11" i18n>
                      You are responsible for maintaining an adequate balance and/or sufficient credit limits in order to avoid
                      overdraft, insufficient funds, or similar fees charged by your payment provider. ChainEX reserves the right to
                      refuse to process, or to cancel or reverse, any purchases or sales of Digital Currency in its sole discretion,
                      even after funds have been debited from your account(s), if ChainEX suspects the transaction relates to, or has
                      a high risk of involvement in (but not limited to) the <a href="/terms#prohibited"> Prohibited Use or Prohibited
                      Business</a> list as set forth below. In such instances, ChainEX will reverse the transaction and is under
                      no obligation to allow you to reinstate a purchase or sale order at the same price or on the same terms as the
                      cancelled transaction. Additionally, under no circumstances are you permitted to withdraw any cryptocurrency or
                      Fiat Currency into an external address or bank account which appears on the <a href="/terms#prohibited">
                      Prohibited Use or Prohibited Business</a> list or any government or international body sanctions list.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.7</span>
                    <span class="col-11" i18n>
                      It is solely your responsibility to determine whether, and to what extent, any taxes apply to any transactions
                      you conduct through the ChainEX services, and to withhold, collect, report and remit the correct amount of taxes
                      to the appropriate tax authorities. Your transaction history is available through your ChainEX account.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.8</span>
                    <span class="col-11" i18n>
                      ChainEX's site may include commentary, information or services on Digital Currency, the market for Digital Currency,
                      uses for Digital Currency and other information, interactive tools, quotes, reports and data concerning Digital
                      Currency and trading in Digital Currency and other subject matter. Some of this information may be supplied by
                      entities not affiliated with ChainEX. To the maximum extent possible, ChainEX will identify and attribute all
                      information sourced from third parties. ChainEX does not, explicitly or implicitly, endorse or approve third-party
                      information. While ChainEX takes reasonable steps to ensure that the third-party information on its website is
                      correct and up to date, ChainEX does not verify or validate such information and takes no responsibility for its
                      content or any consequence of any person placing reliance on the third-party information.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.9</span>
                    <span class="col-11" i18n>
                      When you create a ChainEX account, you agree to: (a) create a strong password that you do not use for any other
                      website or online service; (b) provide accurate and truthful information; (c) maintain and promptly update your
                      ChainEX account information, (d) maintain the security of your ChainEX account by protecting your password and
                      restricting access to your ChainEX account; (e) promptly notify ChainEX if you discover or otherwise suspect any
                      security breaches related to your ChainEX account, and (f) take responsibility for all activities that occur under
                      your ChainEX account and accept all risks of any authorised or unauthorised access to your ChainEX account, to the
                      maximum extent permitted by law.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>ELIGIBILITY</h4>
                  <p class="row mt-0">
                    <span class="number col-1">4.1</span>
                    <span class="col-11" i18n>
                      By accessing or using the site, you represent and warrant that (a) as an individual, you are of legal age and legal capacity to form a
                      binding contract under the applicable laws; (b) Should you be contracting on behalf of a minor, you warrant that you are legally
                      competent to contract on their behalf; (c) as an individual, legal person, or other organisation, you have full legal capacity and sufficient
                      authorizations to enter into these terms; (d) you have not been previously suspended or removed from using ChainEX services; (e) you do not
                      currently have a ChainEX account; (f) if you act as an employee or agent of a legal entity, and enter into these terms on its behalf, you
                      represent and warrant that you have all necessary rights and authorizations to bind such legal entity; (g) your use of ChainEX services will
                      not violate any laws and regulations applicable to you, including but not limited to regulations on anti-money laundering, anti-corruption,
                      and counter-terrorist financing.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">4.2</span>
                    <span class="col-11" i18n>
                      By accessing or using the site, you represent and warrant that:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1">4.2.1</span>
                      <span class="col-11" i18n>
                        you are at least 18 years old; and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">4.2.2</span>
                      <span class="col-11" i18n>
                        that you will not use the products and services to engage in any activities relating to the <strong>Prohibited Use or Prohibited Business</strong>
                        list as set forth below.
                      </span>
                    </li>
                  </ul><br>
                  <p class="row mt-0">
                    <span class="number col-1">4.3</span>
                    <span class="col-11" i18n>
                      In order to use certain features of the ChainEX services, including certain transfers of Digital Currencies and/or
                      government-issued currency ("Fiat Currency"), you may be required to provide ChainEX with certain personal information,
                      including, but not limited to, your name, address, telephone number, e-mail address, date of birth, taxpayer identification
                      number, government identification number, and information regarding your bank account (e.g., financial institution, account
                      type and account number). In submitting this information, or any other personal information as may be required, you verify
                      that the information is accurate and authentic, and you agree to update ChainEX if any information changes.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">4.4</span>
                    <span class="col-11" i18n>
                      You hereby authorise ChainEX to, directly or through third parties, make any inquiries that ChainEX considers necessary to:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">4.4.1</span>
                      <span class="col-11" i18n>
                        Verify your identity and/or protect against fraud, including to query identity information contained in public reports (e.g.,
                        your name, address, previous addresses, or date of birth, etc);
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">4.4.2</span>
                      <span class="col-11" i18n>
                        Query account information associated with your linked bank account (e.g., name or account balance); and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">4.4.3</span>
                      <span class="col-11" i18n>
                        Take action ChainEX reasonably deems necessary based on the results of such inquiries and reports.
                      </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">4.5</span>
                    <span class="col-11" i18n>
                      You further authorise any and all third parties, to which such inquiries or requests may be directed, to fully respond to such
                      inquiries or requests.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">4.6</span>
                    <span class="col-11" i18n>
                      By accessing and using ChainEX services, you represent and warrant that you have not been included in any trade embargoes or
                      economic sanctions list. ChainEX reserves the right to choose markets and jurisdictions to conduct business, and may restrict
                      or refuse, in its discretion, the provision of ChainEX services in certain countries or regions.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">4.7</span>
                    <span class="col-11" i18n>
                      Notwithstanding the foregoing, ChainEX may, in its sole discretion, refuse to allow you to establish a ChainEX account, and may
                      limit the number of ChainEX accounts that a single user may establish and maintain at any time.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>RISK DISCLOSURE</h4>

                  <p class="row mt-0">
                    <span class="number col-1">5.1</span>
                    <span class="col-11" i18n>
                      ChainEX is not licensed as Financial Services Provider in terms of the Financial Advisory and Intermediaries Services Act, No. 37
                      of 2002 ("FAIS") and it does not furnish any advice as defined in FAIS, whether it be general or personal financial advice. Nothing
                      in this website is intended to be or should be taken as financial, legal, or taxation advice. Users should seek their own independent
                      financial, legal, tax and accounting advice in respect of the likely outcomes of their trading of Digital Currencies.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">5.2</span>
                    <span class="col-11" i18n>
                      ChainEX may experience sophisticated cyber-attacks, unexpected surges in activity, or other operational or technical difficulties
                      that may cause interruptions in the service. You understand that the service may experience operational issues that lead to delays
                      on our platform. You agree to and accept the risk of transaction failure resulting from unanticipated or heightened technical
                      difficulties, including those resulting from sophisticated attacks. You hereby indemnify ChainEX against any related losses you incur
                      resulting from any unanticipated or heightened technical difficulties, including those resulting from sophisticated attacks. You
                      further agree not to hold ChainEX accountable for any related losses.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">5.3</span>
                    <span class="col-11" i18n>
                      ChainEX does not own or control the underlying software protocols (e.g. blockchain network), which govern the operation of Digital
                      Currencies supported on the ChainEX platform. By using ChainEX, you acknowledge and agree that:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">5.3.1</span>
                      <span class="col-11" i18n>
                        ChainEX is not responsible for the operation of the underlying protocols and that ChainEX makes no guarantee of their functionality,
                        security, or availability;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">5.3.2</span>
                      <span class="col-11" i18n>
                        The underlying protocols are subject to sudden changes in operating rules (AKA "forks"), and that such forks may materially affect
                        the value, function, and/or the name of the Digital Currency you store in the ChainEX platform;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">5.3.3</span>
                      <span class="col-11" i18n>
                        In the event of a fork, you agree that ChainEX may temporarily suspend its operations (with or without advance notice to you) and
                        that ChainEX may, in its sole discretion, decide whether or not to support (or cease supporting) either branch of the forked protocol
                        entirely;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">5.3.4</span>
                      <span class="col-11" i18n>
                        You acknowledge and agree that ChainEX assumes absolutely no responsibility whatsoever in respect of an unsupported branch of a
                        forked protocol; and,
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">5.3.5</span>
                      <span class="col-11" i18n>
                        ChainEX cannot confirm, cancel or reverse the protocol transactions, and you expressly agree that ChainEX is not liable for any
                        errors, loss, or omissions due to sending or receiving currencies to, or from, incorrect wallet addresses, e-mail addresses, or by
                        entering incorrect transaction amounts, or any other information related to protocol transactions by using the service.
                      </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">5.4</span>
                    <span class="col-11" i18n>
                      ChainEX securely stores all Digital Currency private keys in its control, in a combination of online and offline storage. As a
                      result, it may be necessary for ChainEX to retrieve certain information from offline storage in order to facilitate a Digital
                      Currency transaction in accordance with your instructions, which may delay the initiation or crediting of such Digital Currency
                      transaction for 48 hours or more. You acknowledge and agree that a Digital Currency transaction facilitated by ChainEX may be
                      delayed.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">5.5</span>
                    <span class="col-11" i18n>
                      The risk of loss in trading or holding Digital Currencies can be substantial. You should therefore carefully consider whether
                      trading or holding Digital Currencies is suitable for you in light of your financial condition.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>GENERAL USE</h4>

                  <p class="row mt-0">
                    <span class="number col-1">6.1</span>
                    <span class="col-11" i18n>
                      ChainEX grants you a limited, nonexclusive, non-transferable license, subject to the terms of this Agreement, to access and use
                      the ChainEX site, and related content, materials, information (collectively, the "Content") solely for approved purposes as
                      permitted by ChainEX. Any other use of the ChainEX site or content is expressly prohibited and all other right, title, and
                      interest in the ChainEX site or content is exclusively the property of ChainEX and its licensors. You agree to not copy, transmit,
                      distribute, sell, license, reverse engineer, modify, publish, participate in the transfer or sale of, create derivative works
                      from, or in any other way exploit, any of the content, in whole or in part. "ChainEX.io", "ChainEX", and all logos related to the
                      ChainEX services or displayed on the ChainEX site are either trademarks or registered marks of ChainEX or its licensors. You may
                      not copy, imitate or use them without ChainEX's prior written consent.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">6.2</span>
                    <span class="col-11" i18n>
                      Although ChainEX intends to provide accurate and timely information on the ChainEX site, the ChainEX site (including, without
                      limitation, the content) may not always be entirely accurate, complete, or current, and may also include technical inaccuracies
                      or typographical errors. Accordingly, you should verify all information before relying on it, and all decisions made by you based
                      on information contained on the ChainEX site, are solely your responsibility and ChainEX shall have no liability for any such
                      decisions. Links to third-party materials (including, without limitation, websites) may be provided as a convenience but are not
                      controlled by ChainEX. You acknowledge and agree that ChainEX is not responsible for any aspect of the information, content, or
                      services contained in any third-party materials or on any third-party sites accessible or linked to the ChainEX site.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">6.3</span>
                    <span class="col-11" i18n>
                      If, to the extent permitted by ChainEX from time to time, you grant express permission to a third-party to access or connect to
                      your ChainEX account, either through the third party's product, or service, or through the ChainEX site, you acknowledge that
                      granting permission to a third-party to take specific actions on your behalf does not relieve you of any of the responsibilities
                      under this Agreement. You are fully responsible for all acts, or omissions, of any third-party with access to your ChainEX account.
                      Further, you acknowledge and agree that you will not hold ChainEX responsible for, and will indemnify ChainEX from, any liability
                      arising out of, or related to, any act or omission of any third-party with access to your ChainEX account.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">6.4</span>
                    <span class="col-11" i18n>
                      The use of all ChainEX services is subject to a limit on the amount of volume, stated in Fiat Currency terms, you may transact or
                      transfer in a given period (e.g., daily). To view your limits, login to your ChainEX account and visit
                      <a target="_blank" routerLink="/profile">{{getAppUrl()}}/profile</a>. Your transaction limits may vary depending on your payment
                      method, verification steps you have completed, and other factors. ChainEX reserves the right to change applicable limits as it
                      deems necessary, in its sole discretion.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">6.5</span>
                    <span class="col-11" i18n>
                      If you wish to raise your limits beyond the posted amounts, you may submit a request at <a target="_blank" href="{{getSupportUrl()}}">
                      {{getSupportUrl()}}</a>.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">6.6</span>
                    <span class="col-11" i18n>
                      The use of all ChainEX services is subject to a limit on the amount of volume, stated in Fiat Currency terms, you may transact or
                      transfer in a given period (e.g., daily). To view your limits, login to your ChainEX account and visit
                      <a target="_blank" href="/profile">{{getAppUrl()}}/profile</a>. Your transaction limits may vary depending on your payment method,
                      verification steps you have completed, and other factors. ChainEX reserves the right to change applicable limits as it deems
                      necessary, in its sole discretion.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">6.7</span>
                    <span class="col-11" i18n>
                      The following shall apply when an incorrect deposit reference is used and to any unallocated deposits:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">6.7.1</span>
                      <span class="col-11" i18n>
                        Any deposit received with an incorrect reference number will be considered an unallocated deposit.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">6.7.2</span>
                      <span class="col-11" i18n>
                        Please ensure that you use your unique reference when depositing funds on the ChainEX platform. If the incorrect reference is used,
                        you will be charged an additional administrative fee to allocate your deposit. The fee charged is dependent on the deposit amount.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">6.7.3</span>
                      <span class="col-11" i18n>
                        Deposits made with an incorrect reference and not claimed within one month will be subject to a monthly service and administration fee.
                        These monthly servicing and administration fees will be deducted monthly from the deposit amount at the end of each month until said
                        deposit is claimed. Any amounts greater than these servicing and administration fees will be paid to the property's owner, once claimed.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">6.7.4</span>
                      <span class="col-11" i18n>
                        ChainEX will only allocate deposits received from a bank account registered to your name.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">6.7.5</span>
                      <span class="col-11" i18n>
                        Please note that banking charges incurred on ZAR cash deposits will be deducted from the amount allocated to your ZAR wallet. ChainEX does not allow for cash deposits.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">6.7.6</span>
                      <span class="col-11" i18n>
                        ChainEX will not be liable for any loss or damages of whatsoever nature, incurred in the event of an incorrect deposit reference being used or as a result of any unclaimed
                        or unallocated deposits.
                      </span>
                    </li>
                  </ul><br>
                </li>
                <li>
                  <h4 class="table-heading" i18n>TERMINATION</h4>

                  <p class="row mt-0">
                    <span class="number col-1">7.1</span>
                    <span class="col-11" i18n>
                      ChainEX may suspend, restrict, or terminate your access to any or all of the ChainEX services, and/or deactivate or cancel your
                      ChainEX account if:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">7.1.1</span>
                      <span class="col-11" i18n>
                        ChainEX is so required by a facially valid subpoena, court order, or binding order of a government authority;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">7.1.2</span>
                      <span class="col-11" i18n>
                        ChainEX reasonably suspects you of using your ChainEX account in connection with a <a href="/terms#prohibited">Prohibited Use or
                        Business</a>;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">7.1.3</span>
                      <span class="col-11" i18n>
                        Use of your ChainEX account is subject to any pending litigation, investigation, or government proceeding and/or ChainEX perceives
                        a heightened risk of legal or regulatory non-compliance associated with your account activity;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">7.1.4</span>
                      <span class="col-11" i18n>
                        ChainEX service partners are unable to support your use; or
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">7.1.5</span>
                      <span class="col-11" i18n>
                        You take any action that ChainEX deems as circumventing ChainEX's controls, including, but not limited to, opening multiple
                        ChainEX accounts or abusing promotions which ChainEX may offer from time to time.
                      </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">7.2</span>
                    <span class="col-11" i18n>
                      If ChainEX suspends or closes your account, or terminates your use of ChainEX services for any reason, ChainEX will notify
                      you of such actions, unless a court order or other legal process prohibits ChainEX from providing you with such notice. You
                      acknowledge that ChainEX's decision to take certain actions, including limiting access to, suspending, or closing your
                      account, may be based on confidential criteria that are essential to ChainEX's risk management and security protocols. You
                      agree that ChainEX is under no obligation to disclose the details of its risk management and security procedures to you.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">7.3</span>
                    <span class="col-11" i18n>
                      The ChainEX account can only be used by the account registrant. ChainEX reserves the right to suspend, freeze or cancel
                      the use of ChainEX accounts by persons other than the account registrant. If you suspect or become aware of any
                      unauthorised use of your username and password, you should notify ChainEX immediately. ChainEX assumes no liability for
                      any loss or damage arising from the use of the ChainEX account by you or any third-party with or without your authorization.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">7.4</span>
                    <span class="col-11" i18n>
                      You will be permitted to transfer Digital Currency or funds associated with your Hosted Digital Currency Wallet(s) and/or
                      your Currency Wallet(s) for thirty (30) days after account deactivation or cancellation, unless such transfer is otherwise
                      prohibited:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1">7.4.1</span>
                      <span class="col-11" i18n>
                        Under any law, including but not limited to applicable sanctions programs;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">7.4.2</span>
                      <span class="col-11" i18n>
                        Under the Prohibited Use or Prohibited Business list; or
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">7.4.3</span>
                      <span class="col-11" i18n>
                        By a facially valid subpoena or court order.
                      </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">7.5</span>
                    <span class="col-11" i18n>
                      You may cancel your ChainEX account at any time by withdrawing all balances and visiting <a target="_blank" routerLink="/settings">
                      {{getAppUrl()}}/settings</a>. You will not be charged for cancelling your ChainEX account, although you will be required to pay any
                      outstanding amounts owed to ChainEX. You authorise  ChainEX to cancel or suspend any pending transactions at the time of cancellation.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">7.6</span>
                    <span class="col-11" i18n>
                      ChainEX is an independent contractor for all purposes. Nothing in this Agreement shall be deemed, or is intended to be deemed, nor
                      shall it cause, you and ChainEX to be treated as partners, joint ventures, or otherwise as joint associates for profit, and neither
                      you nor ChainEX to be treated as the agent of the other.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">7.7</span>
                    <span class="col-11" i18n>
                      You are responsible for maintaining adequate security and control of any and all IDs, passwords, hints, personal identification
                      numbers (PINs), API keys or any other codes that you use to access the ChainEX services. Any loss or compromise of the foregoing
                      information and/or your personal information may result in unauthorised access to your ChainEX account by third-parties and the
                      loss or theft of any Digital Currency and/or funds held in your ChainEX account and any associated accounts, including your linked
                      bank account(s) and credit card(s). You are responsible for keeping your email address and telephone number up to date in your account
                      profile in order to receive any notices or alerts that we may send you. ChainEX assumes no responsibility for any loss that you may
                      sustain due to compromise of account login credentials, which are not due to fault of ChainEX, and/or failure to follow or act on any
                      notices or alerts that ChainEX may send to you. In the event that you suspect that your ChainEX account information has been compromised,
                      contact ChainEX support immediately at <a class="email" href="mailto:{{getSupportEmail()}}" target="_blank">{{getSupportEmail()}}</a>.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">7.8</span>
                    <span class="col-11" i18n>
                      In the event that you have not logged into your account on the site for an uninterrupted period of two years, ChainEX reserves the
                      right to deem any and all property that you hold on the site, including Digital Currencies, to be abandoned, with or without notice
                      to you. If your property is abandoned, it will be dealt with in one of two ways:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">7.8.1</span>
                      <span class="col-11" i18n>
                        If you are an unverified customer, it will be immediately forfeited to and seized by ChainEX; or,
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">7.8.2</span>
                      <span class="col-11" i18n>
                        If you are a verified customer, ChainEX will engage in a process to determine the abandoned property's owner (including
                        successors-in-interest and next-of-kin). This process will be subject to servicing and administration fees to be levied
                        on and collected out of the property by ChainEX. Any amounts greater than these servicing and administration fees will be
                        paid to the property's owner, when and if found. If the owner can still not be found, it will be forfeited to and seized
                        by ChainEX.
                      </span>
                    </li>
                  </ul>
                  <br>
                </li>
                <li>
                  <h4 class="table-heading" i18n>USER CONDUCT</h4>

                  <p class="row mt-0">
                    <span class="number col-1">8.1</span>
                    <span class="col-11" i18n>
                      In addition to the list of <a href="/terms#prohibited">Prohibited Use or Business</a> here below,
                      you will not:
                    </span>
                    </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.1</span>
                      <span class="col-11" i18n>
                        Violate, or assist any party in violating, any law, statute, ordinance, regulation or any rule of any self-regulatory or
                        similar organisation of which you are or are required to be a member through your use of the Services;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.2</span>
                      <span class="col-11" i18n>
                        Violate public interests of others, including any actions that would interfere with, disrupt, negatively affect, or prohibit
                        other Users from using ChainEX services;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.3</span>
                      <span class="col-11" i18n>
                        Provide false, inaccurate, incomplete or misleading information;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.4</span>
                      <span class="col-11" i18n>
                        Infringe upon ChainEX's or any third party's copyright, patent, trademark, or intellectual property rights;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.5</span>
                      <span class="col-11" i18n>
                        Distribute unsolicited or unauthorised advertising or promotional material, any junk mail, spam, or chain letters;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.6</span>
                      <span class="col-11" i18n>
                        Use a web crawler or similar technique to access ChainEX's services or to extract data;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.7</span>
                      <span class="col-11" i18n>
                        Reverse engineer or disassemble any aspect of the site or services in an effort to access any source code, underlying
                        ideas and concepts, or algorithms;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.8</span>
                      <span class="col-11" i18n>
                        Take any action that imposes an unreasonable or disproportionately large load on ChainEX's infrastructure, or detrimentally
                        interfere with, intercept, or expropriate any system, data, or information;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.9</span>
                      <span class="col-11" i18n>
                        Transmit or upload any material to the Site that contains viruses, Trojan horses, worms, or any other harmful or deleterious
                        programs;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.10</span>
                      <span class="col-11" i18n>
                        Otherwise attempt to gain unauthorised access to the site, other ChainEX accounts, computer systems or networks connected to
                        the Site, through password mining or any other means; or
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">8.1.11</span>
                      <span class="col-11" i18n>
                        Transfer any rights granted to you under these terms.
                      </span>
                    </li>
                  </ul>
                  <br>
                </li>
                <li>
                  <h4 class="table-heading" i18n>GENERAL PROVISIONS AND AGREEMENT</h4>
                  <p class="row mt-0">
                    <span class="number col-1">9.1 </span>
                    <span class="col-11 " i18n>
                      <strong>User Disputes:</strong> If you have a dispute with one or more users of the ChainEX services, you release ChainEX, its
                      affiliates and service providers, and each of their respective officers, directors, agents, joint venturers, employees, and
                      representatives from any and all claims, demands and damages (actual and consequential) of every kind and nature arising out
                      of or in any way connected with such disputes. You agree to indemnify and hold ChainEX, its affiliates and service providers,
                      and each of its or their respective officers, directors, agents, joint venturers, employees, and representatives, harmless
                      from any claim or demand (including attorneys' fees and any fines, fees or penalties imposed by any regulatory authority)
                      arising out of or related to your breach of this Agreement or your violation of any law, rule or regulation, or the rights
                      of any third party.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">9.2 </span>
                    <span class="col-11" i18n>
                      <strong>Historical Data:</strong> ChainEX makes no representations about the accuracy or completeness of historical Digital
                      Currency price data available on the site. ChainEX will make reasonable efforts to ensure that requests for electronic debits
                      and credits involving bank accounts, credit cards, and check issuances are processed in a timely manner but ChainEX makes no
                      representations or warranties regarding the amount of time needed to complete processing which is dependent upon many factors
                      outside of our control.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">9.3 </span>
                    <span class="col-11" i18n>
                      <strong>Entirety:</strong> This Agreement, the Privacy Policy and any other annexures or Agreements incorporated by reference
                      herein comprise the entire understanding and Agreement between you and ChainEX as to the subject matter hereof, and supersedes
                      any and all prior discussions, Agreements, and understandings of any kind (including without limitation any prior versions of
                      this Agreement), and every nature between and among you and ChainEX. Section headings in this Agreement are for convenience
                      only and shall not govern the meaning or interpretation of any provision of this Agreement.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">9.4 </span>
                    <span class="col-11" i18n>
                      <strong>Amendments:</strong> We may amend or modify this Agreement by posting on the ChainEX Site or emailing to you the revised
                      Agreement, and the revised Agreement shall be effective at such time that ChainEX communicates the amendment to you.  If you do
                      not agree with any such modification, your sole and exclusive remedy is to terminate your use of the services and close your
                      account. You agree that we shall not be liable to you or any third party for any modification or termination of the ChainEX
                      services, or suspension or termination of your access to the ChainEX services, except to the extent otherwise expressly set
                      forth herein. If the revised Agreement includes a material change, we will endeavour to provide you advanced notice via our
                      website and/or email before the material change becomes effective.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">9.5 </span>
                    <span class="col-11" i18n>
                      <strong>Entire Agreement:</strong> You may not assign any rights and/or licenses granted under this Agreement. We reserve the
                      right to assign our rights without restriction, including without limitation to any ChainEX affiliates or subsidiaries, or to
                      any successor in interest of any business associated with the ChainEX Services. Any attempted transfer or assignment in violation
                      hereof shall be null and void. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their
                      successors, and permitted assigns.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">9.6 </span>
                    <span class="col-11" i18n><strong>Severability:</strong> You may not assign any rights and/or licenses granted under this Agreement.
                      We reserve the right to assign our rights without restriction, including without limitation to any ChainEX affiliates or subsidiaries,
                      or to any successor in interest of any business associated with the ChainEX Services. Any attempted transfer or assignment in
                      violation hereof shall be null and void. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties,
                      their successors, and permitted assigns.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">9.7 </span>
                    <span class="col-11" i18n>
                      <strong>Change of Control:</strong> In the event that ChainEX is acquired by or merged with a third-party entity, we reserve the
                      right, in any of these circumstances, to transfer or assign the information we have collected from you as part of such merger,
                      acquisition, sale, or other change of control.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">9.8 </span>
                    <span class="col-11" i18n>
                      <strong>Survival:</strong> All provisions of this Agreement which by their nature extend beyond the expiration or termination of this
                      Agreement, including, without limitation, sections pertaining to suspension or termination, ChainEX Account cancellation, debts owed
                      to ChainEX, general use of the ChainEX Site, disputes with ChainEX, and general provisions, shall survive the termination or expiration
                      of this Agreement.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">9.9 </span>
                    <span class="col-11" i18n>
                      <strong>Force Majeure:</strong> We shall not be liable for delays, failure in performance or interruption of service which result
                      directly or indirectly from any cause or condition beyond our reasonable control, including but not limited to, any delay or failure
                      due to any act of God, act of civil or military authorities, act of terrorists, civil disturbance, war, strike or other labour dispute,
                      fire, interruption in telecommunications or Internet services or network provider services, failure of equipment and/or software,
                      pandemic, epidemic, lockdown, other catastrophe or any other occurrence which is beyond our reasonable control and shall not affect
                      the validity and enforceability of any remaining provisions.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">9.10 </span>
                    <span class="col-11" i18n>
                      <strong>English Control:</strong> Notwithstanding any other provision of this Agreement, any translation of this Agreement is provided
                      for your convenience. The meanings of terms, conditions and representations herein are subject to definitions and interpretations in the
                      English language. Any translation provided may not accurately represent the information in the original English.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>ELECTRONIC NOTICES</h4>
                  <p class="row mt-0">
                    <span class="number col-1">10.1 </span>
                    <span class="col-11 " i18n>
                      You agree and consent to receive electronically all communications, agreements, documents, receipts, notices, tax forms and disclosures
                      (collectively, "Communications") that ChainEX provides in connection with your account and/or use of the ChainEX Services. You agree that
                      ChainEX may provide these communications to you by posting them via the services, by emailing them to you at the email address you provide,
                      and/or by sending an SMS or text message to a mobile phone number that you provide. Your carrier's normal, messaging, data and other rates
                      and fees may apply to any mobile communications. You should maintain copies of electronic communications by printing a paper copy or saving
                      an electronic copy. You may also contact us through <a target="_blank" href="{{getSupportUrl()}}">{{ getSupportUrl() }}</a> to request
                      additional electronic copies of communications.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">10.2 </span>
                    <span class="col-11" i18n>
                      You may withdraw your consent to receive electronic communications by sending a withdrawal notice to <a target="_blank" href="{{getSupportUrl()}}">
                      {{getSupportUrl()}}</a>. If you decline or withdraw consent to receive electronic communications, ChainEX may suspend or terminate your use of the
                      services. Unsubscribe requests may take up to 10 business days to process from the date the request is received.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">10.3 </span>
                    <span class="col-11" i18n>
                      It is your responsibility to keep your email address and mobile phone number on file with ChainEX up to date so that ChainEX can communicate
                      with you electronically. You understand and agree that if ChainEX sends you an electronic communication but you do not receive it because your
                      email address or mobile phone number on file is incorrect or out of date, our ability to contact you at the email address and/or mobile phone
                      number is blocked by your service provider, or you are otherwise unable to receive electronic communications, ChainEX will be deemed to have
                      provided the communication to you whether or not you actually received the communication. Please note that if you use a spam filter that blocks
                      or re-routes emails from senders not listed in your email address book, you must add ChainEX to your email address book so that you will be able
                      to receive the communications we send to you. You can update your email address or mobile phone number at any time by logging into your ChainEX
                      account or by sending such information to <a target="_blank" href="{{getSupportUrl()}}">{{getSupportUrl()}}</a> If your email address or mobile
                      phone number becomes invalid such that electronic communications sent to you by ChainEX are returned, ChainEX may deem your account to be inactive,
                      and you may not be able to complete any transaction via our services until we receive a valid, working email address or mobile phone number from you.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>DISCLAIMER OF WARRANTIES</h4>

                  <p class="row mt-0">
                    <span class="number col-1">11.1 </span>
                    <span class="col-11" i18n>
                      <strong>CHAINEX PROVIDES NO GUARANTEE AS TO THE PERFORMANCE OR THE UNINTERRUPTED AVAILABILITY OF THE SERVICES. THE SERVICES ARE PROVIDED
                      ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. CHAINEX DISCLAIMS ALL WARRANTIES,
                      EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                      NON-INFRINGEMENT WITH RESPECT TO THE SERVICES. CHAINEX DOES NOT REPRESENT OR WARRANT THAT THE SERVICES AND THE INFORMATION CONTAINED
                      THEREIN ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. CHAINEX WILL MAKE REASONABLE EFFORTS TO ENSURE THAT TRANSACTIONS ON THE
                      PLATFORM ARE PROCESSED IN A TIMELY FASHION, BUT MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE AMOUNT OF TIME NEEDED TO
                      PROCESS SUCH TRANSACTIONS. BECAUSE TOKEN TRANSFERS ON AND OFF THE PLATFORM ARE DEPENDENT UPON MANY FACTORS OUTSIDE OF OUR CONTROL
                      INCLUDING DENIAL OF SERVICE ATTACKS AND THE LIQUIDITY OF THE TOKENS TRADED ON OUR PLATFORM, AMONG OTHER FACTORS, CHAINEX MAKES NO
                      REPRESENTATIONS OR WARRANTIES REGARDING THE SUCCESS OF, OR THE AMOUNT OF TIME NEEDED FOR, TOKEN TRANSACTIONS. YOU ALSO ACKNOWLEDGE
                      THAT ANY INFORMATION THAT YOU STORE OR TRANSFER USING THE SERVICES MAY BECOME IRRETRIEVABLY LOST OR CORRUPTED OR TEMPORARILY UNAVAILABLE
                      DUE TO A VARIETY OF CAUSES, INCLUDING SOFTWARE FAILURES, THIRD PARTY PROTOCOL CHANGES, INTERNET OUTAGES, THIRD PARTY DENIAL OF SERVICE
                      ATTACKS, ACTS OF GOD OR UNSCHEDULED MAINTENANCE. YOU ARE ENCOURAGED TO BACK UP AND SAFEGUARD YOUR INFORMATION, INCLUDING LOGIN
                      CREDENTIALS, AT ALL TIMES.</strong>
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">11.2 </span>
                    <span class="col-11" i18n>
                      <strong>ADDITIONALLY, YOU ACKNOWLEDGE THAT CHAINEX DOES NOT ACT OR SERVE AS YOUR BROKER, INTERMEDIARY, AGENT, OR ADVISOR WITH RESPECT
                      TO ANY TRANSACTION YOU MAKE OR PROPOSE TO MAKE USING THE SERVICES AND OWES YOU NO FIDUCIARY DUTY.</strong>
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>LIMITATION OF LIABILITY</h4>

                  <p class="row mt-0">
                    <span class="col-11" i18n>
                      <strong>TO THE MAXIMUM EXTENT PERMITTED BY LAW, CHAINEX SHALL HAVE NO LIABILITY FOR ANY DAMAGES OF ANY KIND (INCLUDING WITHOUT LIMITATION
                      INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR TORT DAMAGES, OR LOST PROFITS) IN CONNECTION WITH YOUR USE OF THE SERVICES, EVEN IF
                      CHAINEX HAS BEEN ADVISED OR IS AWARE OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL CHAINEX'S LIABILITY FOR MONEY DAMAGES UNDER
                      THESE TERMS EXCEED THE AMOUNT OF FEES RECEIVED FROM YOU DURING THE PRECEDING THREE (3) MONTH PERIOD.</strong>
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>ARBITRATION</h4>

                  <p class="row mt-0">
                    <span class="number col-1">13.1 </span>
                    <span class="col-11" i18n>
                      Any dispute, question or difference arising at any time between the parties with regard to this Agreement between you and ChainEX must be
                      submitted to and decided by arbitration on notice given by either party to the other.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">13.2 </span>
                    <span class="col-11" i18n>
                      The arbitration must be held under the auspices of and subject to the rules and procedures of the International Arbitration Act 15 0f 2017,
                      except that:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">13.2.1 </span>
                      <span class="col-11" i18n>
                        The arbitration must be informal and must be conducted in the most expeditious and inexpensive manner possible; and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">13.2.2 </span>
                      <span class="col-11" i18n>
                        The arbitrator will have the fullest and freest discretion to determine the procedure to be adopted, subject only to clause 13.2.1 and
                        to the rules of natural justice.
                      </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">13.3 </span>
                    <span class="col-11" i18n>
                      It is agreed intention that the arbitration must be held and concluded within twenty-one (21) business days after it has been demanded.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">13.4 </span>
                    <span class="col-11" i18n>The arbitrator must be:</span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">13.4.1 </span>
                      <span class="col-11" i18n>
                        An independent chartered accountant of not less than ten (10) years' standing agreed upon between the parties, if the question in
                        dispute is primarily an accounting matter; and/or
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">13.4.2 </span>
                      <span class="col-11" i18n>
                        A practicing advocate or attorney of not less than ten (10) years' standing agreed upon between the parties, if the question in dispute
                        is primarily a legal matter; and/or
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">13.4.3 </span>
                      <span class="col-11" i18n>
                        An independent and suitably qualified person agreed upon between the parties, in the case of any other matter.
                      </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">13.5 </span>
                    <span class="col-11" i18n>
                      If the parties are unable to reach agreement with regards to whom the arbitrator should be within three (3) business days after the
                      arbitration has been demanded, then an arbitrator shall be appointed according the provisions of the International Arbitration Act 15
                      0f 2017 within seven (7) business days after the parties have failed to reach agreement, with the intention that the arbitration be
                      held and concluded within the twenty-one (21) day period referred to.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">13.6 </span>
                    <span class="col-11" i18n>
                      Each party hereby irrevocably consent to the arbitration proceedings, and neither party will be entitled to withdraw from the provisions
                      of this clause or to claim at such arbitration proceedings that such party is not bound by this clause.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">13.7 </span>
                    <span class="col-11" i18n>
                      Each of the parties irrevocably agrees that the decision of the arbitrator (including any costs award):
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">13.7.1 </span>
                      <span class="col-11" i18n>Will be final and binding on each of them;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">13.7.2 </span>
                      <span class="col-11" i18n>Will be carried into effect; and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">13.7.3 </span>
                      <span class="col-11" i18n>May be made an order of court on application by either party.
                    </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">13.8 </span>
                    <span class="col-11" i18n>
                      Nothing contained or implied in this agreement will preclude either party from applying to court for a temporary interdict or any other relief
                      of an urgent and temporary nature, pending the decision or award of the arbitrator.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">13.9 </span>
                    <span class="col-11" i18n>
                      The provisions of this clause are separate and severable from the rest of this agreement and will remain in effect despite the termination,
                      cancellation or invalidity for any reason of this agreement.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">13.10 </span>
                    <span class="col-11" i18n>
                      <strong>ADDITIONALLY, YOU HEREBY WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION</strong>. The parties
                      agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative
                      action. If a court or regulator with oversight over ChainEX decides that applicable law precludes enforcement of any of this section's
                      limitations as to a particular claim for relief, then that claim (and only that claim) must be severed from the arbitration and may be
                      brought in court - subject to the parties' right to appeal the court's decision.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>COMPLIANCE WITH LOCAL LAWS</h4>
                  <p class="row mt-0">
                    <span class="number col-1">14.1 </span>
                    <span class="col-11 " i18n>
                      It is the users' responsibility to abide by local laws in relation to the legal usage of ChainEX services in their local jurisdiction as well
                      as other laws and regulations applicable to users. Users must also factor, to the extent of their local laws all aspects of taxation, the withholding,
                      collection, reporting and remittance to their appropriate tax authorities.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">14.2 </span>
                    <span class="col-11" i18n>
                      All users of ChainEX services acknowledge and declare that their funds come from legitimate sources and do not originate from illegal activities. Users
                      agree that ChainEX may require them to provide or otherwise collect the necessary information and materials as per relevant laws or government orders to
                      verify the legality of the sources and use of their funds.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">14.3 </span>
                    <span class="col-11" i18n>
                      ChainEX maintains a stance of cooperation with law enforcement authorities globally and will not hesitate to seize, freeze, terminate users' accounts and
                      funds which are flagged out or investigated by legal mandate.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" id="prohibited" i18n>PROHIBITED USE, PROHIBITED BUSINESSES AND
                    CONDITIONAL USE</h4>

                  <p class="row mt-0">
                    <span class="number col-1">15.1 </span>
                    <span class="col-11" i18n>
                      <strong>PROHIBITED USE:</strong> You may not use your ChainEX Account to engage in the following categories of activity ("Prohibited Uses").
                      The specific types of use listed below are representative, but not exhaustive. If you are uncertain as to whether or not your use of ChainEX
                      Services involves a Prohibited Use, or have questions about how these requirements apply to you, please submit a request at
                      <a target="_blank" href="{{getSupportUrl()}}">{{getSupportUrl()}}</a>. By opening a ChainEX Account, you confirm that you will not use your
                      Account to do any of the following:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.1.1 </span>
                      <span class="col-11" i18n>
                        <strong>Unlawful Activity:</strong> Activity which would violate, or assist in violation of, any law, statute, ordinance, or regulation,
                        sanctions programs administered in the countries where ChainEX conducts business or which would involve proceeds of any unlawful activity;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.1.2 </span>
                      <span class="col-11" i18n>
                        <strong>Abusive Activity: </strong> Actions which impose an unreasonable or disproportionately large load on our infrastructure, or detrimentally
                        interfere with, intercept, or expropriate any system, data, or information; transmit or upload any material to the ChainEX Site that contains viruses,
                        trojan horses, worms, or any other harmful or deleterious programs; attempt to gain unauthorised access to the ChainEX Site, other ChainEX Accounts,
                        computer systems or networks connected to the ChainEX Site, through password mining or any other means; use ChainEX Account information of another
                        party to access or use the ChainEX Site, except in the case of specific Merchants and/or applications which are specifically authorised by a user to
                        access such user's ChainEX Account and information; or transfer your account access or rights to your account to a third party, unless by operation
                        of law or with the express permission of ChainEX. You agree not to use the ChainEX services for market manipulation (such as pump and dump schemes,
                        wash trading, self-trading, front running, quote stuffing, and spoofing and layering, regardless of whether prohibited by law).
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.1.3 </span>
                      <span class="col-11" i18n>
                        <strong>Abuse Other Users:</strong> Interfere with another individual's or entity's access to or use of any ChainEX Services; defame, abuse, extort,
                        harass, stalk, threaten or otherwise violate or infringe the legal rights (such as, but not limited to, rights of privacy, publicity and intellectual
                        property) of others; incite, threaten, facilitate, promote, or encourage hate, racial intolerance, or violent acts against others; harvest or otherwise
                        collect information from the ChainEX Site about others, including without limitation email addresses, without proper consent.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.1.4 </span>
                      <span class="col-11" i18n>
                        <strong>Fraud:</strong> Activity which operates to defraud ChainEX, ChainEX users, or any other person; provide any false, inaccurate, or misleading
                        information to ChainEX.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.1.5 </span>
                      <span class="col-11" i18n>
                        <strong>Gambling:</strong> Lotteries; bidding fee auctions; sports forecasting or odds making; fantasy sports leagues with cash prizes; internet gaming;
                        contests; sweepstakes; games of chance.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.1.6 </span>
                      <span class="col-11" i18n>
                        <strong>Intellectual Property Infringement:</strong> Engage in transactions involving items that infringe or violate any copyright, trademark, right
                        of publicity or privacy or any other proprietary right under the law, including but not limited to sales, distribution, or access to counterfeit music,
                        movies, software, or other licensed materials without the appropriate authorization from the rights holder; use of ChainEX intellectual property, name,
                        or logo, including use of ChainEX trade or service marks, without express consent from ChainEX or in a manner that otherwise harms ChainEX or the ChainEX
                        brand; any action that implies an untrue endorsement by or affiliation with ChainEX.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.1.7 </span>
                      <span class="col-11" i18n>
                        <strong>Payment on Behalf of Third Parties and Aggregator Prohibition:</strong>  By using the ChainEX platform you hereby acknowledge and agree that you shall
                        not make payments or engage in transactions on behalf of third parties using their account. All transactions and payments conducted on the ChainEX platform must be
                        for the sole benefit of the account holder and must not involve the transfer of funds or assets on behalf of any other individual, entity, or organization.
                        Additionally, users are strictly prohibited from acting as aggregators, intermediaries, or payment processors, and shall not use the exchange for any form of aggregation
                        or consolidation of funds or assets from multiple parties. Any violation of this provision may result in the suspension or termination of the user's account, as well as
                        legal actions as permitted by applicable laws and regulations.
                      </span>
                    </li>
                  </ul>
                  <p class="row mt-0">
                    <span class="number col-1">15.2 </span>
                    <span class="col-11" i18n>
                      You may not without written consent from ChainEX, the following commercial uses of ChainEX data are prohibited:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.2.1</span>
                      <span class="col-11" i18n>
                        Trading service that make or use ChainEX quotes or market bulletin board information.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.2.2</span>
                      <span class="col-11" i18n>
                        Data feeding or streaming services that make use of any market data of ChainEX.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.2.3</span>
                      <span class="col-11" i18n>
                        Any other websites/apps/services that charge for or otherwise profit from (including through advertising or referral fees) market
                        data obtained from ChainEX.
                      </span>
                    </li>
                  </ul>
                  <p class="row mt-0">
                    <span class="number col-1">15.3 </span>
                    <span class="col-11" i18n>
                      By accessing ChainEX services, you agree that ChainEX has the right to investigate any violation of these terms, unilaterally
                      determine whether you have violated these terms, and take actions under relevant regulations without your consent or prior
                      notice. Examples of such actions include, but are not limited to:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.3.1</span>
                      <span class="col-11" i18n>
                        Blocking and closing order requests;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.3.2</span>
                      <span class="col-11" i18n>
                        Freezing your account;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.3.3</span>
                      <span class="col-11" i18n>
                        Reporting the incident to the authorities;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.3.4</span>
                      <span class="col-11" i18n>
                        Publishing the alleged violations and actions that have been taken;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.3.5</span>
                      <span class="col-11" i18n>
                        Deleting any information you published that are found to be violations.
                      </span>
                    </li>
                  </ul>
                  <p class="row mt-0">
                    <span class="number col-1">15.4</span>
                    <span class="col-11" i18n>
                      <strong>PROHIBITED BUSINESSES:</strong> In addition to the Prohibited Uses described above, the following categories of businesses, business practices,
                      and sale items are barred from ChainEX Services ("Prohibited Businesses").
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">15.5</span>
                    <span class="col-11" i18n>
                      The specific types of use listed below are representative, but not exhaustive. If you are uncertain as to whether or not your use of ChainEX Services
                      involves a Prohibited Business, or have questions about how these requirements apply to you, please contact us at  <a target="_blank" href="{{getSupportUrl()}}">
                      {{getSupportUrl()}}</a>.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">15.6</span>
                    <span class="col-11" i18n>
                      By opening a ChainEX Account, you confirm that you will not use ChainEX Services in connection with any of following businesses, activities, practices, or items:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.6.1</span>
                      <span class="col-11" i18n>
                        <strong>Investment:</strong> Investment opportunities or other services that promise high rewards.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.6.2</span>
                      <span class="col-11" i18n>
                        <strong>Intellectual Property or Proprietary Rights Infringement:</strong> Sales, distribution, or access to counterfeit music, movies, software, or other
                        licensed materials without the appropriate authorization from the rights holder.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.6.3</span>
                      <span class="col-11" i18n>
                        <strong>Counterfeit or Unauthorised Goods:</strong> Unauthorised sale or resale of brand name or designer products or services; sale of goods or services
                        that are illegally imported or exported or which are stolen.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.6.4</span>
                      <span class="col-11" i18n>
                        <strong>Regulated Products and Services:</strong> Marijuana dispensaries and related businesses; sale of tobacco, e-cigarettes, and e-liquid; online
                        prescription or pharmaceutical services; age restricted goods or services; weapons and munitions; gunpowder and other explosives; fireworks and related
                        goods; toxic, flammable, and radioactive materials.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.6.5</span>
                      <span class="col-11" i18n>
                        <strong>Drugs and Drug Paraphernalia:</strong> Sale of narcotics, controlled substances, and any equipment designed for making or using drugs, such as
                        bongs, vaporizers, and hookahs.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.6.6</span>
                      <span class="col-11" i18n>
                        <strong>Pseudo-Pharmaceuticals:</strong> Pharmaceuticals and other products that make health claims that have not been approved or verified by the
                        applicable local and/or national regulatory body.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.6.7</span>
                      <span class="col-11" i18n>
                        <strong>Substances designed to mimic illegal drugs:</strong> Sale of a legal substance that provides the same effect as an illegal drug
                        (e.g., salvia, kratom)
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.6.8</span>
                      <span class="col-11" i18n>
                        <strong>Adult Content and Services:</strong> Pornography and other obscene materials (including literature, imagery and other media); sites offering
                        any sexually-related services such as prostitution, escorts, pay-per view, adult live chat features.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 points">15.6.9</span>
                      <span class="col-11" i18n>
                        <strong>High risk businesses:</strong> Any businesses that we believe poses elevated financial risk, legal liability, or violates card network or
                        bank policies.
                      </span>
                    </li>
                  </ul>
                  <br>
                </li>
                <li>
                  <h4 class="table-heading" id="geographical_exclusions" i18n><a href="/terms#geographical_exclusions">GEOGRAPHICAL
                  EXCLUSIONS</a></h4>

                  <p class="row mt-0">
                    The following geographical areas are barred from using ChainEX Services ("Prohibited Areas"). The specific areas listed below are representative, but
                    not exhaustive. If you are uncertain as to whether or not you are domiciled in a Prohibited Area or have questions about how these requirements apply
                    to you, please contact us at <a target="_blank" href="{{getSupportUrl()}}">{{getSupportUrl()}}</a>. By opening a ChainEX Account, you confirm that you
                    are not domiciled in:
                  </p>
                  <ul class="list-unstyled">
                    <li>USA, country code 1, ISO Code: US</li>
                    <li>Algeria, country code 213 , ISO Code: DZ</li>
                    <li>Bolivia, country code 591 , ISO Code: BO</li>
                    <li>Ecuador, country code 593 , ISO Code: EC</li>
                    <li>Krygyzstan, country code 996 , ISO Code: KG</li>
                    <li>Bangladesh, country code 880 , ISO Code: BD</li>
                    <li>Nepal, country code 977 , ISO Code: NP</li>
                    <li>Cambodia, country code 855, ISO Code: KH</li>
                    <li>Macedonia, country code 389, ISO Code: MK</li>
                    <li>Cuba, country code: 53, ISO Code: CU</li>
                    <li>North Korea, country code: 850, ISO Code: KP</li>
                    <li>Syria, country code: 963, ISO Code: SY</li>
                    <li>Iran, country code: 98 , ISO Code: IR</li>
                    <li>Myanmar, country code:  95, ISO Code: MM</li>
                  </ul>
                </li>
              </ol><br/><br/>

              <!-- ANNEXURE A -->
              <h4 class="table-heading" id="stablecoin" i18n>ANNEXURE A</h4>
              <ol class="list-unstyled mx-3">
                <li>
                  <h3 class="table-heading" i18n>ADDENDUM: ChainEX Stablecoin Terms and Conditions</h3>
                  <p i18n>
                    The ChainEX Stablecoin Terms and Conditions (hereinafter "ChainEX Stablecoin Agreement" or "ChainEX Stablecoin Terms and Conditions") constitute an addendum to the ChainEX
                    Terms of Use and Conditions for the provisions of and use of the ZAR denominated stablecoin managed by ChainEX known as ChainEX ZAR tokens ("CZAR"). These terms apply to
                    each electronic form or contract executed by users and/or members who use CZAR Stablecoins - unless expressly stipulated otherwise. To the extent where there is a conflict
                    between the ChainEX Stablecoin Terms and Conditions and the general Terms of Use and Conditions, the terms of this ChainEX Stablecoin Terms and Conditions shall take precedence.
                  </p>
                </li>
              </ol>
              <ol>
                <li>
                  <h4 class="table-heading" i18n>DISCLAIMER AND RISK FACTORS</h4>

                  <p class="row mt-0">
                    <span class="number col-1">1.1</span>
                    <span class="col-11" i18n>
                      CZAR is a BSC (Binance Smart Chain) token based on the Binance Smart Chain network and may be compatible with software or other technology provided by a third-party which
                      supports BSC tokens. ChainEX does not guarantee the security or functionality of any third-party software or technology and is not responsible for any losses of CZAR Stablecoins
                      due to the failure of third-party software or technology.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.2</span>
                    <span class="col-11" i18n>
                      The BSC network may experience backlogs, higher than normal transaction fees, changes to the network, failure or a fork in the protocol. We do not own or control the BSC network,
                      are not responsible for the operation of the BSC network and make no guarantees regarding the network's security, functionality and/or availability.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">1.3</span>
                    <span class="col-11" i18n>
                      Please also review the general <a href="/terms#terms">Terms of Use and Conditions</a> for additional risk factors.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>CZAR STABLECOINS</h4>

                  <p class="row mt-0">
                    <span class="number col-1">2.1</span>
                    <span class="col-11" i18n>
                      CZAR is not money, nor are CZAR considered legal tender or monetary instruments within South Africa.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">2.2</span>
                    <span class="col-11" i18n>
                      Once you have acquired CZAR, you will be able to perform an array of different actions, including but not limited to, transfer them, trade them, keep them, use them to pay persons
                      that will accept CZAR, or redeem them for ZAR from the ChainEX Stablecoin custody account - subject to the limitations below.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">2.3</span>
                    <span class="col-11" i18n>
                      The ZAR used to acquire CZAR is deposited with a South African Bank. CZAR is pegged to the ZAR and maintains a one-to-one ratio. ChainEX, on behalf of the user, receives and holds
                      the ZAR in the designated custody account at the South African Bank. For example, if a user acquires five CZAR with five ZAR, ChainEX will hold in custody for the benefit of the
                      user, the five ZAR in a South African bank account.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">2.4</span>
                    <span class="col-11" i18n>
                      In the absence of a reasonable justification not to redeem CZAR, and provided that the user is are a verified user, a user's CZAR is freely redeemable - subject to the ChainEX Terms
                      of Use and the conditions of ChainEX's verification levels.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">2.5</span>
                    <span class="col-11" i18n>
                      ChainEX may, at its sole discretion, engage market makers to provide liquidity for CZAR markets, both on the platform and on third-party platforms.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>DEPOSIT ACCOUNTS AND PURCHASING CZAR</h4>

                  <p class="row mt-0">
                    <span class="number col-1">3.1</span>
                    <span class="col-11" i18n>
                      Each depository account is (i) in our name and under our control, (ii) separate from our business and operating bank accounts, and (iii) established
                      specifically for the benefit of our CZAR users.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.2</span>
                    <span class="col-11" i18n>
                      After creating and funding your account with ZAR, you may begin to acquire CZAR with ZAR at a one-for-one ratio.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.3</span>
                    <span class="col-11" i18n>
                      For more detailed instructions on how to be issued CZAR, please refer to <a href="mailto:{{getSupportEmail()}}" target="_blank">{{getSupportEmail()}}</a>.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.4</span>
                    <span class="col-11" i18n>
                      Once an issuance of CZAR has been executed and the appropriate assets have been credited and debited, the transaction may not be reversible.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.5</span>
                    <span class="col-11" i18n>
                      ChainEX may require a minimum amount for conversion, which may be updated from time to time.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.6</span>
                    <span class="col-11" i18n>
                      Once a purchase request has passed Compliance checks, it will be processed according to the conversion schedule.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.7</span>
                    <span class="col-11" i18n>
                      ChainEX may require a minimum amount for a withdrawal to be processed, which may be updated from time to time.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.8</span>
                    <span class="col-11" i18n>
                      ChainEX may impose withdrawal limits on your account as per the verification levels.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading">CANCELLED ORDERS</h4>

                  <p class="row mt-0">
                    <span class="col-11" i18n>
                      ChainEX reserves the exclusive right to cancel or nullify any order in the event that such order contains an error, mistake, or, an actual or suspected
                      breach of the ChainEX Terms of Use and Conditions.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>CONVERSION FROM CZAR STABLECOINS TO ZAR</h4>

                  <p class="row mt-0">
                    <span class="number col-1">5.1</span>
                    <span class="col-11" i18n>
                      In order to redeem CZAR for ZAR through the platform, a user must have an account. CZAR can be bought and sold on the platform by placing an order on the
                      orderbook the same way other digital currencies are bought and sold. To see how to execute a trade using any of our trading options, visit
                      <a href="{{getSupportUrl()}}/kb/tutorials/how-to-execute-a-trade" target="_blank">{{getSupportUrl()}}/kb/tutorials/how-to-execute-a-trade</a>.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">5.2</span>
                    <span class="col-11" i18n>
                      CZAR will be rounded down to the nearest ZAR cent at conversion.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">5.3</span>
                    <span class="col-11" i18n>
                      ChainEX will use commercially reasonable efforts to redeem a user's CZAR as soon as possible. It may take up to one business day for your account balance to
                      reflect the redemption. Once a conversion request has passed compliance checks, it will be processed according to the conversion schedule.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading">FREEZES AND UPGRADES</h4>

                  <p class="row mt-0">
                    <span class="col-11" i18n>
                      As part of the BSC network, ChainEX has the ability and the right to freeze and upgrade all CZAR tokens (on an aggregate basis), regardless of where the CZAR
                      is being held. ChainEX will make commercially reasonable efforts to provide prior notice of any material freeze or upgrade and inform holders of CZAR of the
                      reasons and consequences of such freeze and/or upgrade. ChainEX will make commercially reasonable efforts to use the freeze and/or upgrade feature only under
                      limited circumstances.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>FEES</h4>

                  <p class="row mt-0">
                    <span class="number col-1">7.1</span>
                    <span class="col-11" i18n>
                      An overview of our fees on issuance and redemption is set forth at <a href="mailto:{{getSupportEmail ()}}" target="_blank">{{getSupportEmail()}}</a>.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">7.2</span>
                    <span class="col-11" i18n>
                      Your financial institution and/or the provider of your crypto asset wallet may also charge transaction and other fees related to the transfer of funds to
                      your account. To the extent that ChainEX is charged any banking fees by your financial institution or ChainEX's financial institution for the deposit or
                      withdrawal of ZAR to or from your account, ChainEX will deduct an amount to make provision for such fees.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading">CUSTODY INCOME</h4>

                  <p class="row mt-0">
                    <span class="col-11" i18n>
                      You agree and understand that ChainEx may hold the ZAR backing CZAR in a manner which generates interest and/or other earnings and, in such case, you agree
                      to pay us a fee equal to the amount of any such interest and/or earnings attributable or allocable to your CZAR as payment for the custody and other services
                      we provide to you under the ChainEX Terms of Use and Conditions. You agree and understand that we shall collect such payment, equal to the amount of such
                      allocable interest and/or other earnings, simultaneously upon being paid such interest and/or other earnings. You agree and understand that all such fees
                      will be retained by us and that you will not receive any portion of such interest and/or earnings.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading">RIGHT TO CHANGE FEES AND/OR FEE STRUCTURE</h4>

                  <p class="row mt-0">
                    <span class="col-11" i18n>
                      We reserve the right to change or modify our fee structure or increase any of our fees at any time and from time to time. Any such changes, modifications or
                      increases will be effective upon communicating such changes, modifications or increases. Your first use of your account following the communication of any changes
                      to the fees will constitute your acceptance of such changes. If you do not agree to the posted changes, you may close your account as provided for in this agreement.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading">ILLEGAL USE OF CZAR STABLECOINS</h4>

                  <p class="row mt-0">
                    <span class="col-11" i18n>
                      <strong>
                        WE MAY FREEZE, TEMPORARILY OR PERMANENTLY, YOUR USE OF, AND ACCESS TO, CZAR OR THE ZAR BACKING YOUR CZAR, WITH OR WITHOUT ADVANCE NOTICE, IF WE ARE REQUIRED TO DO
                        SO BY LAW, INCLUDING BY COURT ORDER OR OTHER LEGAL PROCESS.<br/><br/>
                        YOUR CZAR AND THE ZAR BACKING YOUR CZAR MAY BE SUBJECT TO SEIZURE OR FORFEITURE BY LAW ENFORCEMENT, AND WE WILL COMPLY WITH LEGAL PROCESS IN RESPECT THEREOF.<br/><br/>
                        IF WE DETERMINE AFTER INVESTIGATION THAT ANY OF YOUR CZAR HAVE BEEN USED, OR ARE BEING USED, FOR ILLEGAL (OR SANCTIONED) ACTIVITY, WE MAY NOT PERMIT YOU TO REDEEM YOUR
                        CZAR FOR ZAR FROM CHAINEX, AND, IF PRESENTED FOR REDEMPTION, SUCH CZAR AND THE ZAR BACKING SUCH CZAR MAY BE FORFEITED.<br/><br/>
                        ANY CZAR OR ZAR UNDERLYING CZAR THAT ARE SUBJECT TO FREEZE, SEIZURE, FORFEITURE OR SIMILAR LIMITATION ON THEIR USE IMPOSED BY LAW MAY BECOME WHOLLY AND PERMANENTLY
                        UNRECOVERABLE AND UNUSABLE, AND IN APPROPRIATE CIRCUMSTANCES, MAY BE DESTROYED.<br/><br/>
                        THIS SECTION APPLIES TO ALL HOLDERS, REGARDLESS OF WHETHER THE HOLDER IS A USER ON CHAINEX.<br/><br/>
                        BY USING CZAR, YOU AGREE THAT CHAINEX MAY TAKE THE ACTIONS SET FORTH IN THIS SECTION AND THAT IT WILL NOT BE LIABLE TO YOU THEREFORE.
                      </strong>
                    </span>
                  </p>
                </li>
              </ol><br/>

              <!-- ANNEXURE B -->
              <h4 class="table-heading" id="earn" i18n>ANNEXURE B </h4>
              <ol class="list-unstyled mx-3">
                <li>
                  <h3 class="table-heading" i18n>ADDENDUM: ChainEX Staking Terms and Conditions</h3>
                  <p i18n>
                    This Staking Terms and Conditions constitute an addendum to the ChainEX Terms of Use and does not apply to other services offered by ChainEX.
                  </p>
                  <p i18n>
                    By opting to stake tokens with ChainEX you are agreeing to become a party to this Agreement and as a consequence of such, consent to being bound by the obligations, duties,
                    warranties, representations and terms and conditions as contained herein.
                  </p>
                  <p i18n>
                    <strong>
                      SHOULD YOU NOT AGREE TO ALL OF THE TERMS AND CONDITIONS CONTAINED WITHIN THIS ANNEXURE B OR, IF ANY OF THE TERMS, CLAUSES, OBLIGATIONS, DUTIES, WARRANTIES OR REPRESENTATIONS
                      IN THIS STAKING TERMS AND CONIDITIONS ARE INACCURATE AS APPLIED TO YOU, YOU MUST NOT MAKE USE OF CHAINEX'S STAKING SERVICES.
                    </strong>
                  </p>
                  <p i18n>
                    To the extent where there is a conflict between the ChainEX Staking Terms of Use and Conditions and the general Terms of Use and Conditions, the terms of this ChainEX Staking
                    Terms and Conditions shall take precedence.
                  </p>
                  <p i18n>
                    <strong>
                      IT IS IMPORTANT TO NOTE THAT EARNINGS FROM CHAINEX'S STAKING PRODUCT/S MAY VARY AND THAT CHAINEX MAY, AT ITS OWN DISCRETION, UTILISE YOUR CRYPTO ON VARIOUS (BUT NOT LIMITED TO),
                      CENTRALISED PLATFORMS, DEFI ECOSYSTEMS, DEFI PLATFORMS, LIQUIDITY POOLS, LIQUIDITY MINING, ETC. IN ORDER TO GENERATE A RETURN FOR ITS USERS.
                    </strong>
                  </p>
                </li>
              </ol>
              <ol>
                <li>
                  <h4 class="table-heading" i18n>RISK WARNING </h4>

                  <p i18n>
                    The cryptocurrency you use to subscribe to the staking product/s may not be covered or protected by insurance against losses.
                  </p>
                  <p i18n>
                    While ChainEX undertakes to take the necessary steps to protect your cryptocurrency, you acknowledge that due to the nature of centralised financial platforms, decentralised
                    financial platforms, liquidity pools, liquidity mining, staking protocols and the DeFi ecosystem there is an inherent risk and users partaking in our staking product/s do so at
                    their own risk. You understand and agree that should any losses be incurred through the use of any centralised financial platforms, decentralised financial platforms, liquidity
                    pools, liquidity mining, staking protocols, the DeFi ecosystem or other mechanism, it may impact your investment.
                  </p>
                  <p i18n>
                    Before subscribing to ChainEX's staking product/s, you should ensure that you understand the risks associated with cryptocurrencies and centralised financial platforms, decentralised
                    financial platforms, liquidity pools, liquidity mining, staking protocols and the DeFi ecosystem. Cryptocurrency markets can be volatile and prices can fluctuate significantly, which
                    could result in sudden and significant increases or decreases in the value of your cryptocurrency. There may be additional risks not identified in these Staking Terms or in the risk
                    warning.
                  </p>
                  <p i18n>
                    You understand and agree that you are subscribing to ChainEX's staking product/s at your own risk. This section is not exhaustive and does not disclose all the risks associated with
                    the use of our staking product/s. You should carefully assess whether your financial situation and risk tolerance is suitable for staking. You accept and agree that ChainEX does not
                    guarantee the return that you may earn on it staking product/s.
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>DISCONTINUATION OF STAKING SERVICES </h4>

                  <p i18n>
                    ChainEX reserves the right to end or discontinue the staking services at any time and for any or no reason whatsoever. ChainEX will notify all users via email or, public channels
                    like the ChainEX website, blog or other social media platforms ChainEX has a presence on, should it decide to discontinue its staking services and by giving at least thirty calendar
                    days' notice of such intended discontinuation.
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>COMPLIANCE </h4>

                  <p class="row mt-0">
                    To the extent that any new or amended legislation, governmental agency or regulatory body in South Africa or any other country takes any action which prevents, restricts, or otherwise
                    limits ChainEX’s ability to provide any part of its staking services to a user, ChainEX shall have no liability in connection with the provision of, or failure to provide, such
                    staking services to such user, and ChainEX may, at its sole discretion:
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.2</span>
                    <span class="col-11" i18n>
                      Make a reasonable effort to relocate its staking services to another location, or
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.3</span>
                    <span class="col-11" i18n>
                      Terminate the applicable staking services immediately and without penalty.
                    </span>
                  </p>
                </li>
              </ol><br>

              <!-- ANNEXURE C -->
              <h4 class="table-heading" id="minor" i18n>ANNEXURE C </h4>
              <ol class="list-unstyled mx-3">
                <li>
                  <h3 class="table-heading" i18n>ADDENDUM: ChainEX Minor Account Terms and Conditions</h3>
                  <p i18n>
                    This Minor account Terms and Conditions constitute an addendum to the ChainEX Terms of Use and Conditions for the provision of and use of a Minor Account on ChainEX.
                    These terms apply to each application to open a Minor Account and to all Minor Accounts opened on ChainEX. To the extent there is a conflict between the ChainEX Minor Account
                    Terms and Conditions and the general Terms of Use and Conditions, the terms of this ChainEX Minor Account Terms and Conditions shall take precedence.
                    The Minor shall be the child under the age of 18 years, in who’s name the application to open a minor account has been made by their Guardian.
                    You consent and agree that you as the legal guardian and Authorised User, as well as the Minor will be legally bound by the ChainEX Terms of Use Agreement.
                  </p>
                </li>
              </ol>
              <ol>
                <li>
                  <h4 class="table-heading" i18n>MINOR ACCOUNT</h4>
                  <p class="row mt-0">
                    <span class="col-11" i18n>
                      A Minor Account shall mean a ChainEX account that is opened in the name of a natural person that is under the age of 18 years old by their legal guardian.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>WARRANTIES</h4>
                  <p class="row mt-0">
                    BY CLICKING THE "CREATE ACCOUNT" BUTTON OR BY ACCESSING OR USING THE CHAINEX SITE, YOU WARRANT THAT:
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">2.1</span>
                    <span class="col-11" i18n>
                      You are the legal guardian of the Minor and that you are competent to legally represent them;
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">2.2</span>
                    <span class="col-11" i18n>
                      The Minor resides with you at your residential address;
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">2.3</span>
                    <span class="col-11" i18n>
                      You grant consent for the Minor to be bound by the terms of this Agreement, as amended from time to time;
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">2.4</span>
                    <span class="col-11" i18n>
                      You understand that in connection with opening an account in the name of the Minor, certain personal information which may be protected by data protection legislation,
                      including amongst others, the Protection of Person Information Act, 2013 (POPI) will be collected by ChainEX;
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">2.5</span>
                    <span class="col-11" i18n>
                      You are a “competent person” as define in POPI who is legally competent to consent to any action or decision being taken in respect of any matter concerning the Minor; and
                      <br><br>
                      You hereby authorise ChainEX to:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1">2.5.1</span>
                      <span class="col-11" i18n>
                        Process all of the Minor’s personal data and all of your personal data;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">2.5.2</span>
                      <span class="col-11" i18n>
                        Transmit any such personal information to an affiliate or partner of ChainEX, for the purpose of performing the Agreement and in the furtherance of ChainEX’s
                        legitimate interest including, statistical analysis and marketing of ChainEX’s services;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1">2.5.3</span>
                      <span class="col-11" i18n>
                        Transmit any such personal information to any affiliate or partner of ChainEX for the purpose of marketing our services and products and the services and
                        products of any of ChainEX’s affiliates or partners.
                      </span>
                    </li>
                  </ul>
                </li><br>
                <li>
                  <h4 class="table-heading" i18n>USE OF THE MINOR ACCOUNT</h4>
                  <p class="row mt-0">
                    <span class="col-11" i18n>
                      The provisions contained in this addendum: ChainEX Minor Account Terms and Conditions, relating to the use of the Minor account shall be in addition to the provisions
                      as contained in the ChainEX Terms of Use and Conditions and all laws, regulations and policies referenced in the Agreement. As the legal guardian of the Minor:
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.1</span>
                    <span class="col-11" i18n>
                      You consent to opening an account in the name of the Minor’s name.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.2</span>
                    <span class="col-11" i18n>
                      You confirm that the Minor Account will only be accessed and operated by yourself.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.3</span>
                    <span class="col-11" i18n>
                      You confirm you will be acting as an Authorised User when accessing and operating the Minor Account.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.4</span>
                    <span class="col-11" i18n>
                      You acknowledge that all activities and actions performed on the Minor account are made solely by you. This means that you make your own choices and
                      decisions and even if you rely on information provided on the ChainEX Platform, we will not be responsible for any losses suffered
                    </span>
                  </p>
                </li>
              </ol><br>

              <p class="text-center">Last Revised: 13 July 2023</p>

              <div class="alert alert-secondary clearfix" role="alert">
                <div class="row">
                  <div class="col-1 reduce-max-w">
                      <mat-icon svgIcon="map-marker"></mat-icon>
                  </div>
                  <div class="col-11">
                    <p i18n>
                      {{address.NAME}},
                      {{address.STREET}}
                      {{address.TOWN}}
                      <br />
                      <span>{{address.REGISTRATION}} {{address.COUNTRY}}.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
