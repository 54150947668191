import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {

  address: typeof environment.config.ADDRESS_DETAILS = environment.config.ADDRESS_DETAILS;

  constructor() {
  }

  ngOnInit() {
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  getAppUrl(): string {
    return environment.config.APP_URL;
  }

  getSupportEmail(): string {
    return environment.config.SUPPORT_EMAIL;
  }

}
