<div class="container-fluid">
  <div class="row back pt-1 pl-1">

    <div class="col-12 col-lg-3 col-xlg-2 order-1 order-lg-0 mb-2 fullHeight full-market mat-elevation-z2">
      <markets-sidebar #sidebar class="h-100"></markets-sidebar>
    </div>

    <div class="col-12 col-lg-9 col-xlg-10 order-0 order-lg-1 full-table">
      <div class="row">
        <div class="col-lg-12 fix-gutter">
          <div class="row mat-elevation-z2 pt-3 mb-2 details">
            <div class="col-sm-12 mb-2">
              <h4 class="table-heading" i18n>LEGAL</h4>
              <ul class="small-text">
                <li><a href="/terms" target="_blank" i18n>Terms of Use</a></li>
                <li><a href="/privacy" target="_blank" i18n>Privacy Policy</a></li>
                <li><a href="/amlkyc" target="_blank" i18n>Anti Money Laundering (AML) and Know Your Customer (KYC) Policy</a></li>
                <li><a href="/risk" target="_blank" i18n>Risk Disclosure</a></li>
                <li><a href="/assets/chainex/PAIA-Manual.pdf" target="_blank" i18n>PAIA Manual</a></li>
                <li><a href="/assets/chainex/Competition-Rules.pdf" target="_blank" i18n>Competition Rules</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
