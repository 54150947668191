<div class="container-fluid">
  <div class="row back pt-1 pl-1">
    <div
      class="
        col-12 col-lg-3 col-xlg-2
        order-1 order-lg-0
        mb-2
        fullHeight
        full-market
        mat-elevation-z2
      "
    >
      <markets-sidebar #sidebar class="h-100"></markets-sidebar>
    </div>

    <div
      class="
        col-12 col-lg-9 col-xlg-10
        order-0 order-lg-1
        mb-2
        full-table
        overflow-x-hidden
      "
    >
      <div class="row">
        <div class="col-lg-12 fix-gutter">
          <div class="row mat-elevation-z2 pt-3 details">
            <div class="col-sm-12">
              <h4 class="table-heading" i18n>CHAINEX API</h4>

              <div class="left-padding">
                <h4 class="table-heading" i18n>OVERVIEW</h4>
                <p>
                  <ng-container i18n>
                    ChainEX API offers a simple and more standardized REST API
                    to make full use of our site functionality. The standard API
                    responses are JSON encoded arrays and we support GET, POST
                    and DELETE requests. Please be aware that calls to the API
                    are rate limited to 10 requests per second, any requests
                    exceeding this rate will be met with a HTTP 503 response.
                  </ng-container>
                </p>
                <h4 class="bottom-margin-20 table-heading" i18n>
                  API REFERENCE
                </h4>

                <strong class="table-heading" i18n>Market</strong>

                <ol class="reference">
                  <li><a href="/api#marketsummary" i18n>Market Summary</a></li>
                  <li><a href="/api#marketstats" i18n>Market Stats</a></li>
                  <li><a href="/api#markettrades" i18n>Market Trades</a></li>
                  <li><a href="/api#marketorders" i18n>Market Orders</a></li>
                  <li>
                    <a href="/api#marketchartdata" i18n>Market Chart Data</a>
                  </li>
                </ol>

                <strong>Wallet</strong>

                <ol class="reference">
                  <li>
                    <a href="/api#walletbalances" i18n>Wallet Balances</a>
                  </li>
                  <li>
                    <a href="/api#walletdeposits" i18n>Wallet Deposits</a>
                  </li>
                  <li>
                    <a href="/api#walletdepositaddress" i18n
                      >Wallet Deposit Address</a
                    >
                  </li>
                  <li>
                    <a href="/api#walletnewdepositaddress" i18n
                      >Wallet New Deposit Address</a
                    >
                  </li>
                  <li>
                    <a href="/api#walletwithdrawals" i18n>Wallet Withdrawals</a>
                  </li>
                  <li>
                    <a href="/api#walletwithdrawal" i18n
                      >Wallet Single Withdrawal</a
                    >
                  </li>
                  <li>
                    <a href="/api#walletrequestwithdrawal" i18n
                      >Wallet Request Withdrawal</a
                    >
                  </li>
                  <li>
                    <a href="/api#walletcancelwithdrawal" i18n
                      >Wallet Cancel Withdrawal</a
                    >
                  </li>
                </ol>

                <strong>Trading</strong>

                <ol class="reference">
                  <li><a href="/api#tradingorders" i18n>Trading Orders</a></li>
                  <li>
                    <a href="/api#tradingorder" i18n>Trading Single Order</a>
                  </li>
                  <li>
                    <a href="/api#tradingaddorder" i18n>Trading Add Order</a>
                  </li>
                  <li>
                    <a href="/api#tradingcancelorder" i18n
                      >Trading Cancel Order</a
                    >
                  </li>
                  <li><a href="/api#tradingtrades" i18n>Trading Trades</a></li>
                  <li>
                    <a href="/api#tradingtrade" i18n>Trading Single Trade</a>
                  </li>
                </ol>

                <strong i18n>Sample Code</strong>

                <ol class="reference">
                  <li><a href="/api#phpsample" i18n>PHP</a></li>
                </ol>

                <h4 class="table-heading" i18n>AUTHENTICATION</h4>
                <p>
                  <ng-container i18n>
                    All requests to the Wallet and Trading methods require
                    authentication via a public and private API key pair. An
                    authenticated API request must contain the following three
                    items.
                  </ng-container>
                </p>
                <br /><br />

                <table
                  class="table-responsive table-one table fees main-block"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th width="130" i18n>Parameter</th>
                      <th i18n>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>key</td>
                      <td i18n>Your public API Key.</td>
                    </tr>
                    <tr>
                      <td>time</td>
                      <td i18n>
                        Current unix timestamp (your requesting device MUST keep
                        accurate time).
                      </td>
                    </tr>
                    <tr>
                      <td>hash</td>
                      <td i18n>
                        HMAC-SHA-256 hash of the full URL and request data,
                        including the public key and timestamp.
                      </td>
                    </tr>
                  </tbody>
                </table>

                <br />
                <p>
                  <ng-container i18n>
                    <strong>IMPORTANT:</strong> The unix timestamp included in
                    the API calls must be accurate to within 5 seconds, any
                    requests received with a timestamp outside of this window
                    will be rejected to aid in the prevention of replay attacks.
                    You can call the following method
                    {{ getApiUrl() }}/timestamp to get our current server time.
                  </ng-container>
                </p>

                <div class="table-heading"><a></a>Authenticated Example</div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/balances/?key=1239[...]55ac8&time=1396560131&hash=b5c45e[...]f5375c</pre>
                <p>
                  <ng-container i18n>
                    The hash value is a HMAC-SHA-256 hash of the full url e.g.
                    {{
                      getApiUrl()
                    }}/wallet/balances/?key=1239[...]55ac8&time=1396560131. The
                    same method applies for POST requests.
                  </ng-container>
                </p>
                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":42,<br />   "data":[&#123;<br />      "code":"LTC",<br />      "name":"Litecoin",<br />      "balance_available":"2.00000000",<br />      "balance_pending_deposit":"0.00000000",<br />      "balance_pending_withdraw":"0.00000000",<br />      "balance_held":"6.50000000"<br />   &#125;,<br />   ...<br />   ]<br />&#125;</pre>

                <h4 class="table-heading" i18n>API RESPONSE</h4>
                <p>
                  <ng-container i18n>
                    The response from the API is standard with a
                    <strong>status</strong> field that shows if the request was
                    successful or not. Based on this, there will be a
                    <strong>data</strong> field that holds the data as an array
                    if successful, a <strong>count</strong> field if fetching
                    more than one item and/or a <strong>message</strong>
                    field that holds the success/error message.
                  </ng-container>
                </p>
                <br /><br />
                <p>
                  <ng-container i18n>Example successful response:</ng-container>
                </p>
                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":42,<br />   "data":[&#123;...&#125;]<br />&#125;</pre>
                <p>
                  <ng-container i18n>Example error response:</ng-container>
                </p>
                <pre
                  class="api"
                >&#123;<br />   "status":"error",<br />   "message":"The coin code does not exist."<br />&#125;</pre>

                <h4 class="table-heading" i18n>MARKET METHODS</h4>
                <p>
                  <ng-container i18n
                    >All markets methods are public and do not require
                    authentication.</ng-container
                  >
                </p>

                <a id="marketsummary" class="table-heading"></a>
                <h4 class="table-heading" i18n>Market Summary</h4>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/market/summary/</pre>
                <p>
                  <ng-container i18n>
                    Provides an overview of all our markets. Data refreshes
                    every minute.
                  </ng-container>
                </p>
                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/market/summary/&#123;EXCHANGE&#125;</pre>
                <p>
                  <ng-container i18n
                    >Provides an overview of only BTC or LTC markets. Data
                    refreshes every minute.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/market/summary/BTC
                </p>
                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":42,<br />   "data":[&#123;<br />      "code":"LTC",<br />      "exchange":"BTC",<br />      "last_price":"0.04600001",<br />      "yesterday_price":"0.04300000",<br />      "change":"+6.98",<br />      "24hhigh":"0.04980000",<br />      "24hlow":"0.04000050",<br />      "24hvol":"21.737"<br />      "top_bid":"0.04590000"<br />      "top_ask":"0.04600005"<br />   &#125;,<br />   ...<br />   ]<br />&#125;</pre>

                <p i18n>
                  NOTE: Yesterday price refers to what the price was midnight
                  UTC.
                </p>

                <a id="marketstats" class="table-heading"></a>
                <h4 class="table-heading" i18n>Market Stats</h4>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/market/stats/&#123;COIN&#125;/&#123;EXCHANGE&#125;</pre>
                <p>
                  <ng-container i18n
                    >Provides the statistics for a single market. Data refreshes
                    every minute.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/market/stats/LTC/BTC
                </p>
                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "data":&#123;<br />      "code":"LTC",<br />      "exchange":"BTC",<br />      "last_price":"0.04600001",<br />      "yesterday_price":"0.04300000",<br />      "change":"+6.98",<br />      "24hhigh":"0.04980000",<br />      "24hlow":"0.04000050",<br />      "24hvol":"21.737"<br />      "top_bid":"0.04590000"<br />      "top_ask":"0.04600005"<br />   &#125;<br />&#125;</pre>

                <p i18n>
                  NOTE: Yesterday price refers to what the price was midnight
                  UTC.
                </p>

                <a id="markettrades" class="table-heading"></a>
                <h4 class="table-heading" i18n>Market Trades</h4>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/market/trades/&#123;COIN&#125;/&#123;EXCHANGE&#125;</pre>
                <p>
                  <ng-container i18n
                    >Fetches the last 100 trades for a given
                    market.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/market/trades/LTC/BTC
                </p>
                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/market/trades/&#123;COIN&#125;/&#123;EXCHANGE&#125;/&#123;LIMIT&#125;</pre>
                <p>
                  <ng-container i18n
                    >Fetches the specified number of trades for a given market,
                    maximum limit is 200.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/market/trades/LTC/BTC/150
                </p>
                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":100,<br />   "data":[&#123;<br />      "type":"SELL",<br />      "price":"0.00000023",<br />      "amount":"412128.80177019",<br />      "total":"0.09478962",<br />      "time":"1394498289.2727"<br />   &#125;,<br />   ...<br />   ]<br />&#125;</pre>

                <p i18n>
                  NOTE: Time is specified as a unix timestamp with microseconds.
                </p>

                <h4 class="table-heading">
                  <a id="marketorders"></a>
                  <ng-container i18n>Market Orders</ng-container>
                </h4>

                <h4 class="table-heading" i18n>Single Type</h4>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/market/orders/&#123;COIN&#125;/&#123;EXCHANGE&#125;/&#123;TYPE&#125;</pre>
                <p>
                  <ng-container i18n>
                    Fetches the 50 best priced orders of a given type for a
                    given market, where {{ "{" }}TYPE{{ "}" }} can be either BUY
                    or SELL. </ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/market/orders/LTC/BTC/BUY
                </p>
                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/market/orders/&#123;COIN&#125;/&#123;EXCHANGE&#125;/&#123;TYPE&#125;/&#123;LIMIT&#125;</pre>
                <p>
                  <ng-container i18n>
                    Fetches the specified number of best priced orders of a
                    given type for a given market, where
                    {{ "{" }}TYPE{{ "}" }} can be either BUY or SELL, maximum
                    limit is 200.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/market/orders/LTC/BTC/BUY/150
                </p>
                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":23,<br />   "data":[&#123;<br />      "price":"0.00000023",<br />      "amount":"22446985.14519785",<br />      "total":"5.16280655"<br />   &#125;,<br />   ...<br />   ]<br />&#125;</pre>

                <h4 class="table-heading" i18n>Combined</h4>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/market/orders/&#123;COIN&#125;/&#123;EXCHANGE&#125;/ALL</pre>

                <p>
                  <ng-container i18n>
                    Fetches the 50 best priced buy and sell orders together for
                    a given market.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/market/orders/LTC/BTC/ALL
                </p>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/market/orders/&#123;COIN&#125;/&#123;EXCHANGE&#125;/ALL/&#123;LIMIT&#125;</pre>
                <p>
                  <ng-container i18n>
                    Fetches the specified number of best priced buy and sell
                    orders together for a given market, maximum limit is 200 for
                    each type.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/market/orders/LTC/BTC/ALL/150
                </p>
                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "data":[&#123;<br />      "type":"buy",<br />      "count":23,<br />      "orders":[&#123;<br />         "price":"0.00000023",<br />         "amount":"22446985.14519785",<br />         "total":"5.16280655"<br />      &#125;,<br />      ...<br />      ]&#125;,&#123;<br />      "type":"sell",<br />      "count":150,<br />      "orders":[&#123;<br />         "price":"0.00000024",<br />         "amount":"1000000.00000000",<br />         "total":"0.24000000"<br />      &#125;,<br />      ...<br />      ]&#125;<br />   &#125;]<br />&#125;</pre>

                <div class="table-heading">
                  <a id="marketchartdata"></a>
                  <ng-container i18n>Market Chart Data</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/market/chartdata/&#123;COIN&#125;/&#123;EXCHANGE&#125;/&#123;PERIOD&#125;</pre>
                <p>
                  <ng-container i18n>
                    Fetches the chart data that we use for our candlestick
                    graphs for a market for a given time period. The period is
                    an optional parameter and can be either '6hh' (6 hours),
                    '1DD' (24 hours), '3DD' (3 days), '7DD' (1 week) or 'MAX'.
                    If no period is defined, it will default to 6
                    hours.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/market/chartdata/LTC/BTC/1DD
                </p>
                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":144,<br />   "data":[&#123;<br />      "date":"2017-02-09 14:20",<br />      "open":"0.00000006",<br />      "close":"0.00000006",<br />      "high":"0.00000006",<br />      "low":"0.00000003",<br />      "exchange_volume":"0.00002145",<br />      "coin_volume":"608.50000000"<br />   &#125;,<br />   ...<br />   ]<br />&#125;</pre>

                <h4 class="table-heading" i18n>WALLET METHODS</h4>

                <p>
                  <ng-container i18n
                    >All wallet methods require authentication.</ng-container
                  >
                </p>

                <div class="table-heading">
                  <a id="walletbalances"></a>
                  <ng-container i18n>Wallet Balances</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/balances/</pre>

                <p>
                  <ng-container i18n>
                    Fetches all the balances in your account, split down into
                    available balance, pending deposits, pending withdrawals and
                    held for orders.
                  </ng-container>
                </p>
                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/balances/&#123;COIN&#125;</pre>
                <p>
                  <ng-container i8n>
                    Fetches the balance for a specific coin, split down into
                    available balance, pending deposits, pending withdrawals and
                    held for orders.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/wallet/balances/BTC
                </p>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":42,<br />   "data":[&#123;<br />      "code":"LTC",<br />      "name":"Litecoin",<br />      "balance_available":"2.00000000",<br />      "balance_pending_deposit":"0.00000000",<br />      "balance_pending_withdraw":"0.00000000",<br />      "balance_held":"6.50000000"<br />   &#125;,<br />   ...<br />   ]<br />&#125;</pre>

                <div class="table-heading">
                  <a id="walletdeposits"></a>
                  <ng-container i18n>Wallet Deposits</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/deposits/</pre>
                <p>
                  <ng-container i18n>
                    Fetches the deposits made in to your account, including
                    pending and confirmed deposits, ordered by most recent first
                    and limited to 25 results.
                  </ng-container>
                </p>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/deposits/&#123;COIN&#125;</pre>
                <p>
                  <ng-container i18n>
                    Fetches the deposits made in to your account for a specific
                    coin, including pending and confirmed deposits, ordered by
                    most recent first and limited to 25 results.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/wallet/deposits/BTC
                </p>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/deposits/&#123;COIN&#125;/&#123;START&#125;/&#123;LIMIT&#125;</pre>
                <p>
                  <ng-container i18n>
                    By default, the method will fetch 25 results. Use the start
                    and limit parameters to fetch more results. Use ALL for the
                    coin if you want to fetch more results for all coins. The
                    maximum limit is 200 entries.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/wallet/deposits/ALL/25/50 fetches the 26th
                  to 75th items.
                </p>
                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":25,<br />   "data":[&#123;<br />      "code":"BTC",<br />      "address":"1234567890AbCdEfGhIjKlMnOpQrStUvWxYz",<br />      "amount":"0.06600000",<br />      "txid":"2c6f31555b5309293f0f83a38703ef7fa74c163155642d3fe53c9cc488bf35eg",<br />      "time":"1393776944",<br />      "pending":"0",<br />      "confirms":null<br />      "req_confirms":"3"<br />      "time_formatted":"2017-03-20 19:50:39"<br />      "status":"CONFIRMED"<br />   &#125;,<br />   ...<br />   ]<br />&#125;</pre>

                <div class="table-heading">
                  <a id="walletdepositaddress"></a>
                  <ng-container i18n>Wallet Deposit Address</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/depositaddress/&#123;COIN&#125;</pre>

                <p>
                  <ng-container i18n>
                    Fetches the deposit address for the specified coin.
                  </ng-container>
                </p>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "data":"1234567890AbCdEfGhIjKlMnOpQrStUvWxYz"<br />&#125;</pre>

                <div class="table-heading">
                  <a id="walletnewdepositaddress"></a>
                  <ng-container i18n>Wallet New Deposit Address</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/newdepositaddress/&#123;COIN&#125;</pre>

                <p>
                  <ng-container i18n>
                    Fetches a new deposit address for the specified coin.
                  </ng-container>
                </p>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "data":"1234567890AbCdEfGhIjKlMnOpQrStUvWxYz"<br />&#125;</pre>

                <div class="table-heading">
                  <a id="walletwithdrawals"></a>
                  <ng-container i18n>Wallet Withdrawals</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/withdrawals/</pre>

                <p>
                  <ng-container i18n>
                    Fetches the withdrawals made from your account, including
                    pending and completed withdrawals, ordered by most recent
                    first and limited to 25 results.
                  </ng-container>
                </p>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/withdrawals/&#123;COIN&#125;</pre>

                <p>
                  <ng-container i18n>
                    Fetches the withdrawals made from your account for a
                    specific coin, including pending and completed withdrawals,
                    ordered by most recent first and limited to 25
                    results.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/wallet/withdrawals/BTC
                </p>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/withdrawals/&#123;COIN&#125;/&#123;START&#125;/&#123;LIMIT&#125;</pre>
                <p>
                  <ng-container i18n>
                    By default, the method will fetch 25 results. Use the start
                    and limit parameters to fetch more results. Use ALL for the
                    coin if you want to fetch more results for all coins. The
                    maximum limit is 200 entries.</ng-container
                  ><br />
                  Example:
                  {{ getApiUrl() }}/wallet/withdrawals/ALL/25/50 fetches the
                  26th to 75th items.
                </p>
                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":25,<br />   "data":[&#123;<br />      "id":"70935",<br />      "code":"BTC",<br />      "address":"1234567890AbCdEfGhIjKlMnOpQrStUvWxYz",<br />      "amount":"0.06600000",<br />      "fee":"0.00020000",<br />      "txid":"2c6f31555b5309293f0f83a38703ef7fa74c163155642d3fe53c9cc488bf35eg",<br />      "time":"1393776944",<br />      "pending":"0",<br />      "time_formatted":"2017-03-20 19:50:39"<br />      "status":"SUCCESSFUL"<br />   &#125;,<br />   ...<br />   ]<br />&#125;</pre>

                <div class="table-heading">
                  <a id="walletwithdrawal"></a>
                  <ng-container i18n>Wallet Single Withdrawal</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/wallet/withdrawal/&#123;ID&#125;</pre>

                <p>
                  <ng-container i18n>
                    Fetches the details of a single withdrawal identified by the
                    withdrawal ID.
                  </ng-container>
                </p>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "data":&#123;<br />      "id":"70935",<br />      "code":"BTC",<br />      "address":"1234567890AbCdEfGhIjKlMnOpQrStUvWxYz",<br />      "amount":"0.06600000",<br />      "fee":"0.00020000",<br />      "txid":"2c6f31555b5309293f0f83a38703ef7fa74c163155642d3fe53c9cc488bf35eg",<br />      "time":"1393776944",<br />      "pending":"0",<br />      "time_formatted":"2017-03-20 19:50:39"<br />      "status":"SUCCESSFUL"<br />   &#125;<br />&#125;</pre>

                <div class="table-heading">
                  <a id="walletrequestwithdrawal"></a>
                  <ng-container i18n>Wallet Request Withdrawal</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apipost">POST</div> {{getApiUrl()}}/wallet/withdraw/&#123;COIN&#125;</pre>

                <p>
                  <ng-container i18n
                    >Submits a withdrawal request, requires email confirmation
                    to process.</ng-container
                  >
                </p>

                <h4 class="table-heading" i18n>Parameters</h4>

                <table
                  class="table-responsive table-one table fees main-block"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th width="130" i18n>Name</th>
                      <th width="110" i18n>Type</th>
                      <th width="120" i18n>Required</th>
                      <th i18n>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>address</td>
                      <td>String</td>
                      <td i18n>Yes</td>
                      <td i18n>The address you wish to withdraw to.</td>
                    </tr>
                    <tr>
                      <td>amount</td>
                      <td>Decimal</td>
                      <td i18n>Yes</td>
                      <td i18n>
                        The amount you wish to withdraw, up to 8 decimals.
                        Remember that a withdrawal fee will be applied to this
                        amount.
                      </td>
                    </tr>
                    <tr>
                      <td>payment_id</td>
                      <td>String</td>
                      <td i18n>No</td>
                      <td i18n>
                        An optional Payment ID to include with your withdrawal.
                        This is only used for coins based on the CryptoNote
                        protocol such as Monero.
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "message":"Your withdrawal request has now been added, please confirm by email and we will then process it.",<br />   "data":&#123;<br />      "id":"70935",<br />      "code":"BTC",<br />      "address":"1234567890AbCdEfGhIjKlMnOpQrStUvWxYz",<br />      "amount":"0.06600000",<br />      "fee":"0.00020000",<br />      "txid":"2c6f31555b5309293f0f83a38703ef7fa74c163155642d3fe53c9cc488bf35eg",<br />      "time":"1393776944",<br />      "pending":"0",<br />      "time_formatted":"2017-03-20 19:50:39"<br />      "status":"UNCONFIRMED"<br />      "status":"Please confirm the withdrawal by email."<br />   &#125;<br />&#125;</pre>

                <div class="table-heading">
                  <a id="walletcancelwithdrawal"></a>
                  <ng-container i18n>Wallet Cancel Withdrawal</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apidelete">DELETE</div> {{getApiUrl()}}/wallet/withdraw/&#123;ID&#125;</pre>

                <p>
                  <ng-container i18n>
                    Cancels a withdrawal request identified by the withdrawal
                    ID, can only cancel unconfirmed and unsuccessful
                    withdrawals.
                  </ng-container>
                </p>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "message":"The pending withdrawal has successfully been cancelled and the balance made available again."<br />&#125;</pre>

                <h4 class="table-heading" i18n>TRADING METHODS</h4>

                <p>
                  <ng-container i18n>
                    All trading methods require authentication.
                  </ng-container>
                </p>

                <div class="table-heading">
                  <a id="tradingorders"></a>
                  <ng-container>Trading Orders</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/trading/orders/</pre>

                <p>
                  <ng-container i18n>
                    Fetches the current open orders in your account, ordered by
                    most recent first and limited to 25 results.
                  </ng-container>
                </p>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/trading/orders/&#123;COIN&#125;</pre>

                <p>
                  <ng-container i18n>
                    Fetches the current open orders in your account for a
                    specified coin, ordered by most recent first and limited to
                    25 results. </ng-container
                  ><br />
                  Example:
                  {{ getApiUrl() }}/trading/orders/BTC
                </p>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/trading/orders/&#123;COIN&#125;/&#123;START&#125;/&#123;LIMIT&#125;</pre>

                <p>
                  <ng-container i18n>
                    By default, the method will fetch 25 results. Use the start
                    and limit parameters to fetch more results. Use ALL for the
                    coin if you want to fetch more results for all
                    coins/markets. The maximum limit is 200
                    entries.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/trading/orders/ALL/25/50 fetches the 26th to
                  75th items.
                </p>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":25,<br />   "data":[&#123;<br />      "order_id":"YFHG-12345678",<br />      "market":"LTC\/BTC",<br />      "type":"BUY",<br />      "price":"0.00000015",<br />      "amount":"500.00000000",<br />      "total":"0.00007500",<br />      "fee":"0.00000011",<br />      "net_total":"0.00007511",<br />      "time":"1393776944.1234",<br />      "time_formatted":"2017-03-20 19:50:39"<br />   &#125;,<br />   ...<br />   ]<br />&#125;</pre>

                <div class="table-heading">
                  <a id="tradingorder"></a>
                  <ng-container i18n>Trading Single Order</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/trading/order/&#123;ID&#125;</pre>

                <p>
                  <ng-container i18n>
                    Fetches the order identified by the order ID.
                  </ng-container>
                </p>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "data":&#123;<br />      "order_id":"YFHG-12345678",<br />      "market":"LTC\/BTC",<br />      "type":"BUY",<br />      "price":"0.00000015",<br />      "amount":"500.00000000",<br />      "total":"0.00007500",<br />      "fee":"0.00000011",<br />      "net_total":"0.00007511",<br />      "time":"1393776944.1234",<br />      "time_formatted":"2017-03-20 19:50:39"<br />   &#125;<br />&#125;</pre>

                <div class="table-heading">
                  <a id="tradingaddorder"></a>
                  <ng-container i18n>Trading Add Order</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apipost">POST</div> {{getApiUrl()}}/trading/order/</pre>

                <p>
                  <ng-container i18n>
                    Submits a new order to market.
                  </ng-container>
                </p>

                <h4 class="table-heading" i18n>Parameters</h4>

                <table
                  class="table-responsive table-one table fees main-block"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th width="130" i18n>Name</th>
                      <th width="110" i18n>Type</th>
                      <th width="120" i18n>Required</th>
                      <th i18n>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>coin</td>
                      <td>String</td>
                      <td i18n>Yes</td>
                      <td i18n>
                        The first half of the market, e.g. if want to add order
                        to LTC/BTC, enter LTC.
                      </td>
                    </tr>
                    <tr>
                      <td>exchange</td>
                      <td>String</td>
                      <td i18n>Yes</td>
                      <td i18n>
                        The second half of the market, e.g. if want to add order
                        to LTC/BTC, enter BTC.
                      </td>
                    </tr>
                    <tr>
                      <td>price</td>
                      <td>Decimal</td>
                      <td i18n>Yes</td>
                      <td i18n>The price of the order, up to 8 decimals.</td>
                    </tr>
                    <tr>
                      <td>amount</td>
                      <td>Decimal</td>
                      <td i18n>Yes</td>
                      <td i18n>
                        The amount of coins you wish to purchase or sell, up to
                        8 decimals.
                      </td>
                    </tr>
                    <tr>
                      <td>type</td>
                      <td>Integer</td>
                      <td i18n>Yes</td>
                      <td i18n>If the order is a BUY (0) or a SELL (1).</td>
                    </tr>
                    <tr>
                      <td>post_only</td>
                      <td>boolean</td>
                      <td i18n>No</td>
                      <td i18n>
                        An order will only be placed if it does not match against an existing order in the order book,
                        i.e. it will only be placed as a Maker Order
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "message":"The order has successfully gone to market.",<br />   "data":&#123;<br />      "order_id":"YFHG-12345678",<br />      "market":"LTC/BTC",<br />      "type":"BUY",<br />      "price":"0.00000015",<br />      "amount":"500.00000000",<br />      "total":"0.00007500",<br />      "fee":"0.00000011",<br />      "net_total":"0.00007511",<br />      "time":"1393776944.1234",<br />      "time_formatted":"2017-03-20 19:50:39"<br />   &#125;<br />&#125;</pre>

                <div class="table-heading">
                  <a id="tradingcancelorder"></a>
                  <ng-container i18n>Trading Cancel Order</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apidelete">DELETE</div> {{getApiUrl()}}/trading/order/&#123;ID&#125;</pre>

                <p>
                  <ng-container i18n>
                    Cancels an open order identified by the order ID. Warning:
                    order cancellations are processed FIFO (First In First Out)
                    alongside new orders, so it may be matched before the
                    cancellation can be processed.
                  </ng-container>
                </p>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "message":"The pending withdrawal has successfully been cancelled and the balance made available again."<br />&#125;</pre>

                <div class="table-heading">
                  <a id="tradingtrades"></a>
                  <ng-container i18n>Trading Trades</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/trading/trades/</pre>

                <p>
                  <ng-container i18n>
                    Fetches the trades made in your account, ordered by most
                    recent first and limited to 25 results.
                  </ng-container>
                </p>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/trading/trades/&#123;COIN&#125;</pre>

                <p>
                  <ng-container i18n>
                    Fetches the trades made in your account for a specific coin,
                    including BTC and LTC markets where applicable, ordered by
                    most recent first and limited to 25 results.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/trading/trades/BTC
                </p>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/trading/trades/&#123;COIN&#125;/&#123;START&#125;/&#123;LIMIT&#125;</pre>

                <p>
                  <ng-container i18n>
                    By default, the method will fetch 25 results. Use the start
                    and limit parameters to fetch more results. Use ALL for the
                    coin if you want to fetch more results for all
                    coins/markets. The maximum limit is 200
                    entries.</ng-container
                  ><br />
                  <ng-container i18n>Example:</ng-container>
                  {{ getApiUrl() }}/trading/trades/ALL/25/50 fetches the 26th to
                  75th items.
                </p>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "count":25,<br />   "data":[&#123;<br />      "trade_id":"YFHG-12345678",<br />      "market":"LTC\/BTC",<br />      "type":"BUY",<br />      "time":"1393776944.1234",<br />      "price":"0.00000015",<br />      "amount":"500.00000000",<br />      "total":"0.00007500",<br />      "fee":"0.00000011",<br />      "net_total":"0.00007511",<br />      "order_id":"ONYC-12345678",<br />      "time_formatted":"2017-03-20 19:50:39"<br />   &#125;,<br />   ...<br />   ]<br />&#125;</pre>

                <div class="table-heading">
                  <a id="tradingtrade"> </a>
                  <ng-container i18n>Trading Single Trade</ng-container>
                </div>

                <pre
                  class="api"
                ><div class="apirequest apiget">GET</div> {{getApiUrl()}}/trading/trade/&#123;ID&#125;</pre>

                <p>
                  <ng-container i18n>
                    Fetches the trade identified by the trade ID.
                  </ng-container>
                </p>

                <h4 class="table-heading" i18n>Response</h4>

                <pre
                  class="api"
                >&#123;<br />   "status":"success",<br />   "data":[&#123;<br />      "trade_id":"YFHG-12345678",<br />      "market":"LTC\/BTC",<br />      "type":"BUY",<br />      "time":"1393776944.1234",<br />      "price":"0.00000015",<br />      "amount":"500.00000000",<br />      "total":"0.00007500",<br />      "fee":"0.00000011",<br />      "net_total":"0.00007511",<br />      "order_id":"ONYC-12345678",<br />      "time_formatted":"2017-03-20 19:50:39"<br />   &#125;<br />&#125;</pre>

                <h4 class="table-heading" i18n>SAMPLE CODE</h4>

                <p>
                  <ng-container i18n>
                    You are free to use and modify all of the below API sample
                    code.
                  </ng-container>
                </p>

                <div class="table-heading"><a id="phpsample"></a>PHP</div>

                <pre class="api">
&lt;&#063;php

  // Get balances
  $response = _doAPICall('wallet/balances');

  // Test a withdraw
  //$response = _doAPICall('wallet/withdraw/BTC', array('address' =&gt; "123456789", 'amount' =&gt; '10'), 'POST');

  // Delete an order
  //$response = _doAPICall('trading/order/KMTA-12345678', array(), 'DELETE');

  // Interfaces with the ChainEX API via cURL. Function handles, GET, POST & DELETE
  //
  // @param   string  $apiCall  The API function that we want to use
  // @param   array   $data     The data for the API function parameters
  // @param   string  $method   If we're using GET, POST or DELETE, uses GET by default
  // @return  array

  function _doAPICall($apiCall, $data = array(), $method = 'GET')
  &#123;

    // Setup public & private keys
    $apiPublicKey = "123931eeb6baadd53cbdbc755ac81cfd";
    $apiPrivateKey = "6546d5e0692d7d5dfd84c1a25ea9d7";

    // Setup URL
    $apiUrl = "{{ getApiUrl() }}/";

    // Set time to variable so it's definitely constant
    $time = time();

    // Start cURL
    $c = curl_init();
    curl_setopt($c, CURLOPT_CONNECTTIMEOUT, 5);
    curl_setopt($c, CURLOPT_RETURNTRANSFER, true);

    // Check what type of API call we are making
    if ($method == 'GET') &#123;

      // Start building the URL
      $apiCall = $apiUrl . $apiCall . "?time=" . $time . "&key=" . $apiPublicKey;

      // Add the array of data to the URL
      if (count($data)) &#123;
        $apiCall .= "&".http_build_query($data);
      &#125;

      // Hash the data
      $hash = hash_hmac("sha256", $apiCall, $apiPrivateKey);

      // Append the hash
      $apiCall .= "&hash=" . $hash;

      // Setup the remainder of the cURL request
      curl_setopt($c, CURLOPT_URL, $apiCall);

    &#125; else if ($method == 'DELETE') &#123;

      // DELETE requests use POSTFIELDS, so add the time and hash
      $data['time'] = $time;
      $data['key'] = $apiPublicKey;

      // Hash the data
      $data['hash'] = hash_hmac("sha256", $apiUrl . $apiCall . "?" . http_build_query($data), $apiPrivateKey);

      // Setup the remainder of the cURL request
      curl_setopt($c, CURLOPT_URL, $apiUrl . $apiCall);
      curl_setopt($c, CURLOPT_POSTFIELDS, http_build_query($data));
      curl_setopt($c, CURLOPT_HTTPHEADER, array('X-HTTP-Method-Override: ' . $method));

    &#125; else &#123;

      // It's a POST request, so add in some additional data
      $data['time'] = $time;
      $data['key'] = $apiPublicKey;

      // Hash the data
      $data['hash'] = hash_hmac("sha256", $apiUrl . $apiCall . "?" . http_build_query($data), $apiPrivateKey);

      // Setup the remainder of the cURL request
      curl_setopt($c, CURLOPT_URL, $apiUrl . $apiCall);
      curl_setopt($c, CURLOPT_POST, true);
      curl_setopt($c, CURLOPT_POSTFIELDS, http_build_query($data));

    &#125;

    // Execute the API call and return the response
    $result = curl_exec($c);
    curl_close($c);

    // Return the results of the API call
    return json_decode($result);

  &#125;

?&gt;</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
