<div class="container-fluid px-0">
  <div class="row mx-0 py-0 py-lg-3 my-3 my-lg-5">
    <div class="blueprint-background"> <!-- start blueprint background -->
      <div class="container">
        <div class="row mx-0">
          <div class="col-12 col-lg-4 px-3 px-lg-0 text-size">
            <h3 i18n><span class="orange-header">Security</span> measures</h3>
          </div>
          <div class="col-12 col-lg-8">
            <ol>
              <li>
                <h4 class="table-heading" i18n>SECURE CONNECTION</h4>
                <ng-container i18n>
                  The internet as we know it is prone to third parties intercepting and gathering private data.
                  All interactions with the ChainEX website and APIs are transferred securely over an encrypted SSL
                  connection using
                  HTTPS.
                </ng-container>
              </li>
              <li>
                <h4 class="table-heading" i18n>TWO-FACTOR (2FA) AUTHENTICATION</h4>
                <ng-container i18n>
                  With standard authentication procedures only requiring an email address and password, it has become
                  increasingly
                  easy for hackers to gain access to user accounts.
                  Users can set up 2FA to provide an extra layer of security during authentication to prevent unwanted
                  access.
                </ng-container>
              </li>
              <li>
                <h4 class="table-heading" i18n>COLD STORAGE</h4>
                <ng-container i18n>
                  Cold storage is a very necessary security precaution when dealing with large amounts of
                  cryptocurrencies.
                  The majority of our user's funds are kept offline in cold storage, with only the amount needed to
                  cover
                  anticipated
                  withdrawals kept in "hot" wallets for quick withdrawals.
                </ng-container>
              </li>
              <li>
                <h4 class="table-heading" i18n>APPLICATION SECURITY</h4>
                <ng-container i18n>
                  We routinely review our application code, and we use industry standards to prevent SQL Injection and
                  XSS
                  attacks.
                  All passwords and sensitive data are encrypted using best practices.
                </ng-container>
              </li>
              <li>
                <h4 class="table-heading" i18n>SECURE CLOUD ACCESS</h4>
                <ng-container i18n>
                  Our web and wallet servers are logically separated and protected by firewalls for increased
                  security.
                  All
                  server
                  access is done over a secure virtual private network (VPN) before gaining access to any system.
                </ng-container>
              </li>
              <li>
                <h4 class="table-heading" i18n>DDOS PROTECTION AND CDN CACHING</h4>
                <ng-container i18n>
                  We utilise a leading DDoS provider for all public facing content and cache all static content on a
                  content delivery
                  network (CDN) to provide the fastest possible load times.
                </ng-container>
              </li>
              <li>
                <h4 class="table-heading" i18n>
                  <a routerLink="/bounty">CHAINEX BOUNTY PROGRAM</a>
                </h4>
                <ng-container i18n>
                  ChainEX is a cryptocurrency exchange and as such security and protection of our system and platform is of the utmost importance to us.
                  This bug bounty has been put in place in order to encourage the responsible disclosure of any bug or vulnerability contained within the ChainEX code or protocols and if any are found to reward those who make such disclosures.
                </ng-container>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- end blueprint background -->
  </div>
</div>
