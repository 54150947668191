import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent {

  address: typeof environment.config.ADDRESS_DETAILS = environment.config.ADDRESS_DETAILS;

}
