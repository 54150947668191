<div class="container-fluid">
  <div class="row back pt-1 pl-1">

    <div class="col-12 col-lg-3 col-xlg-2 order-1 order-lg-0 mb-2 full-market mat-elevation-z2">
      <markets-sidebar #sidebar class="h-100"></markets-sidebar>
    </div>

    <div class="col-12 col-lg-9 col-xlg-10 order-0 order-lg-1 full-table overflow-x-hidden">
      <div class="row">
        <div class="col-lg-12 fix-gutter">
          <div class="row mat-elevation-z2 mb-2 pt-3 details">
            <div class="col-12 mb-2">
              <h4 class="table-heading" i18n>PRIVACY POLICY</h4>
              <ol>
                <li>

                  <h4 class="table-heading" i18n>INTRODUCTION</h4>

                  <p  class="row mt-0">
                    <span class="number col-1">1.1</span>
                    <span class="col-11" i18n>
                      In order to enable us to open and operate an account, to generally provide you with our products
                      and services and comply with laws and regulations, we may need to and/or may be required to collect,
                      record, hold, use, disclose and store Personal Information about you.
                    </span>
                  </p>
                  <p  class="row mt-0">
                    <span class="number col-1">1.2</span>
                    <span class="col-11" i18n>
                      This Privacy Policy – as amended or <u>otherwise</u> changed from time to time – describes how
                      ChainEX collects, uses, stores, shares, and protects your information (including Personal Information)
                      obtained through the chainex.io platform, a ChainEX mobile application or any ChainEX third-party
                      application relying on a ChainEX application or any ChainEX service. The terms "we," "us," and "our"
                      refer to ChainEX (Pty) Ltd.
                    </span>
                    </p>
                    <p  class="row mt-0">
                      <span class="number col-1">1.3</span>
                      <span class="col-11" i18n>
                        By using the ChainEX website and ChainEX services, you consent to the data practices prescribed
                        in this Privacy Policy. We may periodically post changes to this Privacy Policy on this page, and it
                        is your responsibility to review this Privacy Policy frequently and we encourage you to visit this
                        page often. When required by law, we will notify you of any changes to this Privacy Policy.
                      </span>
                    </p>
                </li>
                <li>

                  <h4 class="table-heading" i18n>WHAT IS PERSONAL INFORMATION?</h4>

                  <p class="row mt-0">
                    <span class="number col-1">2.1</span>
                    <span class="col-11" i18n>
                      "Personal Information" refers to any information from which your identity could reasonably be
                      ascertained. ChainEX will only collect and use your Personal Information in accordance with this
                      Privacy Policy and the terms of your agreement(s) with ChainEX. Please note that ChainEX:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">2.1.1</span>
                      <span class="col-11" i18n>
                        May collect Personal Information from you, such as your first and last name, e-mail and mailing
                        addresses, date of birth, government issued identification, photographs, company name, and password
                        when you use the ChainEX website;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">2.1.2</span>
                      <span class="col-11" i18n>
                        May also collect other Personal Information provided by third-party identity verification services
                        or via social networking websites;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">2.1.3</span>
                      <span class="col-11" i18n>
                        If you tell us where you are (e.g., by allowing your mobile device or computer to send us your location),
                        we may store that information;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">2.1.4</span>
                      <span class="col-11" i18n>
                        Certain services, such as two-factor authentication, may require our collection of your phone number. We
                        may associate that phone number to your mobile device identification information;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">2.1.5</span>
                      <span class="col-11" i18n>
                        If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well
                        as any other content included in the e-mail, in order to send you a reply;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">2.1.6</span>
                      <span class="col-11" i18n>
                        We also collect other types of Personal Information that you provide to us voluntarily when seeking support
                        services, such as email, information submitted via online form, other contact information, or other information
                        provided to support services staff; and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">2.1.7</span>
                      <span class="col-11" i18n>
                        We may collect other data, including but not limited to, referral URLs, your location and blockchain analytics
                        information related to blockchain addresses you provide.
                      </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">2.2</span>
                    <span class="col-11" i18n>
                      The following information is automatically collected by our servers:
                    </span>
                  </p>
                  <ul class="pb-3">
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">2.2.1</span>
                      <span class="col-11" i18n>
                        Our servers (which may be hosted by a third-party service provider) collects information from you, including your
                        browser type, operating system, Internet Protocol ("IP") address (a number that is automatically assigned to your
                        computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time stamp
                        for your visit;`
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">2.2.2</span>
                      <span class="col-11" i18n>
                        As is true of most websites, we gather certain information automatically and store it in log files. This information
                        includes IP addresses, browser type, Internet Service Provider ("ISP"), referring/exit pages, operating system, date/time
                        stamp, and clickstream data; and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">2.2.3</span>
                      <span class="col-11" i18n>
                        We retain information on your behalf, such as transactional data and other session data linked to your account.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4 class="table-heading" i18n>MECHANICS OF INFORMATION COLLECTION AND PROCESSING </h4>

                  <p class="row mt-0">
                    <span class="number col-1">3.1</span>
                    <span class="col-11" i18n>
                      Any collecting and processing of a User’s Personal Information will only be conducted to the extent necessary to promote
                      ChainEX’s legitimate business purposes and give effect to the necessary functioning of ChainEX’s products and/or services
                      together with User engagement with said products and/or services.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.2</span>
                    <span class="col-11" i18n>
                      By utilising any of ChainEX’s products and/or services, a user is presumed to have expressly consented to the collecting
                      and processing of Personal Information as referred to in clause 3.1. above.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">3.3</span>
                    <span class="col-11" i18n>
                      Without limiting the generality of the aforesaid, ChainEX will not without a User’s express further consent process a User’s
                      Personal Information for any purpose other than the following, namely:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.3.1</span>
                      <span class="col-11" i18n>
                        In respect of the provision of products and/or services;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.3.2</span>
                      <span class="col-11" i18n>
                        To fulfil orders and execute instructions on behalf of a user;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.3.3</span>
                      <span class="col-11" i18n>
                        For internal record keeping purposes;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.3.4</span>
                      <span class="col-11" i18n>
                        To perform anti-money laundering and know-your-client searches in respect of a user;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.3.5</span>
                      <span class="col-11" i18n>
                        To contact a user in respect of a new product and/or service offered by ChainEX or any of its divisions and/or partners
                        (unless a user has chosen to "opt-out" of receiving such marketing correspondence);
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.3.6</span>
                      <span class="col-11" i18n>
                        To contact a user in respect of a new feature, special offers, promotional competitions offered by ChainEX or any of its
                        divisions and/or partners (unless a user has chosen to "opt-out" of receiving such marketing correspondence); and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.3.7</span>
                      <span class="col-11" i18n>
                        To monitor User’ browsing habits, activities in respect of and general interaction with ChainEX’s website and its products
                        and/or services, in order to improve its offerings.
                      </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">3.4</span>
                    <span class="col-11" i18n>
                      ChainEX will not without a User’s express consent share a User’s Personal Information with a third-party for any purpose
                      other than the following, namely:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.4.1</span>
                      <span class="col-11" i18n>
                        To our employees and/or third-party service providers who facilitate the User’s engagement with our products and/or services,
                        whether by way of ChainEX’s website, email or any other communication method. In order to further ChainEX’s engagement with
                        the user and its legitimate business interests, it is necessary to collect and share User’ Personal Information. The aforesaid
                        being to assist ChainEX to communicate and engage properly and efficiently with the User and render its products and services
                        effectively;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.4.2</span>
                      <span class="col-11" i18n>
                        To third-party service providers who have to process the User’s Personal Information as a necessary consequence of and/or
                        ancillary service to ChainEX’s products and/or services;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.4.3</span>
                      <span class="col-11" i18n>
                        To external responsible parties who already have the User’s express consent to collect and process such Personal Information
                        from ChainEX, or, by consequence, directly from the user;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.4.4</span>
                      <span class="col-11" i18n>
                        To ChainEX’s sub-divisions and/or partners (including their employees and/or third-party service providers) in order for
                        them to interact directly with the user via email or any other method of communication, for purposes of sending the user
                        marketing correspondence regarding any current or new services, new features, special offers or promotional competition
                        offered by them (unless a user has chosen to "opt-out" of receiving such marketing correspondence);
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.4.5</span>
                      <span class="col-11" i18n>
                        To ChainEX’s professional services providers, including but not limited to, insurers, legal counsel and financial
                        institutions, where such disclosure is verily required for due performance under ChainEX’s contractual relationship
                        with the user and/or such professional service provider;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.4.6</span>
                      <span class="col-11" i18n>
                        To ChainEX’s service providers, including but not limited to, payment processors, fraud prevention services, marketing
                        services and information technology support services, whom are under contract with ChainEX’s and who assist with or
                        facilitate any part of ChainEX’s business operations, products and/or services. ChainEX further contracts with the
                        aforesaid parties on the basis that that these service providers may only use the User’s Personal Information in
                        connection with or in the furtherance of the services they perform for ChainEX, to the express exclusion of their own
                        benefit and/or operational purposes. ChainEX further ensures that to the extent necessary its contractual engagements
                        with the aforesaid service providers adhere to the same standards of Personal Information protection as ChainEX;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.4.7</span>
                      <span class="col-11" i18n>
                        To any law enforcement agency, government official, fraud detection agency, regulatory authority or other third-party,
                        where ChainEX believes in good faith that the disclosure of the User’s Personal Information is necessary to prevent
                        physical harm, financial loss, to report or support the investigation into suspected illegal activity, to curtail the
                        contravention of any applicable law, or to investigate violations of this Privacy Policy and/or that of ChainEX’s other
                        Terms and Conditions and/or products and/or services; and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.4.8</span>
                      <span class="col-11" i18n>
                        ChainEX is entitled to use and/or disclose your Personal Information if such use and/or disclosure is required in order
                        to comply with any applicable law, subpoena, order of court or legal process served on ChainEX, or to protect and
                        defend our rights and/or property.
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">3.4.9</span>
                      <span class="col-11" i18n>
                        In the event that a fraudulent transaction is flagged and brought to ChainEX’s attention, ChainEX is entitled to
                        disclose any Personal Information relevant for purposes of criminal investigation and prosecution or otherwise
                        required to discharge its legal obligations.
                      </span>
                    </li>
                  </ul>
                  <br>
                </li>
                <li>
                  <h4 class="table-heading" i18n>HOW DO WE SHARE YOUR PERSONAL INFORMATION?</h4>

                  <p class="row mt-0">
                    <span class="number col-1">4.1</span>
                    <span class="col-11" i18n>
                      We disclose your Personal Information as described below and as described elsewhere in this Privacy Policy.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">4.2</span>
                    <span class="col-11" i18n>
                      It may be necessary to disclose your Personal Information to comply with any law, subpoenas, court orders, or
                      government request, defend against claims, investigate or bring legal action against illegal or suspected illegal
                      activities, enforce our Terms and Conditions, or to protect the rights, safety, and security of ChainEX, our User,
                      or the public.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">4.3</span>
                    <span class="col-11" i18n>
                      We may share your Personal Information with third-party service providers to:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">4.3.1</span>
                      <span class="col-11" i18n>
                        Provide you with the services that we offer you through ChainEX’s website;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">4.3.2</span>
                      <span class="col-11" i18n>
                        Conduct quality assurance testing;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">4.3.3</span>
                      <span class="col-11" i18n>
                        Facilitate the creation of accounts;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">4.3.4</span>
                      <span class="col-11" i18n>
                        Provide technical support;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">4.3.5</span>
                      <span class="col-11" i18n>
                        Verify your identity; and/or to
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">4.3.6</span>
                      <span class="col-11" i18n>
                        Provide other services to ChainEX. These third-party service providers are required not to use your Personal
                        Information other than to provide the services requested by ChainEX.
                      </span>
                    </li>
                  </ul>
                  <br>
                  <p class="row mt-0">
                    <span class="number col-1">4.4</span>
                    <span class="col-11" i18n>
                      We may share some or all of your Personal Information in connection with or during negotiation of any merger,
                      financing, acquisition or dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure
                      of all or a portion of our business or assets. In the event of an insolvency, bankruptcy, or receivership. Personal
                      Information may also be transferred as a business asset. If another company acquires our company, business, or assets,
                      that company will possess the Personal Information collected by us and will assume the rights and obligations regarding
                      your Personal Information as described in this Privacy Policy.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">4.5</span>
                    <span class="col-11" i18n>
                      ChainEX may contain links to other third-party websites which are regulated by their own privacy policies. ChainEX is not
                      responsible for the privacy policies of these third-party websites even if they were accessed using the links from our website.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>HOW WE USE COOKIES</h4>

                  <p class="row mt-0">
                    <span class="number col-1">5.1</span>
                    <span class="col-11" i18n>
                      Cookies help us give you the best experience of using our website. In this Privacy Policy we use the term "cookies" to refer
                      to cookies and other similar technologies. When you access our website, or content or use our application or ChainEX services,
                      we or companies we work with may place small data files called cookies or pixel tags on your computer or other device. They
                      allow us to remember your actions or preferences over time. We use these technologies to:
                    </span>
                  </p>
                  <ul>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">5.1.1</span>
                      <span class="col-11" i18n>
                        Track website usage and browsing behaviour;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">5.1.2</span>
                      <span class="col-11" i18n>
                        Allow you to log-in to your account and navigate through the website;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">5.1.3</span>
                      <span class="col-11" i18n>
                        Monitor the effectiveness of our promotions and advertising; and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <p class="pt-0">
                      <span class="number col-1 pl-0 inner-1">5.1.4</span>
                      <span class="col-11" i18n>
                        Mitigate risk, enhance security and help prevent fraud.
                      </span>
                      </p>
                    </li>
                  </ul>
                  <p class="row mt-0">
                    <span class="number col-1">5.2</span>
                    <span class="col-11" i18n>
                      We use both session and persistent cookies when you access our website or content. Session cookies expire and no longer have
                      any effect when you log out of your account or close your browser. Persistent cookies remain on your browser until you erase
                      them, or they expire.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">5.3</span>
                    <span class="col-11" i18n>
                      We also use Local Shared Objects, commonly referred to as "Flash cookies," to help ensure that your account security is not
                      compromised, to spot irregularities in behaviour to help prevent fraud, and to support our websites and services.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">5.4</span>
                    <span class="col-11" i18n>
                      We encode our cookies so that only we can interpret the information stored in them. You are free to decline our cookies if
                      your browser or browser add-on permits but doing so may interfere with your use of ChainEX’s services. The help section of
                      most browsers or browser add-ons provides instructions on blocking, deleting, or disabling cookies.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">5.5</span>
                    <span class="col-11" i18n>
                      You may also encounter ChainEX cookies or pixel tags on websites that we do not control. For example, if you view a web page
                      created by a third-party or use an application developed by a third-party, there may be a cookie or pixel tag placed by the
                      web page or application. Likewise, these third parties may place cookies or pixel tags that are not subject to our control
                      and the ChainEX Privacy Policy does not cover their use.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>HOW IS MY PERSONAL INFORMATION PROTECTED?</h4>

                  <p class="row mt-0">
                    <span class="number col-1">6.1</span>
                    <span class="col-11" i18n>
                      ChainEX places great importance on ensuring the security of your Personal Information. We regularly review and implement
                      up-to-date technical and organisational security measures when processing your Personal Information. We use computer safeguards
                      such as firewalls and data encryption, we enforce physical access controls to our buildings and files, and we authorize access
                      to Personal Information only for those employees who require it to fulfil their job responsibilities.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">6.2</span>
                    <span class="col-11" i18n>
                      Please note however that no transmission over the Internet or method of electronic storage can be guaranteed to be 100% secure.
                    </span>
                  </p>
                </li>
                <li>
                  <h4 class="table-heading" i18n>HOW CAN I UPDATE MY PERSONAL INFORMATION</h4>

                  <p class="row mt-0">
                    <span class="number col-1">7.1</span>
                    <span class="col-11" i18n>
                      You can update your information by <a target="_blank" href="{{getSupportUrl()}}">contacting support</a> and making a request. If
                      there are any changes to your personal data or if you believe that the personal data, we have about you is inaccurate, incomplete,
                      misleading or not up-to-date, please contact us so that we may take steps to update your personal data. ChainEX may use its
                      discretion in allowing the corrections requested and/or may require further documentary evidence of the new information to avoid
                      fraud and inaccuracy.
                    </span>
                  </p>
                  <p class="row mt-0">
                    <span class="number col-1">7.2</span>
                    <span class="col-11" i18n>
                      We will retain your information for as long as your account has not been closed or as needed to provide you access to your account.
                      If you wish to close your account, please contact us through our <a target="_blank" href="{{getSupportUrl()}}">support centre</a>.
                      We will retain and use your information as necessary to:
                    </span>
                  </p>
                  <ul class="pb-3">
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">7.2.1</span>
                      <span class="col-11" i18n>
                        Comply with our legal obligations;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">7.2.2</span>
                      <span class="col-11" i18n>
                        Protect the interests of ChainEX and its customers;
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">7.2.3</span>
                      <span class="col-11" i18n>
                        Resolve disputes; and
                      </span>
                    </li>
                    <li class="row mt-0">
                      <span class="number col-1 pl-0 inner-1">7.2.4</span>
                      <span class="col-11" i18n>
                        Enforce our Terms.
                      </span>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4 class="table-heading" i18n>PLEASE CONTACT US</h4>

                  <p i18n>Should you have any query in relation to this Privacy Policy or how we handle your Personal Information, please contact us through
                  <a target="_blank" href="{{getSupportUrl()}}">support center</a>.</p>
                </li>
              </ol>
              <div class="alert alert-secondary clearfix" role="alert">
                  <div class="row">
                    <div class="col-1 reduce-max-w">
                        <mat-icon svgIcon="map-marker"></mat-icon>
                    </div>
                    <div class="col-11">
                      <p i18n>
                        {{address.NAME}},
                        {{address.STREET}}
                        {{address.TOWN}}
                        <br />
                        <span>{{address.REGISTRATION}} {{address.COUNTRY}}.</span>
                      </p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
