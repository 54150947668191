<div id="page-top"></div>
<app-header></app-header>
<div class="row container-fluid mx-0 px-0" [ngClass]="infoService.darkTheme ? 'dark-theme-background' : 'light-theme-background'">
  <div class="blueprint-background">
    <div class="vertical-spacer d-none d-lg-block"></div>
    <div class="col-12 col-lg-10 pt-4 pt-lg-0 mt-3 pb-3 mx-auto">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 i18n>About <span class="orange-text"><b>CZAR</b></span></h2>
        </div>
        <div class="col-12 col-lg-9 px-lg-0">
          <p>
            CZAR is a stablecoin pegged to the South African Rand, backed and built by a secure
            and reliable cryptocurrency exchange.
          </p>
          <p>
            CZAR utilizes blockchain technology to allow for the storage, sending and receiving of a
            digital token that is pegged to the South African Rand at a 1:1 ratio and is fully backed
            and convertible at 1 CZAR to R1.00, on ChainEX, giving it a stable price.
          </p>
          <p>
            BSC Contract Address: 0x6D3a215694f2B86F21F5D6bEab594cC856B17DcD
          </p>
          <div class="col-12 col-xl-4 mb-0 mb-lg-3 mx-auto">
            <a routerLink="/markets/CZAR/ZAR" class="mat-raised-button mat-primary orange-gradient w-100 mt-3 py-2 main-button" i18n>BUY CZAR NOW</a>
          </div>
        </div>
      </div>
    </div>
    <div class="vertical-spacer d-none d-lg-block"></div>
    <div class="col-12 col-lg-10 pt-3 pt-lg-0 pb-2 pb-lg-5 mb-2 mb-lg-3 mx-auto">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 i18n>What is <span class="orange-text"><b>CZAR</b></span></h2>
        </div>
        <div class="col-12 col-lg-9 px-lg-0">
          <p>
            CZAR is a digital currency that is pegged to the
            South African Rand, being a “stable” reserve asset. It is designed in this way to reduce
            volatility relative to unpegged cryptocurrencies like Bitcoin, while still inheriting some
            of their most powerful qualities, being:
          </p>
          <ul>
            <li>
              <p>CZAR is transparent, global and accessible to anyone on the internet twenty-four
                hours a day;</p>
            </li>
            <li>
              <p>CZAR is fast, cheap and secure to transmit;</p>
            </li>
            <li>
              <p>CZAR is digitally native to the internet and programmable.</p>
            </li>
          </ul>
          <p>
            CZAR is backed by South African Rands, assets and investments equal to at least the value of the amount of CZAR that has been minted.
          </p>
          <p>
            CZAR tokens are not money but are new assets that move across the blockchain just as
            easily as other digital currencies and thus facilitating the use of South African Rands in
            a digital manner. CZAR gives customers the ability to transact with a traditional
            currency across the blockchain, without the inherent volatility typically associated with
            a digital currency.
          </p>
        </div>
      </div>
    </div>
  </div>



  <div class="map-background">
    <div class="col-12 col-lg-10 mt-2 mt-lg-5 pt-3 pb-3 pb-lg-0 mx-auto">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 i18n>Links to <span class="orange-text"><b>Dex</b></span></h2>
        </div>
        <div class="col-12 col-lg-9 px-lg-0 pt-3">
          <ul>
            <li>
              <a href="https://pancakeswap.finance/swap?outputCurrency=0x6d3a215694f2b86f21f5d6beab594cc856b17dcd">PancakeSwap</a><br>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="vertical-spacer d-none d-lg-block"></div>

    <div class="col-12 col-lg-10 mt-2 pt-2 pb-1 mx-auto">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 i18n>Use <span class="orange-text"><b>Cases</b></span></h2>
        </div>
        <div class="col-12 col-lg-9 px-lg-0">
          <ul>
            <li>
              <p>Use on decentralised exchanges: Use CZAR to trade in DeFi markets;</p>
            </li>
            <li>
              <p>Provide Liquidity: Use CZAR with DeFi AMM and liquidity pools;</p>
            </li>
            <li>
              <p>Reduce the risk: Use CZAR to reduce the risk of price volatility within the cryptocurrency
                sphere;</p>
            </li>
            <li>
              <p>Payments: Use CZAR to send to other people money both locally and across borders without the risk of price fluctuations;</p>
            </li>
            <li>
              <p>Earn Fees: Stake CZAR to earn in liquidity pools on DeFi to earn trading fees.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="vertical-spacer d-none d-lg-block"></div>
  </div>

  <div class="blueprint-background-flipped">
    <div class="col-12 col-lg-10 mt-2 pt-2 pb-1 mx-auto">
      <div class="row">
        <div class="col-12 col-lg-3">
          <h2 i18n>Frequently asked <span class="orange-text"><b>Questions</b></span></h2>
        </div>
        <div class="col-12 col-lg-9 px-lg-0">
          <h4><strong>Will I be able to change my CZAR into South African Rands?</strong></h4>
          <p>
            Yes, as the CZAR tokens are minted on a 1:1 basis, each rand that is exchanged for
            CZAR is held by ChainEX and can easily be exchanged for South African Rands again.
          </p>
          <h4><strong>What can CZAR be used for?</strong></h4>
          <p>
            CZAR has many uses from decentralized trading to payments to earning fees by
            providing liquidity to a liquidity pool and it has even been used to take advantage of
            arbitrage.
          </p>
          <h4><strong>Can I use CZAR if I do not have a registered account on ChainEX?</strong></h4>
          <p>
            Yes, CZAR can be used by anyone.
          </p>
          <h4><strong>How can I get CZAR tokens?</strong></h4>
          <p>
            CZAR tokens can be obtained in one of the following ways:
          </p>
          <ul>
            <li>
              <p>By creating a ChainEX account, funding the account and exchanging Rands for CZAR
                tokens;</p>
            </li>
            <li>
              <p>By using a DEX (decentralised exchange) to exchange other crypto currencies for
                CZAR;</p>
            </li>
            <li>
              <p>CZAR can be sent to you from other individuals who own CZAR.</p>
            </li>
          </ul>
          <h4><strong>If I have lost my device are my funds lost?</strong></h4>
          <p>
            If your CZAR tokens are stored on a private wallet, they can only be recovered with the
            private key or keywords for that private wallet.
          </p>
          <p>
            If your CZAR is stored on ChainEX and you lost access to your ChainEX account you will
            need to verify your details to regain access to your ChainEX account.
          </p>
          <h4><strong>Which exchanges can I send my CZAR tokens to?</strong></h4>
          <p>
            CZAR should only be sent to exchanges that supports and accepts the CZAR token.
          </p>
          <h4><strong>Are CZAR coins divisible to cents like the South African Rand?</strong></h4>
          <p>
            Yes, you can hold as little as 0.00000001 of a CZAR Token.
          </p>
          <h4><strong>How do I know that my CZAR tokens are real?</strong></h4>
          <p>
            CZAR tokens exchanged on or withdrawn from ChainEX will be real tokens.
            All CZAR transactions will be visible on the applicable block explorers.
          </p>
          <h4><strong>How does CZAR protect me from cryptocurrency volatility?</strong></h4>
          <p>
            As CZAR is pegged to the South African rand at a 1-to-1 basis and back by ChainEX, as
            such 1 CZAR token will be equal to R1.00, on ChainEX.
          </p>
          <h4><strong>How do I know my CZAR tokens are secure?</strong></h4>
          <p>
            CZAR is built on top of a cryptographically secure open blockchain technology and
            adheres to strict security protocols and regulations.
          </p>
          <p>
            All CZAR tokens are pegged at 1-to-1 ratio with the South African Rand and backed by
            ChainEX.
          </p>
          <p>
            CZAR tokens can be securely stored, sent and received across the blockchain and are
            redeemable for South African Rands pursuant to ChainEX's terms of service.
          </p>
          <h4><strong>What networks is the CZAR token on?</strong></h4>
          <p>
            BSC - contract address: 0x6D3a215694f2B86F21F5D6bEab594cC856B17DcD
          </p>
          <h4><strong>How much does CZAR cost to use?</strong></h4>
          <p>
            View details on our <a href="/fees">fees page.</a>
          </p>
          <div class="col-12 col-xl-4 mb-5 mb-lg-3 mx-auto">
            <a href="/terms#stablecoin" class="mat-raised-button mat-primary orange-gradient w-100 mt-3 py-2 main-button" i18n>View Terms</a>
          </div>
        </div>
      </div>
    </div>
    <div class="vertical-spacer d-none d-lg-block"></div>
  </div>
</div>

<div class="col-12 py-5 mb-3 mb-sm-0">
  <div class="w-100 my-5 d-none d-lg-block"></div>
</div>

<!-- footer -->
<app-info-footer></app-info-footer>
